import CryptoBomberBomb from "@game/mini/cryptobomber/gameobjects/CryptoBomberBomb";
import CryptoBomberCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberCharacter";
import CryptoBomberCrate from "@game/mini/cryptobomber/gameobjects/CryptoBomberCrate";
import CryptoBomberExplosion from "@game/mini/cryptobomber/gameobjects/CryptoBomberExplosion";
import CryptoBomberPlayerCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberPlayerCharacter";
import CryptoBomberPowerUp from "@game/mini/cryptobomber/gameobjects/CryptoBomberPowerUp";
import OrderableTopDownGameScene from "@game/mini/cryptobomber/OrderableTopDownGameScene";
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import {
  Direction,
  DirectionDeltas,
  DirectionsCardinal,
} from "@game/engine/navigation/Direction";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { Grid } from "@game/engine/objects/Grid";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";
import {
  dispatchEvent,
  EnumApplicationEvents,
  EnumGameEvents,
  EnumNetworkEvents,
  EnumUiEvents,
} from "@shared/Events";
import { PlayerState } from "@game/engine/gameobjects/OrderableTopDownCharacter";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";
import { TilePosition2DToTilePosition3D } from "@common/interfaces/TilePosition3D";
import CryptoBomberWaitingForPlayersCountdown from "@game/mini/cryptobomber/gameobjects/CryptoBomberWaitingForPlayersCountdown";
import CryptoBomberReadyCountdown from "@game/mini/cryptobomber/gameobjects/CryptoBomberReadyCountdown";
import CryptoBomberEndScreen from "@game/mini/cryptobomber/gameobjects/CryptoBomberEndScreen";
import CryptoBomberWaitingForPlayers from "@game/mini/cryptobomber/gameobjects/CryptoBomberWaitingForPlayers";
import { Network } from "@game/engine/networking/Network";
import { loadAllAvailableCharacterAtlasAndJSONIntoScene } from "@game/engine/utilities/AssetLoaderHelper";
import { formatNftName } from "@shared/Helpers";
import { CryptoBomberNetworkedComponent } from "@game/mini/cryptobomber/components/scenecomponents/CryptoBomberNetworkedComponent";
import { ICryptoBomberPlayerData } from "@common/modules/cryptobomber/interfaces/ICryptoBomberPlayerData";
import { Vector2Like } from "@common/interfaces/Vector2Like";
import { PowerUpType } from "@common/modules/cryptobomber/enums/PowerUpType";
import { CryptoBomberGamePhase } from "@common/modules/cryptobomber/enums/CryptoBomberGamePhase";
import { CRYPTO_BOMBER_ROUND_DURATION } from "@game/mini/cryptobomber/common/Constants";
import { GameEndReason } from "@common/modules/cryptobomber/enums/GameEndReason";
import TouchControlsSceneComponent from "@engine/components/scenecomponents/TouchControlsSceneComponent";
import { Toast } from "@engine/notifications/Toast";
import OverlayScene, {
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";
import Tile = Phaser.Tilemaps.Tile;
import Size = Phaser.Structs.Size;
import TimerEvent = Phaser.Time.TimerEvent;

const AudioKeys = {
  Bomb_Explode: "bomb-explode",
  Bomb_Place: "bomb-place",
  Powerup_Pickup: "powerup-pickup",
  Theme: "bomber_theme",
  Walk_3: "walk-3",
};

class ConqueredTile {
  constructor(public tile: Tile, public owner: CryptoBomberCharacter = null) {}

  public conquer(owner: CryptoBomberCharacter) {
    this.owner = owner;
    this.tile.tint = owner.color;
  }
}

export default class CryptoBomberScene extends OrderableTopDownGameScene {
  public static DEBUG = {
    PLAYER_DAMAGED: true,
    POWER_UPS: true,
  };
  public static SceneKey: string = "CryptoBomberScene";
  private _audioComponent: AudioComponent;
  private _blockGrid: Grid<boolean>;
  private _bombGrid: Grid<CryptoBomberBomb>;
  //clearValues
  private _bombs: CryptoBomberBomb[] = [];
  //clearValues
  private _conqueredScores: { [identifier: string]: number } = {};
  private _conqueredTilesGrid: Grid<ConqueredTile>;
  private _crateGrid: Grid<CryptoBomberCrate>;
  //clearValues
  private _crates: CryptoBomberCrate[] = [];
  private _endScreen: CryptoBomberEndScreen;
  private _explosionGrid: Grid<CryptoBomberExplosion>;
  private _localPlayer: CryptoBomberPlayerCharacter;
  private _localPlayerId: string;
  public touchControlsComponent: TouchControlsSceneComponent;
  private _matchData: { [key: string]: string };
  private _networkedComponent: CryptoBomberNetworkedComponent;
  //clearValues
  private _players: CryptoBomberCharacter[] = [];
  //clearValues
  private _playersByIdentifier: {
    [identifier: string]: CryptoBomberCharacter;
  } = {};
  private _powerUpGrid: Grid<CryptoBomberPowerUp>;
  private _readyCountdown: CryptoBomberReadyCountdown;
  private _roundStart: number;
  private _roundTimer: TimerEvent;
  private _waitingCountdown: CryptoBomberWaitingForPlayersCountdown;
  private _waitingMessage: CryptoBomberWaitingForPlayers;

  constructor() {
    super(
      { key: CryptoBomberScene.SceneKey },
      "/assets/game/mini/cryptobomber/",
      "cryptobomber.tmj",
      ["floor_tiles.png", "trees_flat_tiles.png"], // Tilesets
      [],
      ["block.png", "trees_flat_tiles.png"],
    );
  }

  public addPlayer(player: ICryptoBomberPlayerData) {
    // Check if the player is already in the match
    if (
      this._players.some(
        (alreadyJoinedPlayer) => alreadyJoinedPlayer.identifier === player.id,
      )
    ) {
      return;
    }

    if (player.id === this._localPlayerId && !this._localPlayer) {
      this.instantiatePlayer(player);
    } else {
      this.instantiateOpponent(
        player.id,
        player.spawnTilePosition,
        player.color,
      );
    }
  }

  public conquerTile(tilePosition: TilePosition3D, playerId: string) {
    const player = this._playersByIdentifier[playerId];
    if (player) {
      const conqueredTile: ConqueredTile =
        this._conqueredTilesGrid.getAtPosition(tilePosition);

      // Sanity check
      if (
        conqueredTile.owner &&
        conqueredTile.owner.identifier === player.identifier
      ) {
        return;
      }

      // Deduct one from the score of the previous owner, if any
      if (conqueredTile.owner !== null) {
        --this._conqueredScores[conqueredTile.owner.identifier];
      }

      // Add one to the current player's score
      ++this._conqueredScores[player.identifier];
      conqueredTile.conquer(player);

      // Let the UI know
      dispatchEvent(
        EnumGameEvents.GameBombermanConquerTile,
        this._conqueredScores,
      );
    }
  }

  public damagePlayers(playerIds: string[]) {
    playerIds.forEach((playerId) => {
      this._playersByIdentifier[playerId]?.damage();

      dispatchEvent(EnumGameEvents.GameBombermanLifeLost, { uid: playerId });
    });
  }

  public endGame = (
    winners: ICryptoBomberPlayerData[],
    reason: GameEndReason,
  ) => {
    this.setPhase(CryptoBomberGamePhase.GameEnd, winners);
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  };

  public explode(
    owner: CryptoBomberCharacter,
    tilePosition: TilePosition3D,
  ): boolean {
    // Check if we're within the playing field
    const { x, y } = tilePosition;
    if (x < 1 || x > 13 || y < 2 || y > 12) {
      return false;
    }

    // Okay, just nothing then
    if (this.hasBlockAt(tilePosition)) {
      return false;
    }

    // Check if there's a crate here to destroy
    const crate = this.getCrateAt(tilePosition);
    if (crate) {
      crate.explode();
      return false;
    }

    // Check if there's a bomb here to trigger
    const bomb = this.getBombAt(tilePosition);
    if (bomb) {
      bomb.explode();
    }

    // Check if there's already an explosion here so we don't double down
    const explosion = this.getExplosionAt(tilePosition);
    if (!explosion || explosion.hasExploded) {
      // Create a new explosion and add it to all the bookkeeping
      const explosion = new CryptoBomberExplosion(this, owner, tilePosition);
      this.add.existing(explosion);
      this._explosionGrid.addAtPosition(tilePosition, explosion);
      this.addOrderable(explosion);

      // When it explodes
      explosion.on(CryptoBomberExplosion.EventTypes.Fizzle, (explosion) => {
        this._explosionGrid.clearAtPosition(tilePosition);
        this.removeOrderable(explosion);
      });
    }

    return true;
  }

  public gameEnd = (reason: GameEndReason) => {
    this.setPhase(CryptoBomberGamePhase.GameEnd);
  };

  public gameReady = () => {
    this.setPhase(CryptoBomberGamePhase.Ready);
  };

  public gameStart = () => {
    this.setPhase(CryptoBomberGamePhase.Playing);
  };

  public hasBombAt(tilePosition: TilePosition3D): boolean {
    return this._bombGrid.hasAtPosition(tilePosition);
  }

  public initializeWorld(
    phase: CryptoBomberGamePhase,
    localPlayerId: string,
    otherPlayers: ICryptoBomberPlayerData[],
    cratePositions: TilePosition2D[],
    countdown: number,
  ) {
    // For local bookkeeping
    this._localPlayerId = localPlayerId;

    // Spawn the correct representations
    otherPlayers.forEach((otherPlayer) => {
      this.addPlayer(otherPlayer);
    });

    // Place all crates in the correct positions
    cratePositions.forEach((cratePosition) => {
      if (cratePosition) {
        this.placeCrate(TilePosition2DToTilePosition3D(cratePosition));
      }
    });

    // Move towards the current phase if needed
    if (phase === CryptoBomberGamePhase.WaitingForMaximumNumberOfPlayers) {
      this.setPhase(phase, countdown);
    }
  }

  public kicked() {
    this._localPlayer.isAllowedToMove = false;
    this._localPlayer.removeAllListeners();
  }

  public movePlayerTo(playerId: string, position: Vector2Like) {
    if (playerId === this._localPlayerId) {
      return;
    }
    const player = this._playersByIdentifier[playerId];
    if (player) {
      player.moveToAndMakeFace(position.x, position.y);
    }
  }

  public placeBomb = (
    ownerId: string,
    tilePosition: TilePosition3D,
    range: number,
  ) => {
    tilePosition.z = 0;
    const owner = this._playersByIdentifier[ownerId];
    // Create a new bomb and register it for collisions, bookkeeping, and ordering
    const bomb = new CryptoBomberBomb(this, owner, tilePosition, range);
    this._bombGrid.addAtPosition(tilePosition, bomb);
    this._bombs.push(bomb);
    this.add.existing(bomb);
    this.addOrderable(bomb);

    this._audioComponent.play(AudioKeys.Bomb_Place);

    // It's been added to everything it needs to be added to
    bomb.lightTheFuse();

    // Check when it explodes
    bomb.on(CryptoBomberBomb.EventTypes.Explode, (bomb) => {
      this._audioComponent.play(AudioKeys.Bomb_Explode);

      // Owner can place a bomb again
      if (bomb.owner instanceof CryptoBomberPlayerCharacter) {
        bomb.owner.deductAmountOfBombsPlaced();
      }

      // Remove it from bookkeeping and such
      this._bombGrid.clearAtPosition(tilePosition);
      this._bombs.splice(this._bombs.indexOf(bomb), 1);
      this.removeOrderable(bomb);

      // Explode on the bomb's location itself (returns true if we should keep going)
      this.explode(owner, bomb.tilePosition);

      // Explode in every direction
      DirectionsCardinal.forEach((direction) => {
        this.explosionChain(
          bomb.owner,
          bomb.tilePosition,
          direction,
          bomb.range - 1,
        );
      });
    });
  };

  public placePowerUp(
    tilePosition: TilePosition3D,
    powerUpType: PowerUpType = PowerUpType.BombUp,
  ) {
    const powerUp = new CryptoBomberPowerUp(this, tilePosition, powerUpType);

    // Remove from stuff when collected
    powerUp.once(CryptoBomberPowerUp.EventTypes.Collecting, (powerUp) => {
      this._networkedComponent.sendPowerUpCollected(powerUp.tilePosition);
      this._powerUpGrid.clearAtPosition(powerUp.tilePosition);
    });

    powerUp.once(CryptoBomberPowerUp.EventTypes.Collected, (powerUp) => {
      this.removeOrderable(powerUp);
    });

    this._powerUpGrid.addAtPosition(tilePosition, powerUp);
    this.add.existing(powerUp);
    powerUp.appear();
    this.addOrderable(powerUp);
  }

  public powerUpCollected(collectorId: string, tilePosition: TilePosition3D) {
    this._audioComponent.play(AudioKeys.Powerup_Pickup);

    // If it's not us, animate the collecting for the other player
    if (collectorId !== this._localPlayerId) {
      const powerUp = this._powerUpGrid.getAtPosition(tilePosition);
      if (powerUp) {
        powerUp.collect(this._playersByIdentifier[collectorId]);
      } else {
        if (CryptoBomberScene.DEBUG.POWER_UPS) {
          console.log(
            "[CryptoBomber] No powerup to collect found at location " +
              JSON.stringify(tilePosition),
          );
        }
      }
    }
  }

  public removePlayer(playerId: string) {
    const playerToDelete = this._playersByIdentifier[playerId];
    if (playerToDelete) {
      delete this._playersByIdentifier[playerId];
      this._players.splice(this._players.indexOf(playerToDelete), 1);
      this.removeOrderable(playerToDelete);
      playerToDelete.destroy(true);
      dispatchEvent(EnumGameEvents.GameBombermanOpponentLeft, {
        id: playerId,
      });
    }
  }

  public startPlayerAnimation(playerId: string) {
    if (playerId === this._localPlayerId) {
      return;
    }

    const player = this._playersByIdentifier[playerId];
    if (player) {
      player.setPlayerState(PlayerState.Walking);
    }
  }

  public startWaitingForPlayers() {
    this.setPhase(CryptoBomberGamePhase.WaitingForMaximumNumberOfPlayers);
  }

  public stopPlayerAnimation(playerId: string) {
    if (playerId === this._localPlayerId) {
      this._audioComponent.stop(AudioKeys.Walk_3);
      return;
    }

    const player = this._playersByIdentifier[playerId];
    if (player) {
      player.setPlayerState(PlayerState.Idle);
    }
  }

  public tryToPlaceBomb(
    owner: CryptoBomberPlayerCharacter,
    tilePosition: TilePosition3D,
  ): boolean {
    // Can't place at a taken location
    if (this.hasBombAt(tilePosition)) {
      return false;
    }

    // Let others know
    this._networkedComponent.sendPlaceBomb(tilePosition);

    return true;
  }

  protected override async create() {
    super.create();
    //Add touch controls component
    this.touchControlsComponent =
      this.addComponent<TouchControlsSceneComponent>(
        new TouchControlsSceneComponent(
          { pinchZoom: false, pointerDragCamera: false },
          { enable: true },
          { enable: true },
        ),
      );

    dispatchEvent(EnumGameEvents.GameUiMinigameStart, "cryptobomber");

    // So we're not playing in the void
    this.setBackgroundColor(0xc1ebf9);

    // For counting down
    this._roundTimer = this.time.addEvent({
      callback: () => {
        if (!this.isInPhase(CryptoBomberGamePhase.GameEnd)) {
          dispatchEvent(EnumGameEvents.GameBombermanSecondPassed, {
            time: Math.max(
              0,
              CRYPTO_BOMBER_ROUND_DURATION - (Date.now() - this._roundStart),
            ),
          });
        }
      },
      delay: 1000,
      paused: true,
      repeat: -1,
    });

    this._blockGrid = new Grid<boolean>(
      this._map.width,
      this._map.height,
      1,
      false,
    );

    this._map
      .getTilesWithin(
        0,
        0,
        this._map.width,
        this._map.height,
        { isNotEmpty: true },
        "blocks",
      )
      .forEach((tile) => {
        this._blockGrid.add(tile.x, tile.y, 0, true);
      });

    this._bombGrid = new Grid<CryptoBomberBomb>(
      this._map.width,
      this._map.height,
      1,
    );

    // Create and fill it
    this._conqueredTilesGrid = new Grid<ConqueredTile>(
      this._map.width,
      this._map.height,
      1,
    );

    this._map
      .getTilesWithin(
        0,
        0,
        this._map.width,
        this._map.height,
        { isNotEmpty: true },
        "conquerable",
      )
      .forEach((tile) => {
        const { x, y } = tile;
        const backgroundTile = this._map.getTileAt(x, y, true, "background");
        this._conqueredTilesGrid.addAtPosition(
          { x, y, z: 0 },
          new ConqueredTile(backgroundTile),
        );
      });

    this._crateGrid = new Grid<CryptoBomberCrate>(
      this._map.width,
      this._map.height,
      1,
    );

    this._explosionGrid = new Grid<CryptoBomberExplosion>(
      this._map.width,
      this._map.height,
      1,
    );

    this._powerUpGrid = new Grid<CryptoBomberPowerUp>(
      this._map.width,
      this._map.height,
      1,
    );

    // Make sure stuff collides with each other
    this.physics.add.collider(this._players, this._collidableTiles);
    this.physics.add.collider(this._players, this._bombs);
    this.physics.add.collider(this._players, this._crates);
    //@todo not called all of the time
    this.physics.add.overlap(
      this._players,
      this._powerUpGrid.allNodes,
      (player: CryptoBomberPlayerCharacter, powerUp: CryptoBomberPowerUp) => {
        powerUp.collect(player);
      },
    );

    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );

    this._networkedComponent =
      this.addComponent<CryptoBomberNetworkedComponent>(
        new CryptoBomberNetworkedComponent(this._matchData),
      );
    await this._networkedComponent.connect();

    if (this._matchData.matchId) {
      try {
        await this._networkedComponent.joinMatch(this._matchData.matchId);
      } catch (error) {
        // Can't join the match because it has already started
        if ((error as { code: number; message: string }).code === 5) {
          dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
            sceneKey: "ArcadeScene",
            senderSceneKey: "CryptoBomberScene",
          });
          Toast.error(
            "Could not join the match, the game has already started.",
          );
        }
      }
    } else {
      await this._networkedComponent.findAndJoinMatch();
    }

    // Call it manually first
    this.onResized(this.scale.gameSize);
  }

  protected override init(data?: object): void {
    this._matchData = (data as any)?.matchData;
  }

  protected onCreated() {
    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/cryptobomber/audio/mp3/minigame_theme.mp3",
    });
    this._audioComponent.addSoundEffects([
      {
        key: AudioKeys.Bomb_Place,
        path: "/assets/game/mini/cryptobomber/audio/mp3/place_bomb.mp3",
      },
      {
        key: AudioKeys.Bomb_Explode,
        path: "/assets/game/mini/cryptobomber/audio/mp3/bomb_explode.mp3",
      },
      {
        key: AudioKeys.Powerup_Pickup,
        path: "/assets/game/mini/cryptobomber/audio/mp3/pickup_powerup.mp3",
      },
      {
        key: AudioKeys.Walk_3,
        path: "/assets/game/mini/cryptobomber/audio/mp3/walk_3.mp3",
      },
    ]);
    this._audioComponent.playBackgroundAudio(true, 0.7);
  }

  protected onResized(gameSize: Phaser.Structs.Size) {
    super.onResized(gameSize);

    this.centerEverything(gameSize);

    // MARKTWAIN - this is used in several places. Mighty Mines too. Probably abstract it
    const extra = 1.02;
    // Check if we're wider or taller
    let zoomHeight = 0;
    let zoomWidth = 0;
    // Add 10% for a little extra space
    if (this._map.heightInPixels * extra >= gameSize.height) {
      zoomHeight = gameSize.height / (this._map.heightInPixels * extra);
    } else {
      zoomHeight = 1;
    }
    if (this._map.widthInPixels * extra >= gameSize.width) {
      zoomWidth = (gameSize.width / this._map.widthInPixels) * extra;
    } else {
      zoomWidth = 1;
    }

    // const minimum = Math.min(gameSize.width, this.scale.height);
    const camera = this.cameras.main;
    camera.roundPixels = true;
    camera.setZoom(
      Phaser.Math.Clamp(Math.min(zoomWidth, zoomHeight), 0.3, 1.0),
    );

    if (this.touchControlsComponent && this.touchControlsComponent.joystick) {
      this.touchControlsComponent.onZoom();
    }
  }

  protected onShutdown(): void {
    this.clearValues();
    window.localStorage.setItem("crypto-bomber-game-played", "true");
    super.onShutdown();
  }

  protected override preload() {
    super.preload();
    this.load.image("bomb", "/assets/game/mini/cryptobomber/bomb.png");

    this.load.spritesheet(
      "bomb_spritesheet",
      "/assets/game/mini/cryptobomber/bomb/bomb_spritesheet.png",
      {
        frameWidth: 142,
        frameHeight: 128,
      },
    );

    this.load.spritesheet(
      "crate_explosion_spritesheet",
      "/assets/game/mini/cryptobomber/crate_explosion_spritesheet.png",
      {
        frameWidth: 77,
        frameHeight: 109,
      },
    );

    this.load.spritesheet(
      "explosion_spritesheet",
      "/assets/game/mini/cryptobomber/explosion_spritesheet.png",
      {
        frameWidth: 142,
        frameHeight: 128,
      },
    );

    this.load.spritesheet(
      "bomb_up_spritesheet",
      "/assets/game/mini/cryptobomber/power-ups/bomb_up.png",
      {
        frameWidth: 74,
        frameHeight: 87,
      },
    );
    this.load.spritesheet(
      "range_up_spritesheet",
      "/assets/game/mini/cryptobomber/power-ups/range_up.png",
      {
        frameWidth: 74,
        frameHeight: 133,
      },
    );
    this.load.spritesheet(
      "speed_up_spritesheet",
      "/assets/game/mini/cryptobomber/power-ups/speed_up.png",
      {
        frameWidth: 74,
        frameHeight: 95,
      },
    );
    this.load.audio(
      AudioKeys.Theme,
      "/assets/game/mini/cryptobomber/audio/mp3/minigame_theme.mp3",
    );
    this.load.audio(
      AudioKeys.Bomb_Place,
      "/assets/game/mini/cryptobomber/audio/mp3/place_bomb.mp3",
    );
    this.load.audio(
      AudioKeys.Bomb_Explode,
      "/assets/game/mini/cryptobomber/audio/mp3/bomb_explode.mp3",
    );
    this.load.audio(
      AudioKeys.Powerup_Pickup,
      "/assets/game/mini/cryptobomber/audio/mp3/pickup_powerup.mp3",
    );
    this.load.audio(
      AudioKeys.Walk_3,
      "/assets/game/mini/cryptobomber/audio/mp3/walk_3.mp3",
    );

    // MARKTWAIN - Probably lazyload this at some point
    loadAllAvailableCharacterAtlasAndJSONIntoScene(this);
  }

  protected override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    if (
      this.isInPhase(CryptoBomberGamePhase.WaitingForMaximumNumberOfPlayers)
    ) {
      this._waitingCountdown?.updateTimePassed();
    }
    if (this.isInPhase(CryptoBomberGamePhase.Ready)) {
      this._readyCountdown?.updateTimePassed();
    }
  }

  private centerEverything = (gameSize?: Size) => {
    if (!gameSize) {
      gameSize = this.scale.gameSize;
    }
    const { height, width } = gameSize;
    this.cameras.resize(width, height);
    this._endScreen?.centerOnDisplay(gameSize);
    this.centerCamera();
  };

  private clearValues(): void {
    const overlayScene = this.scene.get("OverlayScene") as OverlayScene;
    if (this._readyCountdown) {
      overlayScene.removeAnchoredObject(this._readyCountdown);
    }
    if (this._waitingCountdown) {
      overlayScene.removeAnchoredObject(this._waitingCountdown);
    }
    this._localPlayer = undefined;
    this._players = [];
    this._conqueredScores = {};
    this._playersByIdentifier = {};
    this._bombs = [];
    this._crates = [];
    this._phase = -1;
  }

  private explosionChain = (
    owner: CryptoBomberCharacter,
    tilePosition: TilePosition3D,
    direction: Direction,
    range: number,
  ) => {
    const to = TilePositionMath.add(DirectionDeltas[direction], tilePosition);
    // Keep going if needed (explode returns true if unobstructed)
    if (this.explode(owner, to) && range > 0) {
      this.explosionChain(owner, to, direction, range - 1);
    }
  };

  private getBombAt(tilePosition: TilePosition3D): CryptoBomberBomb {
    return this._bombGrid.getAtPosition(tilePosition);
  }

  private getCrateAt(tilePosition: TilePosition3D): CryptoBomberCrate {
    return this._crateGrid.getAtPosition(tilePosition);
  }

  private getExplosionAt(tilePosition: TilePosition3D): CryptoBomberExplosion {
    return this._explosionGrid.getAtPosition(tilePosition);
  }

  private hasBlockAt(tilePosition: TilePosition3D): boolean {
    return this._blockGrid.hasAtPosition(tilePosition);
  }

  private instantiateOpponent(
    identifier: string,
    spawnTilePosition: TilePosition2D,
    color: number,
  ) {
    const character = new CryptoBomberCharacter(
      this,
      identifier,
      "default_character",
      color,
      spawnTilePosition,
    );
    this.registerCharacter(character);

    // Retrieve opponent's metadata
    Network.Core.core
      .getUsersData(identifier)
      .then(function (data) {
        if (
          data.length > 0 &&
          "metadata" in data[0] &&
          "activeCharacter" in data[0].metadata &&
          "name" in data[0].metadata.activeCharacter
        ) {
          character.updateAvatar(
            formatNftName(data[0].metadata.activeCharacter.name),
          );
        }
      })
      .finally();

    dispatchEvent(EnumGameEvents.GameBombermanOpponentJoined, {
      id: identifier,
      color: color,
    });
  }

  private instantiatePlayer(player: ICryptoBomberPlayerData) {
    const character = new CryptoBomberPlayerCharacter(
      this,
      player.id,
      "default_character",
      player.spawnTilePosition,
      player.color,
    );
    this._localPlayer = character;
    Network.Core.core
      .getUsersData(player.id)
      .then(function (data) {
        if (
          data.length > 0 &&
          "metadata" in data[0] &&
          "activeCharacter" in data[0].metadata &&
          "name" in data[0].metadata.activeCharacter
        ) {
          character.updateAvatar(
            formatNftName(data[0].metadata.activeCharacter.name),
          );
        }
      })
      .finally();
    this._localPlayer.on(
      CryptoBomberPlayerCharacter.EventTypes.Moved,
      (position: Vector2Like) => {
        this._networkedComponent.sendPlayerPosition(position);
      },
    );
    this._localPlayer.on(
      CryptoBomberPlayerCharacter.EventTypes.StartMovement,
      (player: CryptoBomberPlayerCharacter) => {
        this._audioComponent.play(AudioKeys.Walk_3, true);
        this._networkedComponent.sendPlayerStartMovement(player.direction);
      },
    );
    this._localPlayer.on(
      CryptoBomberPlayerCharacter.EventTypes.StopMovement,
      () => {
        this._audioComponent.stop(AudioKeys.Walk_3);
        this._networkedComponent.sendPlayerStopMovement();
      },
    );
    this.registerCharacter(this._localPlayer);

    // Update the UI
    dispatchEvent(EnumGameEvents.GameBombermanPlayerJoined, {
      id: player.id,
      color: player.color,
    });
    // Check if we're the only one here
    if (1 === this._players.length) {
      // Move into the pre-game
      this.setPhase(CryptoBomberGamePhase.PreGame);
    }
  }

  private placeCrate(tilePosition: TilePosition3D) {
    const crate = new CryptoBomberCrate(this, tilePosition);
    this._crateGrid.addAtPosition(tilePosition, crate);
    this._crates.push(crate);
    this.add.existing(crate);
    this.addOrderable(crate);

    crate.on(CryptoBomberCrate.EventTypes.Destroyed, (crate) => {
      this._crateGrid.clearAtPosition(crate.tilePosition);
      this._crates.splice(this._crates.indexOf(crate), 1);
      this.removeOrderable(crate);
    });
  }

  private registerCharacter(character: CryptoBomberCharacter) {
    this.add.existing(character);
    this._players.push(character);
    this._playersByIdentifier[character.identifier] = character;
    this._conqueredScores[character.identifier] = 0;
    this.addOrderable(character);
  }

  private setPhase(phase: CryptoBomberGamePhase, data?: unknown) {
    // Can't double up on the phase
    if (this.isInPhase(phase)) {
      return;
    }

    // Store it
    this._phase = phase;

    const overlayScene = this.scene.get("OverlayScene") as OverlayScene;

    switch (this._phase) {
      case CryptoBomberGamePhase.Playing:
        // Remove the countdown if it's there
        if (this._readyCountdown) {
          overlayScene.removeAnchoredObject(this._readyCountdown);
        }

        this._localPlayer.isAllowedToMove = true;

        this._roundStart = Date.now();
        this._roundTimer.paused = false;
        break;
      case CryptoBomberGamePhase.GameEnd:
        // Just making sure
        if (this._readyCountdown) {
          overlayScene.removeAnchoredObject(this._readyCountdown);
        }
        if (this._waitingCountdown) {
          overlayScene.removeAnchoredObject(this._waitingCountdown);
        }
        this._waitingMessage?.destroy(true);

        const winners = <ICryptoBomberPlayerData[]>data;

        this._localPlayer.isAllowedToMove = false;
        this._endScreen = new CryptoBomberEndScreen(
          this,
          winners[0].id === this._localPlayerId,
        );
        this.add.existing(this._endScreen);

        dispatchEvent(EnumGameEvents.GameUiMinigameShowScoreboard, {
          winners: winners,
          matchData: this._matchData,
        });

        this.centerEverything();

        // And now, move back to the lobby after 10 sec
        // this.time.delayedCall(5000, () => {
        //   startMiniGame(ArcadeScene.SceneKey, CryptoBomberScene.SceneKey);
        // });

        break;
      case CryptoBomberGamePhase.PreGame:
        this._localPlayer.isAllowedToMove = false;
        // this._waitingMessage = new CryptoBomberWaitingForPlayers(this);
        // this.add.existing(this._waitingMessage);

        this.centerEverything();
        break;

      case CryptoBomberGamePhase.Ready:
        // Remove the countdown if it's there
        if (this._waitingCountdown) {
          overlayScene.removeAnchoredObject(this._waitingCountdown);
        }
        dispatchEvent(EnumUiEvents.UiOpenModal, "");

        this._readyCountdown = new CryptoBomberReadyCountdown(this);
        overlayScene.addAnchoredObject(this._readyCountdown, {
          x: { anchor: ScreenAnchorPosition.Center, value: 0 },
          y: { anchor: ScreenAnchorPosition.Center, value: 0 },
        });

        this.centerEverything();
        dispatchEvent(EnumGameEvents.GameBombermanReady, {});
        break;

      case CryptoBomberGamePhase.WaitingForMaximumNumberOfPlayers:
        if (this._waitingMessage) {
          overlayScene.removeAnchoredObject(this._waitingMessage);
        }

        this._waitingCountdown = new CryptoBomberWaitingForPlayersCountdown(
          overlayScene,
          data,
        );
        overlayScene.addAnchoredObject(this._waitingCountdown, {
          x: { anchor: ScreenAnchorPosition.Center, value: 0 },
          y: { anchor: ScreenAnchorPosition.Center, value: 0 },
        });
        // this.add.existing(this._waitingCountdown);

        this.centerEverything();
        break;
    }
  }
}
