import Sprite = Phaser.GameObjects.Sprite;
import Body = Phaser.Physics.Arcade.Body;
import Vector2 = Phaser.Math.Vector2;
import ANIMATION_COMPLETE = Phaser.Animations.Events.ANIMATION_COMPLETE;
import Tween = Phaser.Tweens.Tween;
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import CryptoBomberPlayerCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberPlayerCharacter";
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { appear, collect } from "@game/engine/utilities/AnimationHelper";
import CryptoBomberCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberCharacter";
import { dispatchEvent, EnumGameEvents } from "@shared/Events";
import { PowerUpType } from "@common/modules/cryptobomber/enums/PowerUpType";

interface ITextureAndAnimationData {
  endFrame: number;
  textureName: string;
  yoyo: boolean;
}

const TypeToTextureAndAnimationData: {
  [key: number]: ITextureAndAnimationData;
} = {};
TypeToTextureAndAnimationData[PowerUpType.BombUp] = {
  endFrame: 5,
  textureName: "bomb_up_spritesheet",
  yoyo: true,
};
TypeToTextureAndAnimationData[PowerUpType.RangeUp] = {
  endFrame: 15,
  textureName: "range_up_spritesheet",
  yoyo: false,
};
TypeToTextureAndAnimationData[PowerUpType.SpeedUp] = {
  endFrame: 4,
  textureName: "speed_up_spritesheet",
  yoyo: true,
};

export default class CryptoBomberPowerUp
  extends Sprite
  implements ITopDownOrderable
{
  public static EventTypes = {
    Collecting: "powerupcollecting",
    Collected: "powerupcollected",
  };

  public declare body: Body;

  private _animationName: string = "idle";
  private _appearTweens: Tween[];

  constructor(
    scene: CryptoBomberScene,
    public tilePosition: TilePosition3D,
    private _powerUpType: PowerUpType,
  ) {
    const { endFrame, textureName, yoyo } =
      TypeToTextureAndAnimationData[_powerUpType];
    const worldPosition: Vector2 =
      scene.worldPositionAtTilePosition(tilePosition);
    super(scene, worldPosition.x, worldPosition.y + 32 + 7, textureName);

    // Set up physics
    scene.physics.world.enable(this);
    this.body
      .setCircle(29)
      .setOffset(8, this.height - 66)
      .setImmovable(true);

    // Create a neat little idle animation
    this.anims.create({
      key: this._animationName,
      frameRate: 14,
      frames: this.anims.generateFrameNumbers(textureName, {
        start: 0,
        end: endFrame,
      }),
      repeat: -1,
      yoyo: yoyo,
    });
    this.anims.play(this._animationName);

    this.setOrigin(0.5, 1);

    this.setDepth(10);
  }

  public appear() {
    this._appearTweens = appear(this.scene.tweens, this, true, 256);
  }

  public collect = (player: CryptoBomberCharacter) => {
    // Update the UI with collected powerup
    dispatchEvent(EnumGameEvents.GameBombermanPowerup, {
      type: this._powerUpType,
      playerId: player.identifier,
    });
    this._appearTweens.forEach((tween) => {
      tween.stop();
    });
    // Make sure it can't be picked up anymore
    this.body.destroy();

    // Only if it's a player do the actual collecting
    if (player instanceof CryptoBomberPlayerCharacter) {
      // Apply the power-up
      switch (this._powerUpType) {
        case PowerUpType.BombUp:
          player.upMaximumAmountOfPlacableBombs();
          break;
        case PowerUpType.RangeUp:
          player.upBombRange();
          break;
        case PowerUpType.SpeedUp:
          player.upSpeedLevel();
          break;
      }
    }

    // We're starting the collecting
    this.emit(CryptoBomberPowerUp.EventTypes.Collecting, this);

    const duration = 128;
    // Play a little collect animation
    collect(
      this.scene.tweens,
      this,
      player,
      true,
      duration,
      new Vector2(0, -96),
    ).once(ANIMATION_COMPLETE, () => {
      this.emit(CryptoBomberPowerUp.EventTypes.Collected, this);
      this.destroy(true);
    });

    this.scene.time.delayedCall(duration, () => {
      this.destroy(true);
    });
  };
}
