import { MatchData } from "@heroiclabs/nakama-js";
import { NetworkedComponent } from "@game/engine/components/scenecomponents/v2/NetworkedComponent";
import { Vector2Like } from "@common/interfaces/Vector2Like";
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import { CryptoBomberOpCodes } from "@common/modules/cryptobomber/opcodes/CryptoBomberOpCodes";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { Direction } from "@game/engine/navigation/Direction";
import { TilePosition2DToTilePosition3D } from "@common/interfaces/TilePosition3D";
import { ICryptoBomberPlayerData } from "@common/modules/cryptobomber/interfaces/ICryptoBomberPlayerData";
import { CryptoBomberGamePhase } from "@common/modules/cryptobomber/enums/CryptoBomberGamePhase";
import { ICryptoBomberPowerUpData } from "@common/modules/cryptobomber/interfaces/ICryptoBomberPowerUpData";
import { GameEndReason } from "@common/modules/cryptobomber/enums/GameEndReason";
import { TilePosition3DUtilities } from "@common/utilities/TilePositionUtilities";
import { RemovalReason } from "@common/modules/cryptobomber/enums/RemovalReason";
import { Network } from "@network";

interface IOnBombPlacedData {
  ownerId: string;
  range: number;
  tilePosition: TilePosition2D;
}

interface IOnInitializedWorldData {
  countdown: number;
  cratePositions: TilePosition2D[];
  otherPlayers: ICryptoBomberPlayerData[];
  phase: CryptoBomberGamePhase;
}

interface IOnPlayerLeftData {
  removalReason: RemovalReason;
  userId: string;
}

interface IOnPlayerMovedData {
  playerId: string;
  position: Vector2Like;
}

interface IOnPlayersRankedData {
  reason: GameEndReason;
  winners: ICryptoBomberPlayerData[];
}

interface IOnPowerUpCollectedData {
  collectorId: string;
  tilePosition: TilePosition2D;
}

interface IOnTileConqueredData {
  playerId: string;
  tilePosition: TilePosition2D;
}

export class CryptoBomberNetworkedComponent extends NetworkedComponent {
  protected declare _scene: CryptoBomberScene;

  private _lastPlayerPosition: Vector2Like;
  private readonly _movementUpdateTicker;
  private _playerHasMoved: boolean;
  private readonly _tickRate: number = 20;

  constructor(matchData: { [key: string]: string }) {
    super("cryptobomberv2", matchData);

    this._playerHasMoved = false;

    // Make the movement only update once every x seconds and not always (same as tickrate)
    this._movementUpdateTicker = setInterval(() => {
      if (this._playerHasMoved) {
        this.sendMatchState(
          CryptoBomberOpCodes.OnPlayerMoved,
          this._lastPlayerPosition,
        );
        this._playerHasMoved = false;
      }
    }, 1000 / this._tickRate);
  }

  public override async findAndJoinMatch(): Promise<boolean> {
    return super.findAndJoinMatch(this._module, this._matchData);
  }

  public sendPlaceBomb(tilePosition: TilePosition2D) {
    this.sendMatchState(CryptoBomberOpCodes.OnBombPlaced, tilePosition);
  }

  public sendPlayerPosition(position: Vector2Like) {
    this._lastPlayerPosition = position;
    this._playerHasMoved = true;
  }

  public sendPlayerStartMovement(direction: Direction) {
    this.sendMatchState(CryptoBomberOpCodes.OnPlayerStartMovement, {
      direction,
    });
  }

  public sendPlayerStopMovement() {
    this.sendMatchState(CryptoBomberOpCodes.OnPlayerStopMovement);
  }

  public sendPowerUpCollected(tilePosition: TilePosition3D) {
    this.sendMatchState(
      CryptoBomberOpCodes.OnPowerUpCollected,
      TilePosition3DUtilities.to2D(tilePosition),
    );
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case CryptoBomberOpCodes.OnBombPlaced: {
        const { ownerId, range, tilePosition } =
          this.decodeAndParseMatchData<IOnBombPlacedData>(matchData.data);
        this._scene.placeBomb(
          ownerId,
          TilePosition2DToTilePosition3D(tilePosition),
          range,
        );
        break;
      }

      case CryptoBomberOpCodes.OnGameMovedToPhase: {
        const phase = <CryptoBomberGamePhase>(
          parseInt(this.decodeMatchData(matchData.data))
        );
        switch (phase) {
          case CryptoBomberGamePhase.WaitingForMaximumNumberOfPlayers:
            this._scene.startWaitingForPlayers();
            break;

          case CryptoBomberGamePhase.Ready:
            this._scene.gameReady();
            break;

          case CryptoBomberGamePhase.Playing:
            this._scene.gameStart();
            break;
        }
        break;
      }

      case CryptoBomberOpCodes.OnTileConquered: {
        const { playerId, tilePosition } =
          this.decodeAndParseMatchData<IOnTileConqueredData>(matchData.data);

        this._scene.conquerTile(
          TilePosition2DToTilePosition3D(tilePosition),
          playerId,
        );
        break;
      }

      case CryptoBomberOpCodes.OnInitializeWorld: {
        const { countdown, cratePositions, otherPlayers, phase } =
          this.decodeAndParseMatchData<IOnInitializedWorldData>(matchData.data);

        this._scene.initializeWorld(
          phase,
          this._network.currentUser.id,
          otherPlayers,
          cratePositions,
          countdown,
        );
        break;
      }

      case CryptoBomberOpCodes.OnPlayersRanked: {
        const { reason, winners } =
          this.decodeAndParseMatchData<IOnPlayersRankedData>(matchData.data);

        this._scene.endGame(winners, reason);
        break;
      }

      case CryptoBomberOpCodes.OnPlayerJoined: {
        const player = this.decodeAndParseMatchData<ICryptoBomberPlayerData>(
          matchData.data,
        );

        this._scene.addPlayer(player);
        break;
      }

      case CryptoBomberOpCodes.OnPlayerLeft: {
        const { userId, removalReason } =
          this.decodeAndParseMatchData<IOnPlayerLeftData>(matchData.data);

        if (removalReason === RemovalReason.Kicked) {
          if (userId === Network.Core.currentUser.id) {
            this._scene.kicked();
            break;
          }
        }
        this._scene.removePlayer(userId);

        break;
      }

      case CryptoBomberOpCodes.OnPlayerMoved: {
        const { playerId, position } =
          this.decodeAndParseMatchData<IOnPlayerMovedData>(matchData.data);

        this._scene.movePlayerTo(playerId, position);
        break;
      }

      case CryptoBomberOpCodes.OnPowerUpsFound: {
        const powerUpFoundDatas = this.decodeAndParseMatchData<
          ICryptoBomberPowerUpData[]
        >(matchData.data);

        for (const powerUpData of powerUpFoundDatas) {
          if (CryptoBomberScene.DEBUG.POWER_UPS) {
            console.log(
              "[CryptoBomber] Powerup spawned on " +
                JSON.stringify(powerUpData.tilePosition),
            );
          }
          this._scene.placePowerUp(
            TilePosition2DToTilePosition3D(powerUpData.tilePosition),
            powerUpData.type,
          );
        }
        break;
      }

      case CryptoBomberOpCodes.OnPowerUpCollected: {
        const { collectorId, tilePosition } =
          this.decodeAndParseMatchData<IOnPowerUpCollectedData>(matchData.data);
        if (CryptoBomberScene.DEBUG.POWER_UPS) {
          console.log(
            "[CryptoBomber] Powerup collected on " +
              JSON.stringify(tilePosition),
          );
        }
        this._scene.powerUpCollected(
          collectorId,
          TilePosition2DToTilePosition3D(tilePosition),
        );
        break;
      }

      case CryptoBomberOpCodes.OnPlayersDamaged: {
        const playerIds = this.decodeAndParseMatchData<string[]>(
          matchData.data,
        );

        if (CryptoBomberScene.DEBUG.PLAYER_DAMAGED) {
          console.log("[CryptoBomber] Players damaged " + playerIds.length);
          for (const playerId of playerIds) {
            console.log("[CryptoBomber] Damaged " + playerId);
          }
        }

        this._scene.damagePlayers(playerIds);
        break;
      }

      case CryptoBomberOpCodes.OnPlayerStartMovement: {
        const playerId = this.decodeMatchData(matchData.data);

        this._scene.startPlayerAnimation(playerId);
        break;
      }

      case CryptoBomberOpCodes.OnPlayerStopMovement: {
        const playerId = this.decodeMatchData(matchData.data);

        this._scene.stopPlayerAnimation(playerId);
        break;
      }
    }
  }

  protected onShutdown() {
    super.onShutdown();

    clearInterval(this._movementUpdateTicker);
  }
}
