import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import LoadingSceneComponent from "@engine/components/scenecomponents/LoadingSceneComponent";
import StampWarsCharacter from "@game/mini/stamp_wars/gameobjects/StampWarsCharacter";
import { usableCharacterNames } from "@shared/consts/Characters";
import loadCharacterAtlasAndJSONIntoScene from "@engine/utilities/AssetLoaderHelper";
import StampWarsBoard from "@game/mini/stamp_wars/gameobjects/StampWarsBoard";
import { IStampWarsPlayerData } from "@common/modules/stampwars/interfaces/IStampWarsPlayerData";
import {
  IOnGameCompleteData,
  IOnTickData,
  StampWarsNetworkedComponent,
} from "@game/mini/stamp_wars/components/scenecomponents/StampWarsNetworkedComponent";
import { Network } from "@network";
import { TNakamaUser } from "@shared/types/nakama";
import { AngleToDirection } from "@game/mini/memory/client/navigation/AngleToDirection";
import MovementInputComponent from "@engine/components/scenecomponents/MovementInputComponent";
import { STAMP_WARS_TILE_LEVEL_MAX } from "@common/modules/stampwars/constants/StampWarsConstants";
import {
  dispatchEvent,
  EnumApplicationEvents,
  EnumGameEvents,
} from "@shared/Events";
import NarratorComponent from "@engine/components/scenecomponents/NarratorComponent";
import TileChangeFX from "@game/mini/stamp_wars/gameobjects/TileChangeFX";
import TileFillAnimation from "@game/mini/stamp_wars/gameobjects/TileFillAnimation";
import { EnumNetworkEvents } from "@overlay/enums/Events";
import MainLabel from "@game/mini/stamp_wars/gameobjects/MainLabel";
import OverlayScene, {
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import Vector2 = Phaser.Math.Vector2;
import Image = Phaser.GameObjects.Image;
import TileSprite = Phaser.GameObjects.TileSprite;
import { Toast } from "@engine/notifications/Toast";
import TouchControlsSceneComponent from "@engine/components/scenecomponents/TouchControlsSceneComponent";
import Sprite = Phaser.GameObjects.Sprite;

export const STAMP_WARS_TEAM_COLORS = [
  "#D73DC9",
  "#8353E0",
  "#00A9B6",
  "#DF9127",
];

export const STAMP_WARS_TEAM_COLORS_LIGHT = [
  "#FDA2D3",
  "#E2A7F9",
  "#81DDD3",
  "#FFFFC3",
];

export default class StampWarsScene extends IsometricGameScene {
  public get audioComponent(): AudioComponent {
    return this._audioComponent;
  }
  public static SceneKey = "StampWarsScene";

  private _audioComponent: AudioComponent;
  private _backgroundTerrain: Image;
  private _backgroundWater: TileSprite;
  private _board: StampWarsBoard;
  private _loadingSceneComponent: LoadingSceneComponent;
  private _mainLabel: MainLabel;
  private _matchData: { [key: string]: string };
  private _movementInputComponent: MovementInputComponent;
  private _narratorComponent: NarratorComponent;
  private _networkedComponent: StampWarsNetworkedComponent;
  private _pinchAnim: Sprite;
  private _playerCharacters: { [key: string]: StampWarsCharacter } = {};
  private _playerId: string;
  private _playerCharacterName: string;
  private _playersNakamaData: TNakamaUser[] = [];
  private _tileChangeFX: TileChangeFX;
  private _touchControlsSceneComponent: TouchControlsSceneComponent;

  constructor() {
    super(
      {
        key: StampWarsScene.SceneKey,
        debugMode: false,
      },
      "/assets/game/mini/stamp_wars/",
      "stamp_wars.tmj",
      ["stamp_wars_tileset.png"],
    );
  }

  public getPositionForTileAt(x: number, y: number): Vector2Like {
    const tile = this._board.getTileAt(x, y);
    return this.map.tileToWorldXY(
      x + 1,
      y,
      undefined,
      this.cameras.main,
      tile.layer.name,
    );
  }

  public async initializeWorld(
    players: IStampWarsPlayerData[],
    userId: string,
    countdown: number,
  ) {
    this._playerId = userId;
    this._playerCharacterName = await this.getPlayerCharacterName(userId);

    players.forEach((playerData) => {
      if (playerData.id !== this._playerId) {
        this.instantiateCharacter(playerData).then((character) => {
          this._playerCharacters[playerData.id] = character;
        });
      }
    });
  }

  public startWaitingForPlayers() {
    this._mainLabel.show();
    this._mainLabel.setText("Waiting for more players...");
  }

  public onCreated() {
    this.audioComponent.addBackgroundMusic({
      key: "stampwar_music",
      type: 0,
      path: "/assets/game/mini/stamp_wars/audio/stampwars_music.mp3",
    });
    this._audioComponent.addSoundEffects([
      {
        key: "tile_lock",
        path: "/assets/game/mini/stamp_wars/audio/tile_lock.mp3",
      },
      {
        key: "players_collision",
        path: "/assets/game/mini/stamp_wars/audio/players_collision.mp3",
      },
      {
        key: "fill_area",
        path: "/assets/game/mini/stamp_wars/audio/fill_area.mp3",
      },
    ]);

    this._audioComponent.playBackgroundAudio(true);
  }

  public onGameComplete(completeData: IOnGameCompleteData) {
    const winnerTeam = completeData.tilesPerTeam.reduce(
      (iMax, x, i, arr) => (x > arr[iMax] ? i : iMax),
      0,
    );
    const winnerPlayer = completeData.players.find(
      (playerData) => playerData.team === winnerTeam,
    );
    const winnerName = this._playersNakamaData.find(
      (data) => data.id === winnerPlayer.id,
    ).username;

    this._narratorComponent.narrate("Game Finished!");
    this._narratorComponent.narrate(
      `${winnerName} won the game with ${completeData.tilesPerTeam[winnerTeam]} tiles!`,
    );

    this.time.delayedCall(
      1000,
      this.showGameCompleteDialog,
      [completeData.players],
      this,
    );
  }

  public onGameStarted() {
    this._mainLabel.setColor("#72ee5f");
    this._mainLabel.setText("GO!");
    this._mainLabel.playScaleOut();

    for (const key in this._playerCharacters) {
      this._playerCharacters[key].stopBouncing();
    }
  }

  public onPlayerJoined(playerData: IStampWarsPlayerData) {
    this.instantiateCharacter(playerData).then((character) => {
      this._playerCharacters[playerData.id] = character;

      const playerName = this._playersNakamaData.find(
        (data) => data.id === playerData.id,
      ).username;
      this._narratorComponent.narrate(
        playerName + " joined the game.",
        0,
        "#5fe067",
      );

      if (playerData.id === this._playerId) {
        this.cameras.main.startFollow(character);
        this.cameras.main.stopFollow();
      }
    });
  }

  public onPlayerLeft(userId: string) {
    const playerName = this._playersNakamaData.find(
      (data) => data.id === userId,
    ).username;
    this._narratorComponent.narrate(
      playerName + " left the game.",
      0,
      "#b03e3e",
    );
    this._playerCharacters[userId].destroy();
  }

  public onServerCountdownUpdate(secondsLeft: number) {
    this._mainLabel.show();

    if (secondsLeft > 5) {
      this._mainLabel.setText(`Game starts in ${secondsLeft} seconds`);
    } else {
      this._mainLabel.setColor("#ecea66");
      this._mainLabel.setText(secondsLeft.toString());
      this._mainLabel.playScaleDown();
    }
  }

  public onServerTick(tickData: IOnTickData) {
    this.movePlayersOnTick(tickData);
    this.updateStatusUI(tickData);
  }

  public override update(time: number, delta: number) {
    for (const key in this._playerCharacters) {
      this._playerCharacters[key].setDepth(
        1000 + this._playerCharacters[key].y,
      );
    }

    super.update(time, delta);
  }

  protected override init(data?: object): void {
    this._matchData = (data as any)?.matchData;

    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });
  }

  protected preload() {
    super.preload();

    this.load.atlas(
      "stamp_wars_atlas",
      "/assets/game/mini/stamp_wars/textures/stamp_wars_atlas.png",
      "/assets/game/mini/stamp_wars/textures/stamp_wars_atlas.json",
    );

    this.load.image(
      "stampwars_background",
      "/assets/game/mini/stamp_wars/textures/rock_platform.png",
    );

    this.load.image(
      "stampwars_tiled_background",
      "/assets/game/mini/stamp_wars/textures/bg_panel.png",
    );
    this.load.audio(
      "music",
      "/assets/game/mini/stamp_wars/audio/stampwars_music.mp3",
    );
    this.load.audio(
      "tile_lock",
      "/assets/game/mini/stamp_wars/audio/tile_lock.mp3",
    );
    this.load.audio(
      "players_collision",
      "/assets/game/mini/stamp_wars/audio/players_collision.mp3",
    );
    this.load.audio(
      "fill_area",
      "/assets/game/mini/stamp_wars/audio/fill_area.mp3",
    );

    if (!this.game.device.os.desktop) {
      this.load.atlas(
        "pinch_anim",
        "/assets/game/mini/common/pinch_anim.png",
        "/assets/game/mini/common/pinch_anim.json",
      );
    }

    usableCharacterNames.forEach((characterName) => {
      loadCharacterAtlasAndJSONIntoScene(this, characterName);
    });
  }

  protected override create() {
    super.create();

    this.initializeCamera();

    this._board = new StampWarsBoard(this.map);

    this._backgroundWater = new TileSprite(
      this,
      0,
      1012,
      2050,
      1100,
      "stampwars_tiled_background",
    )
      .setScale(2)
      .setDepth(-999);
    this.add.existing(this._backgroundWater);

    this._backgroundTerrain = new Image(this, 44, 1012, "stampwars_background");
    this._backgroundTerrain.setDepth(-998);
    this.add.existing(this._backgroundTerrain);

    this._loadingSceneComponent.setText("done");
    this._loadingSceneComponent.hide();

    this._movementInputComponent = this.addComponent<MovementInputComponent>(
      new MovementInputComponent(),
    );
    this._movementInputComponent.on(
      MovementInputComponent.EventTypes.DirectionChanged,
      this.onInputDirectionChanged,
      this,
    );

    this._narratorComponent = this.addComponent<NarratorComponent>(
      new NarratorComponent(),
    );
    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );

    if (!this.game.device.os.desktop) {
      this._touchControlsSceneComponent =
        this.addComponent<TouchControlsSceneComponent>(
          new TouchControlsSceneComponent(
            {
              pinchZoom: true,
              pointerDragCamera: true,
              zoomLimits: { max: 1, min: 0.15 },
            },
            { enable: true },
            false,
          ),
        );

      this._movementInputComponent.addVirtualJoystick(
        this._touchControlsSceneComponent.joystick,
      );
    }

    const overlay = this.scene.get("OverlayScene") as OverlayScene;

    this._tileChangeFX = new TileChangeFX(this);
    this._mainLabel = new MainLabel(overlay);

    overlay.addAnchoredObject(this._mainLabel, {
      x: { anchor: ScreenAnchorPosition.Center, value: 0 },
      y: { anchor: ScreenAnchorPosition.Normal, value: 50 },
    });

    this.preparePinchAnim();

    this.initializeNetwork();
    this.hookSceneEvents();

    this.events.on("update", this.update, this);
    this.scale.on("resize", this.onResize, this);
  }

  protected onTileClicked(tile, tilePosition) {
    console.log(
      "[TileChecks] - On Tile Clicked: " + JSON.stringify(tilePosition),
    );
    this._networkedComponent.sendPlayerMove(tilePosition);
  }

  protected onShutdown() {
    this.scale.off("resize", this.onResize, this);
    this.events.off("update", this.update, this);

    const overlay = this.scene.get("OverlayScene") as OverlayScene;
    overlay.removeAnchoredObject(this._mainLabel);

    if (this._pinchAnim) {
      overlay.removeAnchoredObject(this._pinchAnim);
    }

    super.onShutdown();
  }

  private async getPlayerCharacterName(
    userId: string,
  ): Promise<string | undefined> {
    const userData = await Network.Core.core.getUsersData(userId);

    if (
      userData.length > 0 &&
      "metadata" in userData[0] &&
      "activeCharacter" in userData[0].metadata &&
      "name" in userData[0].metadata.activeCharacter
    ) {
      this._playersNakamaData.push(userData[0]);
      return userData[0].metadata.activeCharacter.name;
    }

    return undefined;
  }

  private hookSceneEvents() {
    this.events.on("destroy", this.onDestroy, this);
  }

  private initializeCamera() {
    const defaultHeight = 944;
    const scale = this.scale;

    this.time.delayedCall(2000, () => {
      const camera = this.cameras.main;
      const startScroll = { x: camera.scrollX, y: camera.scrollY };
      const endScroll = {
        x: -this.scale.width / 2,
        y: this.scale.height / 2 + defaultHeight - scale.height,
      };
      const startZoom = 1;
      const endZoom = (0.5 * scale.height) / defaultHeight;

      this.tweens.addCounter({
        from: 0,
        to: 1,
        duration: 2000,
        onUpdate: (tween) => {
          const ease = Phaser.Math.Easing.Sine.Out(tween.getValue());
          camera.setZoom(startZoom + (endZoom - startZoom) * ease);
          camera.setScroll(
            startScroll.x + (endScroll.x - startScroll.x) * ease,
            startScroll.y + (endScroll.y - startScroll.y) * ease,
          );
        },
      });
    });
  }

  private async initializeNetwork() {
    this._networkedComponent = this.addComponent<StampWarsNetworkedComponent>(
      new StampWarsNetworkedComponent(this._matchData),
    );
    await this._networkedComponent.connect();

    if (this._matchData.matchId) {
      try {
        await this._networkedComponent.joinMatch(this._matchData.matchId);
      } catch (error) {
        // Can't join the match because it has already started
        if ((error as { code: number; message: string }).code === 5) {
          dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
            sceneKey: "ArcadeScene",
            senderSceneKey: "StampWarsScene",
          });
          Toast.error(
            "Could not join the match, the game has already started.",
          );
        }
      }
    } else {
      await this._networkedComponent.findAndJoinMatch();
    }
  }

  private async instantiateCharacter(
    playerData: IStampWarsPlayerData,
  ): Promise<StampWarsCharacter> {
    const characterName = await this.getPlayerCharacterName(playerData.id);
    const character = new StampWarsCharacter(
      this,
      characterName,
      playerData.team,
    );
    const position = this.getPositionForTileAt(
      playerData.position.x,
      playerData.position.y,
    );
    const centerPos = this.getPositionForTileAt(
      Math.floor(this.map.width / 2),
      Math.floor(this.map.height / 2),
    );

    character.setPosition(position.x, position.y);
    character.setTilePosition(playerData.position.x, playerData.position.y);
    character.setDepth(1000 + character.y);
    character.bounce();

    character.face(
      AngleToDirection(
        Phaser.Math.Angle.Between(
          position.x,
          position.y,
          centerPos.x,
          centerPos.y,
        ),
      ),
    );
    this.add.existing(character);

    dispatchEvent(
      playerData.id === this._playerId
        ? EnumGameEvents.GameStampWarsPlayerJoined
        : EnumGameEvents.GameStampWarsOpponentJoined,
      {
        id: playerData.id,
        color: STAMP_WARS_TEAM_COLORS[playerData.team],
        team: playerData.team,
      },
    );

    return character;
  }

  private movePlayersOnTick(tickData: IOnTickData) {
    tickData.players.forEach((playerTick) => {
      const character = this._playerCharacters[playerTick.id];
      const worldTargetPosition = this.getPositionForTileAt(
        playerTick.target.x,
        playerTick.target.y,
      );

      if (playerTick.filledAreas.length > 0) {
        playerTick.filledAreas.forEach((filledArea, index) => {
          this.time.delayedCall(500 + 200 * index, () => {
            new TileFillAnimation(
              this,
              this._board,
              this._tileChangeFX,
            ).playFill(filledArea, character.worldPosition, character.team);

            filledArea.forEach((tilePosition) => {
              this._board.setTileTeamAndLevel(
                tilePosition,
                character.team,
                STAMP_WARS_TILE_LEVEL_MAX,
                true,
              );
            });
          });
        });
      }

      character.jumpTo(worldTargetPosition, playerTick.collided);

      if (!playerTick.collided) {
        character.setTilePosition(playerTick.target.x, playerTick.target.y);
        this.time.delayedCall(
          500,
          () => {
            const variation = this._board.playerStepOnTile(
              character.team,
              playerTick.target,
            );
            if (variation > 0) {
              this._tileChangeFX.playAt(
                worldTargetPosition,
                Phaser.Display.Color.ValueToColor(
                  STAMP_WARS_TEAM_COLORS[character.team],
                ).color,
                variation === 2,
              );
              if (variation === 2) {
                this._audioComponent.play("tile_lock");
              }
            }
          },
          undefined,
          this,
        );
      }
    });
  }

  private onDestroy() {
    this.events.off("destroy", this.onDestroy, this);
  }

  private onInputDirectionChanged(direction: Vector2) {
    const playerPosition = this._playerCharacters[this._playerId].tilePosition;
    const targetTile = {
      x: playerPosition.x,
      y: playerPosition.y,
    };
    if (direction.equals(Vector2.ZERO)) {
      this._networkedComponent.sendPlayerMove(playerPosition);
      return;
    }

    direction.rotate(-Math.PI / 4);

    while (
      targetTile.x >= 0 &&
      targetTile.y >= 0 &&
      targetTile.x < this._board.width &&
      targetTile.y < this._board.height
    ) {
      targetTile.x += direction.x;
      targetTile.y += direction.y;
    }

    targetTile.x = Math.round(targetTile.x);
    targetTile.y = Math.round(targetTile.y);

    this._networkedComponent.sendPlayerMove(targetTile);
  }

  private onResize() {
    this.cameras.main.setScroll(
      -this.scale.width / 2,
      this.scale.height / 2 + 944 - this.scale.height,
    );
    this.cameras.main.setZoom((0.5 * this.scale.height) / 944);

    this._mainLabel.onSceneResize();
  }

  private preparePinchAnim() {
    const overlay = this.scene.get("OverlayScene") as OverlayScene;

    if (!this.game.device.os.desktop) {
      this._pinchAnim = new Sprite(overlay, 0, 0, "pinch_anim");
      this._pinchAnim.anims.create({
        key: "pinch",
        frameRate: 10,
        frames: this._pinchAnim.anims.generateFrameNames("pinch_anim", {
          prefix: "handpinchfade_",
          start: 0,
          end: 20,
          zeroPad: 3,
        }),
        repeat: -1,
      });

      overlay.addAnchoredObject(this._pinchAnim, {
        x: { anchor: ScreenAnchorPosition.Center, value: 0 },
        y: { anchor: ScreenAnchorPosition.Edge, value: -150 },
      });

      this.tweens
        .add({
          targets: this._pinchAnim,
          alpha: 0,
          ease: "Sine.easeOut",
          duration: 2000,
          delay: 5000,
        })
        .once("complete", () => {
          overlay.removeAnchoredObject(this._pinchAnim);
        });

      this._pinchAnim.anims.play("pinch");
    }
  }

  private showGameCompleteDialog(players: IStampWarsPlayerData[]) {
    console.log("Showing game complete dialog");

    players.sort((a, b) => b.ticketsWon - a.ticketsWon);
    //@ts-ignore
    dispatchEvent(EnumGameEvents.GameUiMinigameShowScoreboard, {
      winners: players,
      matchData: this._matchData,
      disablePodium: false,
    });

    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  }

  private updateStatusUI(tickData: IOnTickData) {
    this.time.delayedCall(500, () => {
      const tilesPerTeam = this._board.getNumTilesPerTeam();
      dispatchEvent(EnumGameEvents.GameStampWarsTick, {
        timeLeft: tickData.timeLeft,
        tilesPerTeam: tilesPerTeam,
      });
    });
  }
}
