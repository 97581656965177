import Sprite = Phaser.GameObjects.Sprite;
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import CryptoBomberCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberCharacter";
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import ANIMATION_COMPLETE = Phaser.Animations.Events.ANIMATION_COMPLETE;

export default class CryptoBomberExplosion
  extends Sprite
  implements ITopDownOrderable
{
  public static EventTypes = {
    Fizzle: "explosionfizzle",
  };

  public get hasExploded() {
    if (!this.anims.currentFrame) {
      return false;
    }
    return this.anims.currentFrame.textureFrame > 3;
  }

  private readonly _animationName: string = "explode";

  constructor(
    scene: CryptoBomberScene,
    public owner: CryptoBomberCharacter,
    public tilePosition: TilePosition3D,
  ) {
    const worldPosition = scene.worldPositionAtTilePosition(tilePosition);
    super(scene, worldPosition.x, worldPosition.y, "explosion_spritesheet");

    this.anims.create({
      key: this._animationName,
      frameRate: 10,
      frames: this.anims.generateFrameNumbers("explosion_spritesheet", {
        start: 0,
        end: 9,
      }),
      repeat: 0,
    });

    this.play(this._animationName).once(ANIMATION_COMPLETE, () => {
      this.emit(CryptoBomberExplosion.EventTypes.Fizzle, this);

      this.destroy(true);
    });
  }
}
