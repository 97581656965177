import OrderableTopDownGameScene from "@game/mini/cryptobomber/OrderableTopDownGameScene";
import AnimatedTopDownAvatarComponent from "@game/engine/components/entitycomponents/AnimatedTopDownAvatarComponent";
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { Direction } from "@game/engine/navigation/Direction";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Pawn from "@game/engine/Pawn";
import equals, {
  TilePosition3DZero,
} from "@game/engine/utilities/TilePositionHelper";
import { formatNftName, validateCharacterName } from "@shared/Helpers";

export enum PlayerState {
  Idle = "idle",
  // Sitting = "sit",
  Walking = "walk",
}

export default class OrderableTopDownCharacter
  extends Pawn
  implements ITopDownOrderable
{
  public static EventTypes = {
    StateChanged: "playerstatechanged",
    MovedToTile: "playermovedtotile",
  };
  public declare state: PlayerState;
  public tilePosition: TilePosition3D = TilePosition3DZero;
  protected _animatedTopDownAvatarComponent: AnimatedTopDownAvatarComponent;
  protected _previousTilePosition: TilePosition3D = TilePosition3DZero;
  private readonly _scene: OrderableTopDownGameScene;

  public get direction(): Direction {
    return this._animatedTopDownAvatarComponent.direction;
  }

  constructor(
    scene: OrderableTopDownGameScene,
    x: number,
    y: number,
    characterName = "default_character",
  ) {
    super(scene, x, y);

    this._scene = scene;

    characterName = formatNftName(characterName);
    validateCharacterName(characterName);

    // Adding an animated avatar that hooksitself into the character and displays the correct animation based on the player's movement
    this._animatedTopDownAvatarComponent =
      this.addComponent<AnimatedTopDownAvatarComponent>(
        new AnimatedTopDownAvatarComponent(this._scene, characterName),
      );
  }

  public addedToScene() {
    super.addedToScene();

    // Start out idling
    this.setPlayerState(PlayerState.Idle);
  }

  public face = (direction: Direction) => {
    this._animatedTopDownAvatarComponent.faceIfNotAlreadyFacing(direction);
  };

  public setPlayerState(state: PlayerState) {
    if (this.state === state) {
      return;
    }
    const previousState: PlayerState = this.state;
    this.state = state;

    this.emit(
      OrderableTopDownCharacter.EventTypes.StateChanged,
      this,
      this.state,
      previousState,
    );
  }

  public override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    const tilePosition = this._scene.tilePositionAtCoordinates(this.x, this.y);

    if (tilePosition && !equals(tilePosition, this.tilePosition)) {
      this._previousTilePosition = this.tilePosition;
      this.tilePosition = tilePosition;

      this.emit(
        OrderableTopDownCharacter.EventTypes.MovedToTile,
        this,
        this.tilePosition,
        this._previousTilePosition,
      );
    }
  }

  public updateAvatar(characterName: string) {
    this._animatedTopDownAvatarComponent.loadCharacter(characterName);
  }
}
