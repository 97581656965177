import { IPrivateMetadata } from "@common/interfaces/IPrivateMetadata";
import { Network } from "@game/engine/networking/Network";

export async function savePushToken(pushToken: string): Promise<void> {
  // Retrieve private metadata
  const privateMetadata: IPrivateMetadata =
    await Network.Core.core.retrievePrivateMetadata();
  // Store pushtoken
  privateMetadata.pushToken = pushToken;
  await Network.Core.core.storePrivateMetadata(privateMetadata);
}

export async function removePushToken() {
  // Retrieve private metadata
  const privateMetadata: IPrivateMetadata =
    await Network.Core.core.retrievePrivateMetadata();

  privateMetadata.pushToken = "";
  await Network.Core.core.storePrivateMetadata(privateMetadata);
}
