export const GameSettings = {
  BOARD_NUM_COLS: 4,
  BOARD_NUM_ROWS: 4,
  BOARD_TILE_RESOLUTION: 4,

  TILE_HALF_WIDTH: 64,
  TILE_HALF_HEIGHT: 32,

  PLAYER_SPEED: 250,
};
