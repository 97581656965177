// noinspection JSSuspiciousNameCombination

import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import CryptoBomberCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberCharacter";
import { PlayerState } from "@game/engine/gameobjects/OrderableTopDownCharacter";
import {
  DirectionDeltas,
  DirectionsCardinal,
} from "@game/engine/navigation/Direction";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";
import { Vector2ToTilePosition3D } from "@game/engine/utilities/Utilities";
import {
  CRYPTO_BOMBER_PLAYER_MOVEMENT_SPEED_IN_PIXELS,
  CRYPTO_BOMBER_POWERUP_MAXIMUM_LEVEL_SPEED,
} from "@game/mini/cryptobomber/common/Constants";
import { TilePosition2D } from "@game/mini/cryptobomber/backend/CryptobomberBackend";
import { dispatchEvent, EnumGameEvents } from "@shared/Events";
import Body = Phaser.Physics.Arcade.Body;
import Key = Phaser.Input.Keyboard.Key;
import KeyCodes = Phaser.Input.Keyboard.KeyCodes;
import Vector2 = Phaser.Math.Vector2;

type CursorKeyMap = { [keyName: string]: Key };

export default class CryptoBomberPlayerCharacter extends CryptoBomberCharacter {
  public static EventTypes = {
    Death: "playerdeath",
    StateChanged: "playerstatechanged",
    Moved: "playermoved",
    MovedToTile: "playermovedtotile",
    StartMovement: "playerstartmovement",
    StopMovement: "playerstopmovement",
  };
  public declare body: Body;
  public isAllowedToMove: boolean = false;
  private _amountOfBombsPlaced: number;
  private _isMoving: boolean;
  private readonly _keys: { [keyName: string]: Key[] };
  private _maximumAmountOfPlacableBombs: number;
  private readonly _maximumSpeedLevel: number;
  private _movementVector: Vector2 = Vector2.ZERO;
  private readonly _speedInPixels: number;
  private _speedLevel: number;
  private _bombRange: number;

  public get bombRange(): number {
    return this._bombRange;
  }

  constructor(
    public scene: CryptoBomberScene,
    identifier: string,
    characterName: string = "default_character",
    tilePosition: TilePosition2D,
    color: number,
  ) {
    super(scene, identifier, characterName, color, tilePosition);

    this._amountOfBombsPlaced = 0;
    this._bombRange = 1;
    this._isMoving = false;
    this._keys = {};
    this._maximumAmountOfPlacableBombs = 1;
    this._maximumSpeedLevel = CRYPTO_BOMBER_POWERUP_MAXIMUM_LEVEL_SPEED;
    this._speedInPixels = CRYPTO_BOMBER_PLAYER_MOVEMENT_SPEED_IN_PIXELS;
    this._speedLevel = 0;

    // Add the WASD controls + placing bombs with space
    this.appendKeys(
      <CursorKeyMap>this.scene.input.keyboard.addKeys(
        {
          up: KeyCodes.W,
          down: KeyCodes.S,
          left: KeyCodes.A,
          right: KeyCodes.D,
          placeBomb: KeyCodes.SPACE,
        },
        false,
      ),
    );
    // Add alternative controls
    this.appendKeys(
      <CursorKeyMap>this.scene.input.keyboard.addKeys(
        {
          up: KeyCodes.UP,
          down: KeyCodes.DOWN,
          left: KeyCodes.LEFT,
          right: KeyCodes.RIGHT,
        },
        false,
      ),
    );

    if (this.scene.touchControlsComponent.isAttachedToScene) {
      this.appendKeys(
        this.scene.touchControlsComponent.getJoystickCursorKeys(),
      );
      this.scene.touchControlsComponent.onButtonClick(this.placeBomb);
    }

    // So we can read our input and such
    this.registerForTick();

    // So we can update the server of our position if needed
    this.registerForPostTick();

    // So we can place bombs
    this.allKeysOfNameOn("placeBomb", "down", this.placeBomb);

    // Add ourselves to the physicsworld
    this.scene.physics.world.enable(this);
    const radius = 16;
    this.body.setCircle(radius).setOffset(-radius, -1.5 * radius);
  }

  public deductAmountOfBombsPlaced() {
    --this._amountOfBombsPlaced;
  }

  public override postTick(deltaSeconds: number, deltaTime: number) {
    super.postTick(deltaSeconds, deltaTime);

    // Check if there was any movement
    if (this._movementVector.x !== 0 || this._movementVector.y !== 0) {
      const { x, y } = this;
      this.emit(CryptoBomberPlayerCharacter.EventTypes.Moved, { x, y });
    }
  }

  public override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    // Reset the vector
    this._movementVector.set(0, 0);

    // Recreate it based on our inputs
    let hasInput = false;
    if (this.body) {
      if (this.isAnyKeyOfNameDown("down")) {
        hasInput = true;
        this._movementVector.y = 1;
      }
      if (this.isAnyKeyOfNameDown("left")) {
        hasInput = true;
        this._movementVector.x = -1;
      }
      if (this.isAnyKeyOfNameDown("right")) {
        hasInput = true;
        this._movementVector.x = 1;
      }
      if (this.isAnyKeyOfNameDown("up")) {
        hasInput = true;
        this._movementVector.y = -1;
      }
    }
    // Face the direction we're moving
    this.faceWithMovement(this._movementVector);

    // Only animate ourselves if there's actually need for it
    if (hasInput) {
      // Keep track of when we start movement
      if (!this._isMoving) {
        this._isMoving = true;
        this.emit(CryptoBomberPlayerCharacter.EventTypes.StartMovement, this);
      }
      this.setPlayerState(PlayerState.Walking);
    } else {
      // Keep track of when we stop movement
      if (this._isMoving) {
        this._isMoving = false;
        this.emit(CryptoBomberPlayerCharacter.EventTypes.StopMovement, this);
      }
      // If we were moving beforehand, stop it
      if (this.state !== PlayerState.Idle && this.body) {
        this.body.setVelocity(this._movementVector.x, this._movementVector.y);
      }
      this.setPlayerState(PlayerState.Idle);
      return;
    }

    // Calculate the movement vector including speed
    const speed =
      this._speedInPixels +
      (this._speedLevel / this._maximumSpeedLevel) * this._speedInPixels;
    this._movementVector.normalize().multiply(new Vector2(speed, speed));

    this.applyVelocity();
  }

  public upBombRange() {
    ++this._bombRange;
  }

  public upMaximumAmountOfPlacableBombs() {
    ++this._maximumAmountOfPlacableBombs;
  }

  public upSpeedLevel() {
    this._speedLevel = Math.min(this._speedLevel + 1, this._maximumSpeedLevel);
  }

  protected override onDeath() {
    super.onDeath();

    this._movementVector.set(0, 0);
    this.applyVelocity();
    this.unregisterForTick();

    dispatchEvent(EnumGameEvents.GameUiMinigameEnd);
  }

  protected onDestroy() {
    super.onDestroy();

    // Lets give the keyboard back
    this.allKeysOfNameOff("placeBomb", "down", this.placeBomb);

    for (const keys of Object.values(this._keys)) {
      keys.map((key) => this.scene.input.keyboard.removeKey(key, true));
    }
  }

  private allKeysOfNameOff(
    keyName: string,
    event: string | symbol,
    callback: Function,
    context?: any,
  ): boolean {
    if (keyName in this._keys) {
      for (const key of this._keys[keyName]) {
        key.off(event, callback, context);
        this.scene.input.keyboard.removeKey(key);

        this._keys[keyName].splice(
          this._keys[keyName].findIndex((k) => k.keyCode === key.keyCode),
          1,
        );
      }
      return true;
    }
    return false;
  }

  private allKeysOfNameOn(
    keyName: string,
    event: string | symbol,
    callback: Function,
    context?: any,
  ): boolean {
    if (keyName in this._keys) {
      for (const key of this._keys[keyName]) {
        key.on(event, callback, context);
      }
      return true;
    }
    return false;
  }

  private appendKeys(keys: CursorKeyMap) {
    for (const keyName in keys) {
      if (keyName in this._keys) {
        this._keys[keyName].push(keys[keyName]);
      } else {
        this._keys[keyName] = [keys[keyName]];
      }
    }
  }

  private applyVelocity() {
    if (this.isAllowedToMove) {
      this.body.setVelocity(this._movementVector.x, this._movementVector.y);
    } else {
      this.body.setVelocity(0, 0);
    }
  }

  private faceWithMovement(movement: Vector2) {
    const signed = TilePositionMath.sign(Vector2ToTilePosition3D(movement));
    const corrected = { x: signed.y, y: -signed.x, z: 0 };

    // Only use the obtuse directions
    for (let index = 0; index < DirectionsCardinal.length; index++) {
      const direction = DirectionsCardinal[index];
      const delta = DirectionDeltas[direction];

      // Seems like we're moving in that direction
      if (TilePositionMath.equals(delta, corrected)) {
        this.face(direction);
        return;
      }
    }
  }

  private isAnyKeyOfNameDown(keyName: string): boolean {
    if (keyName in this._keys) {
      return this._keys[keyName].some((key) => key.isDown);
    }
    return false;
  }

  private placeBomb = () => {
    if (
      this.isAllowedToMove &&
      this.isAlive &&
      this._amountOfBombsPlaced < this._maximumAmountOfPlacableBombs
    ) {
      // Try to place a bomb and if it works, wait for it to be explode
      if (this.scene.tryToPlaceBomb(this, this.tilePosition)) {
        ++this._amountOfBombsPlaced;
      }
    }
  };
}
