import Image = Phaser.GameObjects.Image;
import { Scene } from "phaser";

export default class UIBUtton extends Phaser.GameObjects.Container {
  private _callback: () => void;
  private _callbackContext: object;
  private _frame: string;
  private _framePressed: string;
  private _image: Image;
  private _texture: string;
  private _pressed: boolean = false;
  private _pressScale: number = 1;

  constructor(
    scene: Scene,
    texture: string,
    frame: string,
    framePressed: string,
    pressScale: number = 1,
  ) {
    super(scene);

    this._frame = frame;
    this._framePressed = framePressed;
    this._pressScale = pressScale;
    this._texture = texture;

    this._image = new Image(scene, 0, 0, texture, frame);
    this.add(this._image);

    this.initEvents();
  }

  public setCallback(callback: () => void, callbackContext: object) {
    this._callback = callback;
    this._callbackContext = callbackContext;
  }

  public setEnabled(enabled: boolean) {
    this._image.input.enabled = enabled;
  }

  public setFrames(frame: string, framePressed: string) {
    this._frame = frame;
    this._framePressed = framePressed;

    this._image.setTexture(this._texture, this._frame);
  }

  private initEvents() {
    this._image.setInteractive();

    this._image.on("pointerdown", this.onPress, this);
    this._image.on("pointerup", this.onUp, this);
    this._image.on("pointerout", this.onOut, this);
  }

  private onPress() {
    this.emit("pointerdown");
    this._image.setTexture(this._texture, this._framePressed);

    this.scale = this._pressScale;
    this._pressed = true;
  }

  private onUp() {
    this._image.setTexture(this._texture, this._frame);

    if (this._callback && this._pressed) {
      this._callback.apply(this._callbackContext);
    }

    this._pressed = false;
    this.scale = 1;
  }

  onOut() {
    if (!this._image.input.enabled) return;

    this._image.setTexture(this._texture, this._frame);
    this._pressed = false;
    this.scale = 1;
  }
}
