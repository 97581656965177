import { IIsometricTilemap } from "@game/engine/interfaces/IIsometricTilemap";
import {
  TilemapImageLayer,
  TilemapLayer,
  TilemapTileLayer,
} from "@game/engine/objects/TilemapLayer";
import IsometricCharacter from "../IsometricCharacter";
import TiledGameScene from "./TiledGameScene";
import Pathfinder, { TilePosition3D } from "../navigation/Pathfinder";
import TilePath from "../objects/TilePath";
import Tile = Phaser.Tilemaps.Tile;
import PhaserTilemapLayer = Phaser.Tilemaps.TilemapLayer;
import Vector2 = Phaser.Math.Vector2;
import Rectangle = Phaser.Geom.Rectangle;
import { isMobile } from "web3modal";

export default class IsometricGameScene
  extends TiledGameScene
  implements IIsometricTilemap
{
  public tileOffset: Vector2 = new Vector2(0, 32);

  protected _lastHoveredPosition: TilePosition3D = null;
  protected _lastHoveredTile: Tile = null;
  protected _pathfinder: Pathfinder;
  protected _walkables: Phaser.Tilemaps.TilemapLayer[];
  protected _obstacles: Phaser.Tilemaps.TilemapLayer[];

  private _debugPositionText: Phaser.GameObjects.Text;

  constructor(
    config,
    assetPath: string,
    tilemapPath: string,
    tilesetPathOrPaths: string | string[],
    imageOrImages?: string | string[],
  ) {
    super(config, assetPath, tilemapPath, tilesetPathOrPaths, imageOrImages);

    this._walkables = [];
    this._obstacles = [];
  }

  public getBounds(): Rectangle {
    return new Rectangle(
      -this.map.widthInPixels * 0.5,
      0,
      this.map.widthInPixels,
      this.map.heightInPixels,
    );
  }

  public getCorrectedPosition = (position: Vector2) => {
    return new Vector2(
      position.x - this.map.tileWidth * 0.5 + this.tileOffset.x,
      position.y - this.map.tileHeight + this.tileOffset.y,
    );
  };

  public getTileAndPositionAt(
    position: Vector2,
    includeBlockedPositions: boolean = false,
  ): { tile: Tile; tilePosition: TilePosition3D } {
    // Apply tile offset
    position = this.getCorrectedPosition(position);

    for (let index = this._walkables.length - 1; index >= 0; index--) {
      const walkable = this._walkables[index];

      const tileXY = walkable.worldToTileXY(position.x, position.y);

      // Snap it to the isometric grid
      tileXY.x = Math.round(tileXY.x);
      tileXY.y = Math.round(tileXY.y);

      // Position in "3D"
      const hoveredPosition: TilePosition3D = {
        x: tileXY.x,
        y: tileXY.y,
        z: index,
      };

      // Check if this is actually an available tile and if not, hide the selector
      if (
        !includeBlockedPositions &&
        this._pathfinder.isBlocked(hoveredPosition)
      ) {
        return null;
      }

      // Retrieve the tile from the floor otherwise
      const tile = walkable.getTileAt(tileXY.x, tileXY.y);
      if (tile && tile.index > -1) {
        return { tile: tile, tilePosition: hoveredPosition };
      }
    }

    return null;
  }

  public getPathTo(
    startPosition: TilePosition3D,
    goalPosition: TilePosition3D,
  ) {
    const path: TilePath = this._pathfinder.find(startPosition, goalPosition);
    return path;
  }

  public moveCharacterTo(
    character: IsometricCharacter,
    startPosition: TilePosition3D,
    goalPosition: TilePosition3D,
  ) {
    const path: TilePath = this._pathfinder.find(startPosition, goalPosition);

    if (this._debugMode) {
      // path.nodes.forEach((node) => {
      // this.walkables[node.z].putTileAt(12, node.x, node.y);
      // });
    }

    character.follow(path);
  }

  protected create() {
    super.create();

    // Create a pathfinder for the currently loaded map
    this._pathfinder = this.createPathfinder();

    // Add all layers for now
    this._layers.forEach((tilemapLayer: TilemapLayer, index) => {
      // Only display visible layers
      if (!tilemapLayer.visible) {
        return;
      }

      // Render a tilemaplayer
      if (tilemapLayer instanceof TilemapTileLayer) {
        const layer: PhaserTilemapLayer = this.map.createLayer(
          tilemapLayer.name,
          this.tilesets,
          tilemapLayer.x,
          tilemapLayer.y,
        );
        layer.setDepth(index);

        const layerTypeProperty = tilemapLayer.properties.find(
          (x) => x.name === "Type",
        );
        // Making layers with missing property "Type" walkable as a hotfix of memory minigame bug
        const layerType = layerTypeProperty
          ? layerTypeProperty.value
          : "walkable";

        if (!layerTypeProperty) {
          console.warn(
            `Layer ${tilemapLayer.name} does not have a Type property set`,
          );
        }

        // console.log("Tilemap " + tilemapLayer.name + " Type", layerType);
        if ("walkable" === layerType) {
          this._walkables.push(layer);
        } else if ("obstacle" === layerType) {
          this._obstacles.push(layer);
        }
        // Done for this iteration
        return;
      }

      // Render an imagelayer
      if (tilemapLayer instanceof TilemapImageLayer) {
        const tilemapImageLayer: TilemapImageLayer = tilemapLayer;
        const image = this.add
          .image(
            tilemapImageLayer.x +
              this.map.tileWidth / 2 -
              this.map.widthInPixels / 2,
            tilemapImageLayer.y + this.map.tileHeight / 2,
            tilemapImageLayer.image,
          )
          .setOrigin(0);

        image.setDepth(index);

        // Done for this iteration
        return;
      }
    });

    if (this._debugMode) {
      // Adding text for some extra info
      this._debugPositionText = this.add.text(0, 400, "", {
        fontFamily: "monospace",
        fontSize: "16px",
      });
    }

    // Add input to the mouse
    this.setupInput();
  }

  protected createPathfinder(): Pathfinder {
    return new Pathfinder(this.map);
  }

  protected onNoTileSelected(): void {}

  protected onShutdown() {
    super.onShutdown();

    // Clear the array
    this._walkables.length = 0;
    this._obstacles.length = 0;

    // this.events.off('update', this.update);
    this.input.off("pointerdown", this.pointerDown);
    this.input.off("pointermove", this.pointerMoved);
  }

  protected onTileClicked(tile: Tile, tilePosition: TilePosition3D): void {}

  protected onTileHovered(tile: Tile, tilePosition: TilePosition3D): void {}

  private pointerDown = (pointer) => {
    if (!isMobile() && null !== this._lastHoveredPosition) {
      console.log("[TileChecks] -Moving to hovered position");
      this.onTileClicked(this._lastHoveredTile, this._lastHoveredPosition);
    } else {
      // Move the cursors a little bit to get the exact position
      const tileAndCorrespondingPosition = this.getTileAndPositionAt(
        new Vector2(pointer.worldX, pointer.worldY),
        true,
      );
      if (tileAndCorrespondingPosition !== null) {
        this.onTileClicked(
          tileAndCorrespondingPosition.tile,
          tileAndCorrespondingPosition.tilePosition,
        );
      }
    }
  };

  private pointerMoved = (pointer) => {
    const tileAndCorrespondingPosition = this.getTileAndPositionAt(
      new Vector2(pointer.worldX, pointer.worldY),
      true,
    );
    if (tileAndCorrespondingPosition !== null) {
      // Check if this is actually an available tile and if not, hide the selector
      if (
        this._pathfinder.isBlocked(tileAndCorrespondingPosition.tilePosition)
      ) {
        this.unselectTile();
        return;
      }

      if (this._debugMode) {
        // Just move with the pointer, it's the easiest to track on larger maps
        this._debugPositionText.x = pointer.worldX + 32;
        this._debugPositionText.y = pointer.worldY + 32;
      }
      // If it's not a different tile
      if (
        null !== this._lastHoveredPosition &&
        this._lastHoveredPosition.x ===
          tileAndCorrespondingPosition.tilePosition.x &&
        this._lastHoveredPosition.y ===
          tileAndCorrespondingPosition.tilePosition.y &&
        this._lastHoveredPosition.z ===
          tileAndCorrespondingPosition.tilePosition.z
      ) {
        return;
      }

      // Let the world know
      this._lastHoveredPosition = tileAndCorrespondingPosition.tilePosition;
      this._lastHoveredTile = tileAndCorrespondingPosition.tile;

      if (this._debugMode) {
        this._debugPositionText.text =
          "(" +
          this._lastHoveredPosition.x +
          ", " +
          this._lastHoveredPosition.y +
          ", " +
          this._lastHoveredPosition.z +
          ")";
      }

      this.onTileHovered(this._lastHoveredTile, this._lastHoveredPosition);
      return;
    }

    // If we got here, nothing is selected
    this.unselectTile();
  };

  private setupInput() {
    // Add input
    this.input.on("pointerdown", this.pointerDown);
    this.input.on("pointermove", this.pointerMoved);
  }

  private unselectTile() {
    if (this._lastHoveredPosition !== null) {
      this._lastHoveredPosition = null;
      if (this._debugMode) {
        this._debugPositionText.text = "";
      }
      this.onNoTileSelected();
    }
  }
}
