import { TilePosition } from "@game/mini/memory/backend_old/MemoryBoardManager";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import PlayerController from "@game/engine/controllers/PlayerController";
import MemoryGameCharacter from "@game/mini/memory/client/gameobjects/MemoryGameCharacter";
import IsometricUtils from "@game/mini/memory/utils/IsometricUtils";

export default class OpponentPlayerController extends PlayerController {
  public alive: boolean = true;
  private _pawn: MemoryGameCharacter;

  constructor(
    scene: IsometricGameScene,
    pawn: MemoryGameCharacter,
    protected _playerId: string,
  ) {
    super(scene, pawn);

    this._pawn = pawn;
  }

  public destroy() {
    this._pawn.destroy(true);
  }

  public start() {
    super.start();
  }

  public onMoveMessageReceived(tilePosition2D: TilePosition) {
    const isoPosition = IsometricUtils.orthoToIso(
      tilePosition2D.col + 0.5,
      tilePosition2D.row + 0.5,
    );
    this._pawn.walkTo(isoPosition.x, isoPosition.y);
  }

  public moveToIsoCoords(x: number, y: number) {
    if (this._pawn.x === x && this._pawn.y === y) return;

    this._pawn.walkTo(x, y);
  }

  public sink() {
    this._pawn.sink();
  }
}
