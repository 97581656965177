import { NetworkedComponent } from "@engine/components/scenecomponents/v2/NetworkedComponent";
import CrashScene from "../CrashScene";
import EventEmitter from "eventemitter3";
import { MatchData } from "@heroiclabs/nakama-js";
import { CrashOpCodes, CrashStates } from "@common/modules/crash/CrashModels";
import { EnumNetworkEvents, dispatchEvent } from "@shared/Events";
export class CrashNetworkedComponent extends NetworkedComponent {
  public eventEmitter: EventEmitter;
  protected declare _scene: CrashScene;

  constructor(matchData: { [key: string]: string }) {
    super("crash", matchData);
    this.eventEmitter = new EventEmitter();
  }

  public override async findAndJoinMatch(): Promise<boolean> {
    return super.findAndJoinMatch(this._module, this._matchData);
  }

  public sendGameJoin(amount: number) {
    if (amount <= 0) return;
    this.sendMatchState(CrashOpCodes.GamePlayerJoined, { amount });
    setTimeout(() => {
      return dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
    }, 1500);
  }

  public sendGameLeft() {
    this.sendMatchState(CrashOpCodes.GamePlayerLeft);
    setTimeout(
      () => dispatchEvent(EnumNetworkEvents.NetworkRequestUserData),
      1500,
    );
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case CrashOpCodes.GameState: {
        const {
          minPlayableAmount,
          state,
          timerTimestamp,
          players,
          multiplier,
          multiplierHistory,
        } = this.decodeAndParseMatchData<{
          minPlayableAmount: number;
          state: CrashStates;
          timerTimestamp: number;
          players: { userId: string; username: string; amount: number }[];
          multiplier: number;
          multiplierHistory: number[];
        }>(matchData.data);
        this._scene.onGameState(minPlayableAmount, multiplierHistory);
        switch (state) {
          case CrashStates.PreReady: {
            this._scene.onGamePreReady(timerTimestamp);
            break;
          }
          case CrashStates.Ready: {
            this._scene.onGamePrepare(timerTimestamp);
            break;
          }
          case CrashStates.Game: {
            this._scene.onGameStarted();
            this._scene.onGameTick(multiplier, 0);
            for (const player of players) {
              this._scene.onGamePlayerJoined(
                player.userId,
                player.username,
                player.amount,
              );
            }
            break;
          }
          case CrashStates.PostGame: {
            this._scene.onGameCrash(timerTimestamp, multiplier, {});
            break;
          }
        }
      }
      case CrashOpCodes.GamePreReady: {
        //About to start next round timer
        const { timestamp } = this.decodeAndParseMatchData<{
          timestamp: number;
        }>(matchData.data);
        this._scene.onGamePreReady(timestamp);
        break;
      }
      case CrashOpCodes.GamePrepare: {
        //About to start game
        const { timestamp } = this.decodeAndParseMatchData<{
          timestamp: number;
        }>(matchData.data);
        this._scene.onGamePrepare(timestamp);
        break;
      }
      case CrashOpCodes.GameStarted: {
        this._scene.onGameStarted();
        break;
      }
      case CrashOpCodes.GameTick: {
        const { multiplier, time } = this.decodeAndParseMatchData<{
          multiplier: number;
          time: number;
        }>(matchData.data);
        this._scene.onGameTick(multiplier, time);
        break;
      }
      case CrashOpCodes.GameCrash: {
        const { timestamp, multiplier, playersCrashed } =
          this.decodeAndParseMatchData<{
            timestamp: number;
            multiplier: number;
            playersCrashed: { [key: string]: number };
          }>(matchData.data);
        this._scene.onGameCrash(timestamp, multiplier, playersCrashed);
        break;
      }
      case CrashOpCodes.GamePlayerJoined: {
        const { userId, username, amount } = this.decodeAndParseMatchData<{
          userId: string;
          username: string;
          amount: number;
        }>(matchData.data);
        this._scene.onGamePlayerJoined(userId, username, amount);
        break;
      }
      case CrashOpCodes.GamePlayerLeft: {
        const { userId, username, amount, multiplier } =
          this.decodeAndParseMatchData<{
            userId: string;
            username: string;
            amount: number;
            multiplier: number;
          }>(matchData.data);
        this._scene.onGamePlayerLeft(userId, username, amount, multiplier);
        break;
      }
      case CrashOpCodes.PlayerJoined: {
        const { userId, username } = this.decodeAndParseMatchData<{
          userId: string;
          username: string;
        }>(matchData.data);
        this._scene.onPlayerJoined(userId, username);
        break;
      }
      case CrashOpCodes.PlayerLeft: {
        const { userId, username } = this.decodeAndParseMatchData<{
          userId: string;
          username: string;
        }>(matchData.data);
        this._scene.onPlayerLeft(userId, username);
        break;
      }
    }
  }

  protected onShutdown(): void {
    super.onShutdown();
  }
}
