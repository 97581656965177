import SceneComponent from "@engine/components/SceneComponent";
import GameScene from "@engine/scenes/GameScene";
import OverlayScene, {
  ScreenAnchorPosition,
} from "@engine/scenes/OverlayScene";
import Text = Phaser.GameObjects.Text;
import BitmapText = Phaser.GameObjects.BitmapText;
import Container = Phaser.GameObjects.Container;

export default class NarratorComponent extends SceneComponent {
  private _container: Container;
  private _overlayScene: OverlayScene;
  private _texts: Array<Text | BitmapText> = [];

  constructor() {
    super();
  }

  public narrate(textString: string, delay?: number, color?: string) {
    const text = new Phaser.GameObjects.Text(
      this._scene,
      40,
      -50 * this._texts.length,
      textString,
      {
        fontFamily: "Depixel",
        fontSize: "40px",
        stroke: "#000",
        strokeThickness: 12,
        color: color || "#ffffff",
      },
    )
      .setOrigin(0, 0.5)
      .setScrollFactor(0)
      .setScale(0.5);

    this._container.add(text);
    this._texts.push(text);
    this.animateText(text, delay);
  }

  public setVisible(visible: boolean) {
    this._container.visible = visible;
  }

  protected onSceneSet(scene?: GameScene) {
    super.onSceneSet(scene);

    this._overlayScene = this.scene.scene.get("OverlayScene") as OverlayScene;

    this._container = new Phaser.GameObjects.Container(
      this._overlayScene,
      -scene.scale.width / 2,
      scene.scale.height + scene.cameras.main.scrollY - 75,
    );

    this._overlayScene.addAnchoredObject(this._container, {
      x: { anchor: ScreenAnchorPosition.Normal, value: 0 },
      y: { anchor: ScreenAnchorPosition.Edge, value: -75 },
    });
  }

  protected onShutdown() {
    super.onShutdown();

    this._overlayScene.removeAnchoredObject(this._container);
  }

  private animateText(text: BitmapText | Text, delay: number = 0) {
    text.x -= 500;

    this._scene.tweens.add({
      targets: text,
      x: text.x + 500,
      ease: "Sine.easeOut",
      duration: 500,
      delay: delay,
    });

    this._scene.tweens
      .add({
        targets: text,
        alpha: 0,
        ease: "Sine.easeIn",
        duration: 2000,
        delay: delay + 5000,
      })
      .once("complete", () => this.removeText(text), this);
  }

  private removeText(text: BitmapText | Text) {
    const index = this._texts.indexOf(text);

    if (index >= 0) {
      this._texts.splice(index, 1);
    }

    this._container.remove(text, true);
    this.repositionTextsVertically();
  }

  private repositionTextsVertically() {
    this._texts.forEach((text, index) => {
      text.y = -50 * index;
    });
  }
}
