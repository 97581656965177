import LineWalkerComponent from "@game/mini/memory/client/components/LineWalkerComponent";
import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import Pawn from "@game/engine/Pawn";
import IsometricCharacter, {
  PlayerState,
} from "@game/engine/IsometricCharacter";
import AnimatedMemoryAvatarComponent from "@game/mini/memory/client/components/AnimatedMemoryAvatarComponent";
import { formatNftName } from "@shared/Helpers";

export default class MemoryGameCharacter extends Pawn {
  public declare state: PlayerState;

  private _walkerComponent: LineWalkerComponent;
  private _avatarComponent: AnimatedMemoryAvatarComponent;

  constructor(scene, x, y, characterName: string = "mr_anonymous") {
    super(scene, x, y);

    this.state = PlayerState.Idle;

    this._walkerComponent = this.addComponent<LineWalkerComponent>(
      new LineWalkerComponent(),
    );

    // Adding an animated avatar that hooksitself into the character and displays the correct animation based on the player's movement
    this._avatarComponent = this.addComponent<AnimatedMemoryAvatarComponent>(
      new AnimatedMemoryAvatarComponent(
        <IsometricGameScene>this.scene,
        formatNftName(characterName),
      ),
    );

    this.initEvents();
  }

  public sink() {
    this._avatarComponent.sink();
  }

  public stop() {
    this._walkerComponent.stop();
  }

  public walkTo(x: number, y: number) {
    this._walkerComponent.walkTo(x, y);
    this.emit(
      IsometricCharacter.EventTypes.MovedToTile,
      { x: x, y: y },
      { x: this.x, y: this.y },
    );
  }

  private initEvents() {
    this._walkerComponent.events.on(
      LineWalkerComponent.EventTypes.Completed,
      () => {
        this.setPlayerState(PlayerState.Idle);
      },
      this,
    );

    this._walkerComponent.events.on(
      LineWalkerComponent.EventTypes.Started,
      () => {
        this.setPlayerState(PlayerState.Walking);
      },
    );
  }

  private setPlayerState(state: PlayerState) {
    if (this.state === state) {
      return;
    }
    const previousState: PlayerState = this.state;
    this.state = state;

    this.emit(
      IsometricCharacter.EventTypes.StateChanged,
      this,
      this.state,
      previousState,
    );
  }
}
