import Sprite = Phaser.Physics.Arcade.Sprite;
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import CryptoBomberCharacter from "@game/mini/cryptobomber/gameobjects/CryptoBomberCharacter";
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Vector2 = Phaser.Math.Vector2;
import ANIMATION_COMPLETE = Phaser.Animations.Events.ANIMATION_COMPLETE;
import Body = Phaser.Physics.Arcade.Body;
import { CRYPTO_BOMBER_BOMB_DURATION } from "@game/mini/cryptobomber/common/Constants";

export default class CryptoBomberBomb
  extends Sprite
  implements ITopDownOrderable
{
  public static EventTypes = {
    Explode: "bombexploded",
  };

  public get range(): number {
    return this._range;
  }

  public declare body: Body;

  private readonly _animationName: string = "fuse";
  private _isExploded: boolean;

  constructor(
    scene: CryptoBomberScene,
    public owner: CryptoBomberCharacter,
    public tilePosition: TilePosition3D,
    private _range: number,
  ) {
    const worldPosition: Vector2 =
      scene.worldPositionAtTilePosition(tilePosition);
    super(scene, worldPosition.x, worldPosition.y, "bomb_spritesheet");

    this._isExploded = false;

    // Makes it so we can move out, but not back in
    scene.physics.world.enable(this);
    this.body.setSize(64, 64).setOffset(39, 32).setImmovable(true);

    this.anims.create({
      key: this._animationName,
      frameRate: 14,
      frames: this.anims.generateFrameNumbers("bomb_spritesheet", {
        start: 0,
        end: 16,
      }),
    });
    this.anims.create({
      key: "danger",
      frameRate: 14,
      frames: this.anims.generateFrameNumbers("bomb_spritesheet", {
        start: 17,
        end: 20,
      }),
      repeat: -1,
    });
    this.anims.create({
      key: "explosion",
      frameRate: 14,
      frames: this.anims.generateFrameNumbers("bomb_spritesheet", {
        start: 20,
        end: 22,
      }),
    });

    // Explode after 3500
    this.scene.time.addEvent({
      callback: this.explode,
      callbackScope: this,
      delay: CRYPTO_BOMBER_BOMB_DURATION,
    });
    this.scene.time.addEvent({
      callback: () => {
        if (this && !this._isExploded) {
          this.play("explosion");
        }
      },
      callbackScope: this,
      delay: CRYPTO_BOMBER_BOMB_DURATION - 140,
    });
  }

  public explode() {
    if (this._isExploded) {
      return;
    }

    this._isExploded = true;

    this.emit(CryptoBomberBomb.EventTypes.Explode, this);

    this.destroy(true);
  }

  public lightTheFuse() {
    // Start counting down and animate
    this.play(this._animationName).once(ANIMATION_COMPLETE, () => {
      this.play("danger");
    });
  }
}
