import { isMobile } from "web3modal";
import Vector2Like = Phaser.Types.Math.Vector2Like;

type MultiplierHistoryPoint = { multiplier: number; time: number };

const BASE_TIME_SCALE = 10; // Pixels per second
const BASE_MULTIPLIER_SCALE = 100; // Pixels per multiplier
const MARGIN_HORIZONTAL = 50; // Pixels
const MARGIN_VERTICAL = !isMobile() ? 50 : 20;
const MAX_CURVE_POINTS = 30; // 30 seems like a stable number. Very high values cause the curve to look fragmented

export class CrashGraphSpace {
  public get renderWidth(): number {
    return this._width - 2 * MARGIN_HORIZONTAL;
  }
  public get renderHeight(): number {
    return this._height - 2 * MARGIN_VERTICAL;
  }

  private _height: number;
  private _multiplierHistory: MultiplierHistoryPoint[] = [];
  private _timeScale: number = BASE_TIME_SCALE;
  private _multiplierScale: number = BASE_MULTIPLIER_SCALE;
  private _width: number;

  constructor(width: number, height: number) {
    this._width = width;
    this._height = height;
  }

  public addHistoryPoint(multiplier: number, time: number) {
    this._multiplierHistory.push({
      multiplier: multiplier,
      time: time,
    });

    this.updateScales();
  }

  public getCurvePoints(): Vector2Like[] {
    const curvePoints: Vector2Like[] = [];

    if (this._multiplierHistory.length === 0) {
      return curvePoints;
    }

    const numPoints = Math.min(
      this._multiplierHistory.length,
      MAX_CURVE_POINTS,
    );
    const lastPoint = this.historyPointToGraphPoint(
      this._multiplierHistory[this._multiplierHistory.length - 1],
    );

    for (let i = 0; i < numPoints; i++) {
      curvePoints.push(
        this.historyPointToGraphPoint(
          this._multiplierHistory[
            Math.floor(this._multiplierHistory.length / numPoints) * i
          ],
        ),
      );
    }

    curvePoints.push(lastPoint);

    return curvePoints;
  }

  public historyPointToGraphPoint(
    historyPoint: MultiplierHistoryPoint,
  ): Vector2Like {
    return {
      x: (historyPoint.time / 1000) * this._timeScale,
      y: -historyPoint.multiplier * this._multiplierScale,
    };
  }

  public reset() {
    this._multiplierHistory = [];
    this._timeScale = BASE_TIME_SCALE;
    this._multiplierScale = BASE_MULTIPLIER_SCALE;
  }

  public setDimensions(width: number, height: number) {
    this._width = width;
    this._height = height;
    this.updateScales();
  }

  private updateScales() {
    if (this._multiplierHistory.length === 0) {
      this._multiplierScale = BASE_MULTIPLIER_SCALE;
      this._timeScale = BASE_TIME_SCALE;
    } else {
      const lastHistoryPoint =
        this._multiplierHistory[this._multiplierHistory.length - 1];
      const maxTime = Math.max(1000, lastHistoryPoint.time);
      const maxMultiplier = Math.max(5, lastHistoryPoint.multiplier);

      this._timeScale =
        (this._width - 2 * MARGIN_HORIZONTAL) / (maxTime / 1000);
      this._multiplierScale =
        (this._height - 2 * MARGIN_VERTICAL) / maxMultiplier;
    }
  }
}
