import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { ISceneSwitchingData } from "@game/engine/interfaces/ISceneSwitchingData";
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import { EnumGameEvents } from "@shared/enums";
import RemotePlayerCharacter from "../lobby/gameobjects/RemotePlayerCharacter";
import { dispatchEvent } from "@shared/Events";
import OrderableNonPlayerCharacter from "@game/engine/gameobjects/OrderableNonPlayerCharacter";
import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import MoonbeamScene from "@game/mini/moonbeam/MoonbeamScene";
import ShopScene from "@game/mini/shop/ShopScene";
import Rectangle = Phaser.Geom.Rectangle;
import PlinkoScene from "../plinko/PlinkoScene";
import MightyMinesScene from "../mighty-mines/MightyMinesScene";

class AudioKeys {
  public static Theme = "arcadetheme";
}

export default class ArcadeScene extends PublicSpaceGameScene {
  public static SceneKey: string = "ArcadeScene";

  constructor() {
    super(
      {
        key: ArcadeScene.SceneKey,
      },
      "/assets/game/mini/arcade/",
      "arcade.tmj",
      [
        "minigamearcade_tileset.png",
        "OldFormat/Furniture/arcade_chair_blue/arcade_chair_blue.png",
        "OldFormat/Furniture/arcade_chair_corner_blue/arcade_chair_corner_blue.png",
        "OldFormat/Furniture/arcade_chair_pink/arcade_chair_pink.png",
        "OldFormat/Furniture/arcade_glass_table/arcade_glass_table.png",
        "OldFormat/Furniture/arcade_glass_table_tall/arcade_glass_table_tall.png",
        "OldFormat/Furniture/arcade_plant_bed/arcade_plant_bed.png",
        "OldFormat/Furniture/arcade_stool_brown/arcade_stool_brown.png",
        "OldFormat/Furniture/arcade_stool_yellow/arcade_stool_yellow.png",
        "OldFormat/Furniture/arcade_table/arcade_table.png",
        "OldFormat/Furniture/arcade_tall_chair_orange/arcade_tall_chair_orange.png",
        "OldFormat/Furniture/arcade_tall_chair_purple/arcade_tall_chair_purple.png",
        "OldFormat/Furniture/arcade_tall_chair_yellow/arcade_tall_chair_yellow.png",
        "Highlight.png",
      ], // Tilesets
      [
        "Furniture/console_fountain/console_fountain_a.png",
        "Furniture/console_fountain/console_structure.png",
        "Furniture/rubiks_cube_fountain/rubiks_cube_fountain_a_a_a.png",
        "Furniture/sign_cryptobomber/sign_cryptobomber_a.png",
        "Furniture/sign_mightymines/sign_mightymines_a.png",
        "Furniture/sign_rocketcrash/sign_rocketcrash_a.png",
        "Furniture/sign_bitplinko/sign_bitplinko_a.png",
        "Furniture/sign_fruitymemory/sign_fruitymemory_a.png",
        "Furniture/sign_stampwars_a/sign_stampwars_a.png",
        "Furniture/b_statue/b_statue_a.png",
        "Furniture/arcade_plant_bed/arcade_plant_bed_a_a_a_b.png",
        "Furniture/arcade_tall_glass_divider/arcade_tall_glass_divider_a.png",
        "Furniture/arcade_plant_bed_long/arcade_plant_bed_long_a_left.png",
        "Furniture/arcade_plant_bed_long/arcade_plant_bed_long_a_right.png",
        "Furniture/b_statue/b_statue_a_left.png",
        "Furniture/b_statue/b_statue_a_right.png",
        "Furniture/billiards_table/billiards_table_a_left.png",
        "Furniture/billiards_table/billiards_table_a_right.png",
        "Furniture/foosball_table/foosball_table_left.png",
        "Furniture/foosball_table/foosball_table_right.png",
        "Furniture/air_hockey_table/air_hockey_table_a_left.png",
        "Furniture/air_hockey_table/air_hockey_table_a_right.png",
        "Furniture/ping_pong_table/ping_pong_table_a_left.png",
        "Furniture/ping_pong_table/ping_pong_table_a_right.png",
        "Furniture/bit_bop_drop/bit_bop_drop_d_a_a_left.png",
        "Furniture/bit_bop_drop/bit_bop_drop_d_a_a_right.png",
        "Furniture/bit_bop_drop/bit_bop_drop_d_a_a_right_right.png",
        "Furniture/bit_bop_drop/bit_bop_drop_a_a.png",
        "Furniture/bit_bop_drop/bit_bop_drop_b.png",
        "Furniture/bit_bop_drop/bit_bop_drop_c.png",
        "Furniture/bit_box_machine/bit_box_machine_a.png",
        "Furniture/bit_boy_machine/bit_boyFull.png",
        "Furniture/bit_claw_machine/bit_claw_machine_a.png",
        "Furniture/bit_machine/bit_machine_a.png",
        "Furniture/space_bit_machine/space_bit_machine_a_left.png",
        "Furniture/space_bit_machine/space_bit_machine_a_right.png",
        "Furniture/doge_claw_machine/doge_claw_machine_d.png",
        "Furniture/great_bits_stand/great_bits_stand_a.png",
        "Furniture/great_bits_stand/great_bits_stand_a_top.png",
        "Furniture/great_bits_stand/great_bits_stand_a_top_right.png",
        "Furniture/great_bits_stand/great_bits_stand_b.png",
        "Furniture/great_bits_stand/great_bits_stand_c_left.png",
        "Furniture/great_bits_stand/great_bits_stand_c_right.png",
        "Furniture/hit_the_bit_machine/hit_the_bit_machine_d.png",
        "Furniture/jet_rocket_machine/jet_rocket_group.png",
        "Furniture/mightymines_machine/mightymines_group_left.png",
        "Furniture/mightymines_machine/mightymines_group_right.png",
        "Furniture/misc_arcade_machine/miscGroup1.png",
        "Furniture/misc_arcade_machine/miscGroup2.png",
        "Furniture/misc_arcade_machine/miscGroup3.png",
        "Furniture/misc_arcade_machine/miscGroup4.png",
        "Furniture/pinball_machine/pinball_machine_a_left.png",
        "Furniture/pinball_machine/pinball_machine_a_right.png",
        "Furniture/plinko_machine/plinko_machine_a.png",
        "Furniture/skee_ball_machine/skee_ball_group_left.png",
        "Furniture/skee_ball_machine/skee_ball_group_right.png",
        "Furniture/vending_machine_drinks/vending_machine_drinks_a.png",
        "Furniture/vending_machine_snacks/vending_machine_snacks_a.png",
        "Furniture/arcade_front_desk/arcade_front_desk_a_a.png",
        "Furniture/arcade_front_desk/arcade_front_desk_a_b.png",
        "Furniture/arcade_front_desk/arcade_front_desk_a_c_left.png",
        "Furniture/arcade_front_desk/arcade_front_desk_a_c_right.png",
        "Furniture/arcade_front_desk/arcade_front_desk_a_d.png",
        "Furniture/arcade_front_desk/arcade_front_desk_b.png",
        "Furniture/arcade_front_desk/arcade_front_desk_c_a_a.png",
        "Furniture/arcade_front_desk/arcade_front_desk_b_sh.png",
        "Furniture/arcade_half_wall_short/arcade_half_wall_short_a.png",
        "Furniture/arcade_half_wall_short/arcade_half_wall_short_b.png",
        "Furniture/arcade_half_wall_long/aracde_half_wall_long_a.png",
        "Furniture/arcade_half_wall_long/aracde_half_wall_long_b.png",
        "Wall/minigame_arcade_wall_c.png",
        "Wall/minigame_arcade_wall_b.png",
        "Wall/minigame_arcade_wall_a.png",
        "Wall/gameboy_entrance_pink_a.png",
        "Wall/gameboy_entrance_blue_a.png",
        "Wall/gameboy_entrance_purple_a.png",
        "Wall/gameboy_entrance_purple_a_back.png",
        "Wall/gameboy_entrance_blue_a_back.png",
        "Wall/gameboy_entrance_pink_a_back.png",
        "fogWall/fog_wall_tileable_a.png",
        "fogWall/fog_wall_tileable_b.png",
      ], // Image layers
      [
        "OldFormat/Furniture/arcade_chair_blue/arcade_chair_blue.png",
        "OldFormat/Furniture/arcade_chair_corner_blue/arcade_chair_corner_blue.png",
        "OldFormat/Furniture/arcade_chair_pink/arcade_chair_pink.png",
        "OldFormat/Furniture/arcade_glass_table/arcade_glass_table.png",
        "OldFormat/Furniture/arcade_glass_table_tall/arcade_glass_table_tall.png",
        "OldFormat/Furniture/arcade_plant_bed/arcade_plant_bed.png",
        "OldFormat/Furniture/arcade_stool_brown/arcade_stool_brown.png",
        "OldFormat/Furniture/arcade_stool_yellow/arcade_stool_yellow.png",
        "OldFormat/Furniture/arcade_table/arcade_table.png",
        "OldFormat/Furniture/arcade_tall_chair_orange/arcade_tall_chair_orange.png",
        "OldFormat/Furniture/arcade_tall_chair_purple/arcade_tall_chair_purple.png",
        "OldFormat/Furniture/arcade_tall_chair_yellow/arcade_tall_chair_yellow.png",
      ], // Spritesheets
      [], //Animation atlas
      true,
    );

    // Adding audio for loading
    // this._audioComponent.addSoundEffects();
  }

  public getBounds(): Rectangle {
    return new Rectangle(-10000, -10000, 20000, 20000);
  }

  public override getNetworkMatchIdentifier(): string {
    return "arcade";
  }

  public override onNPCInteract(npc: OrderableNonPlayerCharacter) {
    if ("do" in npc.interaction) {
      npc.twitch();
      dispatchEvent(EnumGameEvents.GameUiNpcInteraction, {
        ...npc.interaction,
        name: npc.characterName,
      });
    }

    if ("interact" in npc.interaction) {
      npc.twitch();
      npc.say(npc.interaction["interact"]);
    }
  }

  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  protected async init(data?) {
    super.init(data);

    // Check the origin and adjust the spawn point accordingly
    if (data && "origin" in data) {
      const { origin } = <ISceneSwitchingData>data;
      const validOrigins = [
        ArcadeScene.SceneKey,
        CryptoBomberScene.SceneKey,
        PlinkoScene.SceneKey,
        MightyMinesScene.SceneKey,
        MemoryGameScene.SceneKey,
        MoonbeamScene.SceneKey,
        ShopScene.SceneKey,
      ];
      if (validOrigins.indexOf(origin) > -1) {
        this.setSpawnLocation(origin);
      }
    }
  }

  protected onCreated() {
    super.onCreated();

    // this._audioComponent.pauseOnBlur = true;
    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/arcade/audio/theme.mp3",
    });
    this._audioComponent.playBackgroundAudio();
    // this._audioComponent.masterVolume = 1.0;
    // this._audioComponent.soundEffectVolume = 1.0;
  }

  protected override preload() {
    this.load.audio(
      AudioKeys.Theme,
      "/assets/game/mini/arcade/audio/theme.mp3",
    );
    super.preload();
  }
}
