import { GameObjects } from "phaser";

class YaxisComponent {
  private container: GameObjects.Container;
  private multiplierSize: number = 2;
  private multiMin: number = 1;
  private cleanup: any[] = [];
  private xPosition: number = 525;
  private yPosition: number = 330;

  constructor(ctn: GameObjects.Container, x: number, y: number) {
    this.container = new GameObjects.Container(ctn.scene, 0, 0);
    this.xPosition = x;
    this.yPosition = y;
    ctn.add(this.container);
    // this.container = ctn;

    const zero = new GameObjects.Text(
      this.container.scene,
      this.xPosition,
      this.yPosition,
      "0x",
      { font: "20px depixel", color: "#FFFFFF" },
    );
    zero.setOrigin(0, 0.5);
    this.container.add(zero);

    // const lineZero = new GameObjects.Line(this.container.scene, -590, 10, -500, 20, 0xFFFFFF);
    // this.container.add(lineZero);

    const lineZero = this.container.scene.add.graphics();
    lineZero.lineStyle(1, 0xffffff);
    lineZero.lineBetween(
      this.xPosition + 30,
      this.yPosition,
      this.xPosition + 60,
      this.yPosition,
    );
    this.container.add(lineZero);
  }

  public startRender(
    multiplier: number,
    maxWinMultiplier: number,
    x: number,
    y: number,
  ) {
    this.cleanup.forEach((c) => c.destroy());
    if (multiplier < this.multiplierSize) {
      const zero = new GameObjects.Text(
        this.container.scene,
        this.xPosition,
        this.yPosition - 700,
        "10x",
        { font: "20px depixel", color: "#FFFFFF" },
      );
      zero.setOrigin(0.5, 0.5);
      this.container.add(zero);
      const lineZero = this.container.scene.add.graphics();
      lineZero.lineStyle(1, 0xffffff);
      lineZero.lineBetween(
        this.xPosition + 30,
        this.yPosition - 700,
        this.xPosition + 60,
        this.yPosition - 700,
      );
      this.container.add(lineZero);
      this.cleanup.push(zero);
      this.cleanup.push(lineZero);

      const five = new GameObjects.Text(
        this.container.scene,
        this.xPosition,
        this.yPosition - 400,
        "5x",
        { font: "20px depixel", color: "#FFFFFF" },
      );
      five.setOrigin(0.5, 0.5);
      this.container.add(five);
      const lineFive = this.container.scene.add.graphics();
      lineFive.lineStyle(1, 0xffffff);
      lineFive.lineBetween(
        this.xPosition + 30,
        this.yPosition - 400,
        this.xPosition + 60,
        this.yPosition - 400,
      );
      this.container.add(lineFive);
      this.cleanup.push(five);
      this.cleanup.push(lineFive);
    } else if (multiplier > this.multiplierSize) {
      // const topmulti = Math.floor((700 / y) * multiplier);
      // const topmulti = Math.abs(multiplier / (y/800));

      // const topmulti = Math.floor(multiplier * 4)

      // let multiInterval = Math.floor(topmulti / 5);

      // let wholeOnes: number = 0;

      // const yInterval = 800 / 5;
      // const startYPos = 400;

      // while (wholeOnes < 4) {
      //     wholeOnes += multiInterval;

      //     const yPos = startYPos - yInterval;

      //     if (yPos > -400) {
      //     }
      // }

      const rocketAbsPosition = y + 400;
      const percentageRocket = 1 - rocketAbsPosition / 700;
      const topmulti = multiplier / percentageRocket;

      this.renderPosition(
        (1 - Math.floor(multiplier / 2) / topmulti) * 700 - 400,
        Math.floor(multiplier / 2),
      );
      this.renderPosition(
        (1 - Math.ceil(multiplier + 1) / topmulti) * 700 - 400,
        Math.ceil(multiplier + 1),
      );
      this.renderPosition(
        (1 - Math.ceil((multiplier + 2) / 2) / topmulti) * 700 - 400,
        Math.ceil((multiplier + 2) / 2),
      );
      this.renderPosition(
        (1 - Math.ceil(multiplier * 1.2) / topmulti) * 700 - 400,
        Math.ceil(multiplier * 1.2),
      );
      this.renderPosition(
        (1 - Math.ceil(2 * multiplier) / topmulti) * 700 - 400,
        Math.ceil(2 * multiplier),
      );
      this.renderPosition(
        (1 - Math.ceil(4 * multiplier) / topmulti) * 700 - 400,
        Math.ceil(4 * multiplier),
      );
      // this.renderPosition(-390, Math.floor(topmulti));
    }
  }

  private renderPosition(yPos: number, value: number) {
    if (yPos < -400) {
      return;
    }
    const multiText = new GameObjects.Text(
      this.container.scene,
      this.xPosition,
      yPos,
      value.toString() + "x",
      { font: "20px depixel", color: "#FFFFFF" },
    );
    multiText.setOrigin(0.5, 0.5);
    this.container.add(multiText);
    const lineText = this.container.scene.add.graphics();
    lineText.lineStyle(1, 0xffffff);
    lineText.lineBetween(this.xPosition + 30, yPos, this.xPosition + 60, yPos);
    this.container.add(lineText);
    this.cleanup.push(multiText);
    this.cleanup.push(lineText);
  }
}

export { YaxisComponent };
