import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import UIBUtton from "@game/mini/room/objects/UIButton";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";

export default class FurnitureEditorUI extends Phaser.GameObjects.Container {
  private _buttons: { [key: string]: UIBUtton } = {};
  private _inputCaptured: boolean;
  private _open: boolean;

  public get inputCaptured(): boolean {
    return this._inputCaptured;
  }

  public get open(): boolean {
    return this._open;
  }

  constructor(scene: OrderableIsometricGameScene) {
    super(scene);

    this.add(
      new Phaser.GameObjects.Image(
        scene,
        0,
        0,
        "ui_atlas",
        "editor_tools_background",
      ),
    );

    this.initUIElements();

    this.depth = Number.MAX_VALUE;
    this.scale = 0;
    //scene.add.existing(this);

    this.scene.events.on("preupdate", this.preUpdate, this);
  }

  public destroy() {
    if (!this.scene) return; // Prevents the game from crashing if destroy is called twice, but I don't know why this happens yet

    this.scene.events.off("preupdate", this.preUpdate, this);
    super.destroy();
  }

  public hide() {
    this.scene.tweens.killTweensOf(this);

    this.scene.tweens.add({
      targets: this,
      scale: 0,
      ease: Phaser.Math.Easing.Back.In,
      duration: 350,
    });

    this._open = false;
  }

  public move(x, y) {
    this.scene.tweens.killTweensOf(this);

    this.scene.tweens.add({
      targets: this,
      x: x,
      y: y - 150,
      ease: Phaser.Math.Easing.Quadratic.Out,
      duration: 450,
    });
  }

  public setPickUpActive(active: boolean) {
    const frame = active ? "large_button" : "large_button_disabled";

    this._buttons["pick_up"].setFrames(frame, frame);
    this._buttons["pick_up"].setEnabled(active);
  }

  public show() {
    this.setPickUpActive(
      (this.scene as CustomizableRoomScene).playerRole === "owner",
    );

    this.scene.tweens.killTweensOf(this);

    this.scene.tweens.add({
      targets: this,
      y: this.y - 150,
      ease: Phaser.Math.Easing.Sine.Out,
      duration: 400,
    });

    this.scene.tweens.add({
      targets: this,
      scale: 1,
      ease: Phaser.Math.Easing.Back.Out,
      duration: 500,
    });

    this._open = true;
  }

  private buttonPressed(buttonId: string) {
    this.emit(buttonId);
  }

  private initUIElements() {
    const buttonTypes = ["move", "rotate", "close"];

    for (let i = 0; i < 3; i++) {
      const button = new UIBUtton(
        this.scene,
        "ui_atlas",
        "button_" + buttonTypes[i],
        "button_" + buttonTypes[i],
        0.9,
      ).setPosition(-63 + 63 * i, -30);

      button.setInteractive();
      button.setCallback(() => this.buttonPressed(buttonTypes[i]), this);

      this.add(button);
      this._buttons[buttonTypes[i]] = button;
    }

    const pickUpButton = new UIBUtton(
      this.scene,
      "ui_atlas",
      "large_button",
      "large_button",
      0.9,
    ).setPosition(0, 25);

    pickUpButton.setCallback(() => this.buttonPressed("pick_up"), this);
    pickUpButton.add(
      new Phaser.GameObjects.Text(this.scene, 0, 0, "Pick up", {
        fontFamily: "Depixel",
        color: "#ffffff",
        fontSize: "20px",
      }).setOrigin(0.5, 0.5),
    );

    this.add(pickUpButton);
    this._buttons["pick_up"] = pickUpButton;

    for (let key in this._buttons) {
      const button = this._buttons[key];

      button.on(
        "pointerdown",
        () => {
          this._inputCaptured = true;
        },
        this,
      );
    }
  }

  private preUpdate(time: number, delta: number) {
    this._inputCaptured = false;
  }
}
