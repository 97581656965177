import axios, { AxiosInstance } from "axios";

export interface ShopDisplayItemData {
  address: string;
  id: string;
  location: {
    direction: number;
    id: string;
    offsetX: number;
    offsetY: number;
    position: number;
  };
  metadata: {
    attributes: ShopDisplayItemAttributeData[];
    description: string;
    name: string;
    image: string;
  };
  ticketPrice: number;
  sbthPrice: number;
  sold: number;
}

export interface ShopDisplayItemAttributeData {
  trait_type: string;
  value: string;
}

export default class ShopAPI {
  private _axios: AxiosInstance;

  constructor(public baseURL: string) {
    this._axios = axios.create({ baseURL: baseURL });
  }

  public async getCollection(): Promise<ShopDisplayItemData[]> {
    return new Promise<any>((resolve, reject) => {
      this._axios
        .get("/collection")
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }

  public async getCollectionShowcase(): Promise<ShopDisplayItemData[]> {
    return new Promise<any>((resolve, reject) => {
      this._axios
        .get("/collection/showcase")
        .then((response) => resolve(response.data))
        .catch(reject);
    });
  }
}
