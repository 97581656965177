import { TILE_VALUES } from "@game/mini/memory/common/data/TileValues";
import Vector2 = Phaser.Math.Vector2;
import TileSinkAnimation from "@game/mini/memory/client/animations/TileSinkAnimation";
import Entity from "@game/engine/Entity";
import { Scene } from "phaser";

export default class MemoryTile extends Entity {
  public tileId: number = 0;
  private _element: string = "";
  private _defaultPos: Vector2 = new Vector2();
  private _sinkAnim: TileSinkAnimation;

  private tileImage: Phaser.GameObjects.Image;
  public waterImage: Phaser.GameObjects.Image;

  constructor(scene: Scene) {
    super(scene, 0, 0);

    this.tileImage = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      "memory_atlas",
      "tile_empty",
    ).setOrigin(0.5, 0);
    this.waterImage = new Phaser.GameObjects.Image(
      scene,
      0,
      0,
      "memory_atlas",
      "tile_water",
    )
      .setOrigin(0.5, 0)
      .setAlpha(0);

    this.add(this.tileImage);
    this.add(this.waterImage);

    this._sinkAnim = new TileSinkAnimation(scene, this);
  }

  public setTileId(tileId: number) {
    this.tileId = tileId;
  }

  public setDefaultPosition(x: number, y: number) {
    this._defaultPos.set(x, y);
    this.setPosition(x, y);
  }

  public resetPosition() {
    this.setPosition(this._defaultPos.x, this._defaultPos.y);
  }

  public setElement(element: string) {
    this._element = element;
  }

  public setElementVisible(visible) {
    const frame =
      "tile_" + (visible ? TILE_VALUES[this.tileId].element : "empty");
    this.tileImage.setTexture("memory_atlas", frame);
  }

  public sink(delay?: number): TileSinkAnimation {
    this._sinkAnim.playSink(delay);

    return this._sinkAnim;
  }

  public float(delay?: number): TileSinkAnimation {
    this._sinkAnim.playFloat(delay);

    return this._sinkAnim;
  }

  get element() {
    return this._element;
  }

  get defaultPos(): Phaser.Math.Vector2 {
    return this._defaultPos;
  }
}
