import Vector2 = Phaser.Math.Vector2;
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Vector2Like = Phaser.Types.Math.Vector2Like;

export function objectIsEmpty(object): boolean {
  return Object.keys(object).length === 0;
}

export function Vector2ToTilePosition3D(
  vector: Vector2 | Vector2Like,
  z: number = 0,
): TilePosition3D {
  return { x: vector.x, y: vector.y, z: z };
}

export const EmptyCallback = () => {};
