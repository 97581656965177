export enum EnumApplicationEvents {
  ApplicationStartMinigame = "APPLICATION_START_MINIGAME",
  ApplicationMoveToScene = "APPLICATION_MOVE_TO_SCENE",

  ApplicationUiRetrievedMinigameSettings = "APPLICATION_UI_RETRIEVED_MINIGAME_SETTINGS",
  ApplicationNetworkRequestMinigameSettings = "APPLICATION_NETWORK_REQUEST_MINIGAME_SETTINGS",
  ApplicationNetworkAuthenticated = "APPLICATION_NETWORK_AUTHENTICATED",
}

export enum EnumUiEvents {
  UiDataSendKarma = "UI_DATA_SEND_KARMA",
  UiGameSendKarma = "UI_GAME_SEND_KARMA",
  UiEnableInput = "UI_ENABLE_INPUT",
  UiMounted = "UI_MOUNTED",
  UiChatMessage = "UI_CHAT_MESSAGE",
  UiStartFullscreen = "UI_START_FULLSCREEN",
  UiWalletChanged = "UI_WALLET_CHANGED",

  UiLogin = "UI_LOGIN",
  UiRegister = "UI_REGISTER",
  UiLogout = "UI_LOGOUT",
  UiLoginFailed = "UI_LOGIN_FAILED",
  UiLoginSuccess = "UI_LOGIN_SUCCESS",
  // Communication between UI and minigames
  UiGamePlinkoInitialize = "UI_GAME_PLINKO_INITIALIZE",
  UiGamePlinkoStart = "UI_GAME_PLINKO_START",

  // Mines
  UiGameMinesStart = "UI_GAME_MINES_START",
  UiGameMinesCashout = "UI_GAME_MINES_CASHOUT",
  UiGameMinesReset = "UI_GAME_MINES_RESET",

  // Memory Minigame
  UiGameMemoryGameLost = "UI_GAME_MEMORY_GAME_LOST",
  UIGameMemoryGameWon = "UI_GAME_MEMORY_GAME_WON",

  // UI->Network requests
  UiNetworkRequestInventory = "UI_NETWORK_REQUEST_INVENTORY",
  UiNetworkRequestInventoryUpdate = "UI_NETWORK_REQUEST_INVENTORY_UPDATE",
  UiNetworkRequestResetPassword = "UI_NETWORK_REQUEST_RESET_PASSWORD",
  UINetworkRequestRoomsList = "UI_NETWORK_REQUEST_ROOMS_LIST",
  UiNetworkUpdateCharacter = "UI_NETWORK_UPDATE_CHARACTER",
  UiNetworkMessage = "UI_NETWORK_MESSAGE",
  UiNetworkGetCurrentAccount = "UI_NETWORK_GET_CURRENT_ACCOUNT",

  UiGameSettingsUpdateAudio = "UI_GAME_SETTINGS_UPDATE_AUDIO",

  UiGameNpcInteractionReject = "UI_GAME_NPC_INTERACTION_REJECT",
  UiGameNpcInteractionAccept = "UI_GAME_NPC_INTERACTION_ACCEPT",

  UiOpenModal = "UI_OPEN_MODAL",

  UINetworkLeaderboardRetrieveRecords = "UI_NETWORK_LEADERBOARD_RETRIEVE_RECORDS",
  UINetworkLeaderboardReceivedRecords = "UI_NETWORK_LEADERBOARD_RECEIVED_RECORDS",
  UiNetworkLeaderboardGetTop = "UI_NETWORK_LEADERBOARD_GET_TOP",
  UiNetworkLeaderboardGetTopUpdate = "UI_NETWORK_LEADERBOARD_GET_TOP_UPDATE",

  // Room Editor
  UiPlaceFurniture = "UI_ROOM_EDITOR_PLACE_FURNITURE",
  UiRoomEditorFurnitureSelected = "UI_ROOM_EDITOR_FURNITURE_SELECTED",

  // Store
  UiOpenStoreItemModal = "UI_OPEN_STORE_ITEM_MODAL",
  UiOpenStoreItemModalByID = "UI_OPEN_STORE_ITEM_MODAL_BY_ID",
  UiToggleVoiceChat = "UI_TOGGLE_VOICE_CHAT",

  UiNetworkTradeProposal = "UI_NETWORK_TRADE_PROPOSAL",
  UiNetworkTradeAnswer = "UI_NETWORK_TRADE_ANSWER",
  UiNetworkTradeChange = "UI_NETWORK_TRADE_CHANGE",
  UiNetworkTradeCommit = "UI_NETWORK_TRADE_COMMIT",
  UiNetworkTradeAbandon = "UI_NETWORK_TRADE_ABANDON",

  // Ticket transfer
  UiNetworkUserTicketTransfer = "UI_NETWORK_USER_TICKET_TRANSFER",
  UiUserTicketTransferUpdate = "UI_USER_TICKET_TRANSFER_UPDATE",
}

export enum EnumGameEvents {
  // Plinko
  GamePlinkoGameOver = "GAME_PLINKO_GAME_OVER",

  // Mines
  GameMinesGameOver = "GAME_MINES_GAME_OVER",
  GameMinesAddReward = "GAME_MINES_ADD_REWARD",

  // Bomberman
  GameBombermanPlayerJoined = "GAME_BOMBERMAN_PLAYER_JOINED",
  GameBombermanOpponentJoined = "GAME_BOMBERMAN_OPPONENT_JOINED",
  GameBombermanLifeLost = "GAME_BOMBERMAN_LIFE_LOST",
  GameBombermanPowerup = "GAME_BOMBERMAN_POWERUP",
  GameBombermanGameOver = "GAME_BOMBERMAN_GAME_OVER",
  GameBombermanSecondPassed = "GAME_BOMBERMAN_SECOND_PASSED",
  GameBombermanFriendlyConquer = "GAME_BOMBERMAN_FRIENDLY_CONQUER",
  GameBombermanEnemyConquer = "GAME_BOMBERMAN_ENEMY_CONQUER",
  GameBombermanConquerTile = "GAME_BOMBERMAN_CONQUER_TILE",

  // Memory
  GameMemoryCountdownStarted = "GAME_MEMORY_COUNTDOWN_STARTED",
  GameMemoryGameOver = "GAME_MEMORY_GAME_OVER",
  GameMemoryPlayerJoined = "GAME_MEMORY_PLAYER_JOINED",

  // Room Editor
  RoomEditorRoomEntered = "ROOM_EDITOR_ROOM_ENTERED",
  RoomEditorFurniturePlaced = "ROOM_EDITOR_FURNITURE_PLACED",

  // Minigame General
  GameUiMinigameStart = "GAME_UI_MINIGAME_START",
  GameUiMinigameEnd = "GAME_UI_MINIGAME_END",
  GameUiMinigameUpdate = "GAME_UI_MINIGAME_UPDATE",
  GameUiMinigameLobbyUpdate = "GAME_UI_MINIGAME_LOBBY_UPDATE",
  GameUiMinigamePlayerUpdate = "GAME_UI_MINIGAME_PLAYER_UPDATE",
  GameUiMinigameShowScoreboard = "GAME_UI_MINIGAME_SHOW_SCOREBOARD",
  GameUiMinigameTicketsUpdated = "GAME_UI_MINIGAME_TICKETS_UPDATED",

  GameUiNpcInteraction = "GAME_UI_NPC_INTERACTION",
  GameUiPlayerInteraction = "GAME_UI_PLAYER_INTERACTION",

  GameUiAlreadyJoinedError = "GAME_UI_ALREADY_JOINED_ERROR",

  GameUiTradeOpen = "GAME_UI_TRADE_OPEN",
  GameUiTradeProposal = "GAME_UI_TRADE_PROPOSAL",
  GameUiTradeChangeItem = "GAME_UI_TRADE_CHANGE_ITEM",
  GameUiTradeCommit = "GAME_UI_TRADE_CHANGE_COMMIT",
  GameUiTradeAbandon = "GAME_UI_TRADE_CHANGE_ABANDON",

  GameUiReceivedKarma = "GAME_UI_RECEIVED_KARMA",
  GameUiRoomUpdateData = "GAME_UI_ROOM_UPDATE_DATA",

  UserTransferTickets = "USER_TRANSFER_TICKETS",
  ReceivedUserTicketsDone = "USER_TRANSFER_TICKETS_DONE",
}

export enum EnumNetworkEvents {
  //Generic, callee independent requests
  NetworkRequestUserData = "NETWORK_REQUEST_USER_DATA",
  NetworkRequestUsersData = "NETWORK_REQUEST_USERS_DATA",
  NetworkRequestUsersDataResponse = "NETWORK_REQUEST_USERS_DATA_RESPONSE",
  NetworkRequestUserActiveNfts = "NETWORK_REQUEST_USER_ACTIVE_NFTS",
  NetworkRequestStorageItem = "NETWORK_REQUEST_STORAGE_ITEM",
  NetworkCallRPC = "NETWORK_CALL_RPC",
  NetworkCallRPCResponse = "NETWORK_CALL_RPC_RESPONSE",

  NetworkUiUpdateMessageHistory = "NETWORK_UI_UPDATE_MESSAGE_HISTORY",
  NetworkUiUpdateRoomsList = "NETWORK_UI_UPDATE_ROOMS_LIST",
  NetworkApplicationRequestMinigameSettings = "NETWORK_APPLICATION_REQUEST_MINIGAME_SETTINGS",
  NetworkUpdateUserActiveNfts = "NETWORK_UPDATE_USER_ACTIVE_NFTS",
}
export enum EnumTradeUiEvents {
  UiDataProposal = "UiDataProposal",
  UiDataAnswer = "UiDataAnswer",
  UiDataConfirm = "UiDataConfirm",
  UiDataDecline = "UiDataDecline",
  UiDataAbandon = "UiDataAbandon",
  UiSetItemInTrade = "UiSetItemInTrade",
  UiSetItemOutTrade = "UiSetItemOutTrade",
}
