import RemoteImage from "@game/mini/shop/gameobjects/RemoteImage";
import { IInteractable } from "@game/engine/interfaces/IInteractable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import { makeInteractable } from "@game/engine/utilities/InteractableHelper";
import { ShopDisplayItemData } from "@game/engine/api/ShopAPI";
import { TItemTile } from "@overlay/types";
import { EnumCurrencyType, getTraitValue } from "@uicomps//common/helpers";

export default class ShopDisplayItem
  extends RemoteImage
  implements IInteractable
{
  public interaction: { [action: string]: string };
  public itemTile: TItemTile;

  constructor(
    scene: OrderableIsometricGameScene,
    x,
    y,
    tilePosition: TilePosition3D,
    item: ShopDisplayItemData,
    public identifier: string,
    public description: string,
    imageURL: string,
    debugMode: boolean = false,
  ) {
    super(scene, x, y, tilePosition, identifier, imageURL, debugMode);

    this.itemTile = {
      id: item.id,
      address: item.address,
      currency: EnumCurrencyType.TICKET,
      description: item.metadata.description,
      image: item.metadata.image,
      name: item.metadata.name,
      ticketPrice: item.ticketPrice,
      sbthPrice: item.sbthPrice,
      rarity: getTraitValue(item.metadata.attributes, "rarity"),
      replicas: +getTraitValue(item.metadata.attributes, "replicas"),
      season: getTraitValue(item.metadata.attributes, "drop"),
      sold: item.sold,
    };
  }

  protected onLoadedImage = () => {
    makeInteractable<ShopDisplayItem>(this.image, this);
  };
}
