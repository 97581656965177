import {
  CRYPTO_BOMBER_READY_DURATION,
  CRYPTO_BOMBER_WAITING_FOR_MAXIMUM_PLAYERS_DURATION,
} from "@game/mini/cryptobomber/common/Constants";

import Size = Phaser.Structs.Size;
import Text = Phaser.GameObjects.Text;

export default class CryptoBomberReadyCountdown extends Text {
  private _isCountingDown: boolean;
  private _previousDifference: string;
  private readonly _startTime: number;

  constructor(scene) {
    super(scene, 0, 0, "" + (CRYPTO_BOMBER_READY_DURATION * 0.001).toFixed(0), {
      align: "center",
      color: "#ffffff",
      fontFamily: "Depixel",
      fontSize: "32px",
      padding: { x: 8, y: 8 },
    });

    this._isCountingDown = true;
    this._previousDifference = "";
    this._startTime = Date.now();

    this.setDepth(999).setOrigin(0.5).setScrollFactor(0);
  }

  public centerOnDisplay(gameSize: Size) {
    this.y = gameSize.height * 0.32;
    this.x = gameSize.width * 0.5;
  }

  public updateTimePassed() {
    if (!this || !this._isCountingDown) {
      return;
    }
    const difference = Math.ceil(
      (CRYPTO_BOMBER_READY_DURATION - (Date.now() - this._startTime)) * 0.001,
    ).toFixed(0);

    if (difference !== this._previousDifference) {
      if (difference === "-1") {
        this._isCountingDown = false;
        return;
      }

      this._previousDifference = difference;
      this.setText(difference === "0" ? "GO!" : difference);
      this.setScale(2.56);
      this.scene.tweens.add({
        duration: 512 + 256,
        ease: "Cubic.easeOut",
        props: {
          scaleX: 1,
          scaleY: 1,
        },
        targets: this,
      });
    }
  }
}
