import StampWarsScene from "@game/mini/stamp_wars/StampWarsScene";
import Pawn from "@engine/Pawn";
import IsometricGameScene from "@engine/scenes/IsometricGameScene";
import { formatNftName } from "@shared/Helpers";
import AnimatedIsometricAvatarComponent from "@engine/components/entitycomponents/AnimatedIsometricAvatarComponent";
import Sprite = Phaser.GameObjects.Sprite;
import { Direction } from "@engine/navigation/Direction";
import { PlayerState } from "@engine/Basic2DCharacter";
import { AngleToDirection } from "@game/mini/memory/client/navigation/AngleToDirection";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import Tween = Phaser.Tweens.Tween;

const JUMP_TIME = 500;
const TEAM_TO_COLOR_NAME = ["pink", "purple", "teal", "yellow"];

export default class StampWarsCharacter extends Phaser.GameObjects.Container {
  private _idleTweens: Tween[] = [];

  public get tilePosition(): TilePosition2D {
    return this._tilePosition;
  }
  public get worldPosition(): Vector2Like {
    return this._worldPosition;
  }

  readonly team: number;

  private _avatarComponent: AnimatedIsometricAvatarComponent;
  private _hopper: Sprite;
  private _pawn: Pawn;
  private _tilePosition: TilePosition2D;
  private _worldPosition: Vector2Like;

  constructor(scene: StampWarsScene, characterName: string, team: number) {
    super(scene);

    this.team = team;

    this._tilePosition = { x: 0, y: 0 };
    this._worldPosition = { x: 0, y: 0 };

    this._hopper = scene.add.sprite(
      0,
      -20,
      "stamp_wars_atlas",
      "hopper_" + TEAM_TO_COLOR_NAME[team] + "_0_0",
    );
    this._hopper.anims.create({
      key: "bounce",
      frames: this._hopper.anims.generateFrameNames("stamp_wars_atlas", {
        prefix: "hopper_" + TEAM_TO_COLOR_NAME[team] + "_0_",
        end: 9,
        zeroPad: 0,
      }),
      frameRate: 40,
    });
    this.add(this._hopper);

    this._pawn = new Pawn(scene, 0, -45);
    // Adding an animated avatar that hooksitself into the character and displays the correct animation based on the player's movement
    this._avatarComponent =
      this._pawn.addComponent<AnimatedIsometricAvatarComponent>(
        new AnimatedIsometricAvatarComponent(
          <IsometricGameScene>this.scene,
          formatNftName(characterName),
        ),
      );

    this._pawn.state = PlayerState.Idle;
    this.add(this._pawn);
  }

  public face(direction: Direction) {
    this._avatarComponent.face(direction);
  }

  public jumpTo(target: Vector2Like, collide: boolean = false) {
    if (
      target.x !== this._worldPosition.x ||
      target.y !== this._worldPosition.y
    ) {
      this.face(
        AngleToDirection(Phaser.Math.Angle.BetweenPoints(this, target)),
      );
    }

    if (collide) {
      (this.scene as StampWarsScene).audioComponent.play("players_collision");
    }

    this._hopper.play("bounce");

    if (!collide) {
      this._worldPosition.x = target.x;
      this._worldPosition.y = target.y;
    }

    this.scene.tweens.add({
      targets: this._pawn,
      y: -50,
      duration: 250,
      ease: "Sine.easeOut",
    });

    this.scene.tweens.add({
      targets: this._pawn,
      y: -45,
      duration: 250,
      ease: "Sine.easeIn",
      delay: 250,
    });

    this.scene.tweens.add({
      targets: this,
      x: "+=" + (target.x - this.x) / 2,
      duration: JUMP_TIME / 2,
    });

    this.scene.tweens.add({
      targets: this,
      x: this._worldPosition.x,
      duration: JUMP_TIME / 2,
      delay: JUMP_TIME / 2,
    });

    this.scene.tweens.add({
      targets: this,
      y: Math.min(this.y, target.y) - 20,
      duration: JUMP_TIME / 2,
      ease: "Sine.easeOut",
    });

    this.scene.tweens.add({
      targets: this,
      y: this._worldPosition.y,
      duration: JUMP_TIME / 2,
      ease: "Sine.easeIn",
      delay: JUMP_TIME / 2,
    });
  }

  public setTilePosition(x: number, y: number) {
    this._tilePosition.x = x;
    this._tilePosition.y = y;
  }

  public bounce() {
    if (!this.scene) {
      // Workaround, sometimes when a player leaves this is still called after the object has been destroyed
      return;
    }

    this._idleTweens = [];
    this._idleTweens.push(
      this.scene.tweens.add({
        targets: this._pawn,
        y: -50,
        duration: 250,
        ease: "Sine.easeOut",
      }),
    );

    this._idleTweens.push(
      this.scene.tweens.add({
        targets: this._pawn,
        y: -45,
        duration: 250,
        ease: "Sine.easeIn",
        delay: 250,
      }),
    );

    this._idleTweens.push(
      this.scene.tweens.add({
        targets: this,
        y: this.y - 30,
        duration: JUMP_TIME / 2,
        ease: "Sine.easeOut",
      }),
    );

    this._idleTweens.push(
      this.scene.tweens
        .add({
          targets: this,
          y: this.y,
          duration: JUMP_TIME / 2,
          ease: "Sine.easeIn",
          delay: JUMP_TIME / 2,
        })
        .once("complete", this.bounce, this),
    );
  }

  public stopBouncing() {
    this._idleTweens.forEach((tween) => tween.stop());
    this._idleTweens = [];
  }
}
