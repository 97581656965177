import Pawn from "../Pawn";
import { Scene } from "phaser";

export default class Controller {
  constructor(protected scene: Scene, protected pawn: Pawn) {}

  public start() {}

  public possess(pawn: Pawn) {
    this.pawn = pawn;
  }

  public unpossess() {
    this.pawn = null;
  }
}
