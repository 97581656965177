import Button from "@game/engine/components/ui/Button";
import GameScene from "@game/engine/scenes/GameScene";
import { GameObjects, Time, Tweens } from "phaser";
import { BackgroundMangment } from "./BackgroundManagment";
import { RocketGenerator } from "./RocketGenerator";
import { XaxisComponent } from "./Xaxis";
import { YaxisComponent } from "./YAxis";
import { CrashNetworkedComponent } from "./server/CrashNetworkedComponent";
import LoadingSceneComponent from "@engine/components/scenecomponents/LoadingSceneComponent";
import AudioComponent from "@engine/components/scenecomponents/AudioComponent";
import { currentAccount } from "@shared/Global";
import { dispatchEvent, EnumGameEvents } from "@shared/Events";
import { CrashGraphSpace } from "@game/mini/crash/CrashGraphSpace";
import { isMobile } from "web3modal";
import TrailParticleEmitterManager from "@engine/gameobjects/effects/TrailParticleEmitterManager";
import { ConfettiVFX } from "./ConfettiVFX";
import Vector2 = Phaser.Math.Vector2;

export default class CrashScene extends GameScene {
  public static SceneKey: string = "CrashScene";
  private _audioComponent: AudioComponent;
  private _graphSpace: CrashGraphSpace;
  private _loadingSceneComponent: LoadingSceneComponent;
  private _matchData: { [key: string]: string };
  //Components
  private _networkedComponent: CrashNetworkedComponent;
  private _rocketCurvePath: Phaser.Curves.Path;
  private _rocketGraphics: GameObjects.Graphics;
  private _trailParticleEmitterManager: TrailParticleEmitterManager;
  private background: BackgroundMangment;
  private background_rockets: GameObjects.Rectangle;
  private background_rockets_border: GameObjects.Rectangle;
  private bang: GameObjects.Sprite;
  private cashoutEnabled = false;
  private coin: Phaser.GameObjects.Sprite;
  private confetti: ConfettiVFX;
  private crashword: GameObjects.Sprite;
  private currentMultiplier: number = 1;
  private exhaustEmitter1: GameObjects.Particles.ParticleEmitter;
  private exhaustEmitter2: GameObjects.Particles.ParticleEmitter;
  private gameStarted = false;
  private minStake: number = 0.5;
  private minigamesafearea: GameObjects.Container;
  private notificationEarnings: GameObjects.Text;
  private notificationLarge: GameObjects.Text;
  private notificationMiddle: GameObjects.Text;
  private originalHeight: number = 0;
  private originalWidth: number = 0;
  private particles: Phaser.GameObjects.Particles.ParticleEmitterManager;
  private placedBet: number = 0;
  private rocketGenerator: RocketGenerator;
  private rocketMove: Tweens.Tween;
  private rocketRotation: Tweens.Tween;
  private rocketa: GameObjects.Container;
  private rocketaContainer: GameObjects.Container;
  private stake: number = 0;
  private stakeBackground: Phaser.GameObjects.Sprite;
  private stakeButton: Button;
  private stakeButton1000: Button;
  private stakeButton500: Button;
  private stakeValueText: Phaser.GameObjects.Text;
  private stakebutton100: Button;
  private timeStarted: number = 0;
  private timer: Time.TimerEvent;
  private timerTimestamp: number = 0;
  private trailParticleEmitterManager: TrailParticleEmitterManager;
  private wagerText: Phaser.GameObjects.Text;
  private xAxis: XaxisComponent;
  private yAxis: YaxisComponent;

  constructor() {
    super({
      key: CrashScene.SceneKey,
    });
  }

  public onGameCrash(
    timestamp: number,
    multiplier: number,
    playersCrashed: { [key: string]: number },
  ) {
    this._graphSpace.reset();
    this._rocketCurvePath.destroy();
    this._rocketCurvePath = new Phaser.Curves.Path(0, -10);

    this.gameStarted = false;
    this.cashoutEnabled = false;
    this.timer.paused = true;
    this.currentMultiplier = multiplier;
    this.notificationLarge.setText(
      this.currentMultiplier.toLocaleString() + "x",
    );

    this.bang.setX(this.rocketaContainer.x);
    this.bang.setY(this.rocketaContainer.y);
    this.bang.setAlpha(1);
    this.bang.setVisible(true);
    this.crashword.setX(this.rocketaContainer.x);
    this.crashword.setY(this.rocketaContainer.y);
    this.crashword.setAlpha(1);
    this.crashword.setVisible(true);
    this.bang.play("start");
    this.crashword.play("start");
    this._audioComponent.stop("liftoff");
    this._audioComponent.play("explosion");

    this.rocketa.setAlpha(0);
    this._rocketGraphics.setAlpha(0);

    if (this.stake > 0) this.notificationEarnings.setColor("#FF0000");
    this.stake = 0;
    this.notificationLarge.setColor("#FF0000");
    this.notificationMiddle.setAlpha(1);
    this.notificationMiddle.setColor("#FF0000");
    this.notificationMiddle.setText("Round Over");

    this.exhaustEmitter1.stop();
    this.exhaustEmitter2.stop();
    this.tweens.killAll();

    dispatchEvent(EnumGameEvents.GameCrashMultiplierHistory, [multiplier]);
    this.stakeButton.setText("Place Wager");
    this.stake = 0;
    this.placedBet = 0;
    this.updateStake();
  }

  public onGamePlayerJoined(userId: string, username: string, amount: number) {
    dispatchEvent(EnumGameEvents.GameCrashPlayerHistory, {
      username,
      amount,
      action: " wagered",
      type: this._matchData["currency"],
    });
  }

  public onGamePlayerLeft(
    userId: string,
    username: string,
    amount: number,
    multiplier: number,
  ) {
    dispatchEvent(EnumGameEvents.GameCrashPlayerHistory, {
      username,
      amount,
      action: " cashed-out",
      multiplier,
      type: this._matchData["currency"],
    });
  }

  public onGamePreReady(timestamp: number) {
    this.timerTimestamp = timestamp;
    this._rocketGraphics.setAlpha(0);

    this.bang.setAlpha(0);
    this.crashword.setAlpha(0);
    this._rocketGraphics.setAlpha(0);
    this.placedBet = 0;
    this.currentMultiplier = 1;

    this.stakebutton100.setDisabled(false);
    this.stakebutton100.setAlpha(1);
    this.stakeButton500.setDisabled(false);
    this.stakeButton500.setAlpha(1);
    this.stakeButton1000.setDisabled(false);
    this.stakeButton1000.setAlpha(1);
    this.stakeBackground.setAlpha(1);
    this.wagerText.setAlpha(1);
    this.coin.setAlpha(1);

    this.stakeValueText.setText(this.stake.toLocaleString());
    this.notificationEarnings.setAlpha(0);
    this.notificationLarge.setScale(1, 1);
    this.notificationLarge.setColor("#FFFFFF");
    this.notificationLarge.setText("PREPARING NEXT ROUND");

    this.notificationMiddle.setAlpha(1);
    this.notificationMiddle.setColor("#0fa");
    this.notificationMiddle.setText(
      "Starting in " + this.timeUntilNextRound().toLocaleString() + "s",
    );
    this.timer.paused = false;

    if (this.rocketMove) {
      this.rocketMove.stop();
    }
    if (this.rocketRotation) {
      this.rocketRotation.stop();
    }
  }

  public onGamePrepare(timestamp: number) {
    this.timerTimestamp = timestamp;

    this.notificationLarge.setColor("#FFFFFF");
    this.notificationLarge.setText("ROUND IS ABOUT TO START");

    this.notificationMiddle.setAlpha(1);
    this.notificationMiddle.setColor("#0fa");
    this.notificationMiddle.setText(
      "Starting in " + this.timeUntilNextRound().toLocaleString() + "s",
    );
    this.timer.paused = false;
    if (this.rocketMove) {
      this.rocketMove.stop();
    }
    if (this.rocketRotation) {
      this.rocketRotation.stop();
    }

    this._audioComponent.play("liftoff", false, 0.5);
  }

  public onGameStarted() {
    this.gameStarted = true;
    this.rocketGenerator.generateRocket();
    this.rocketa.setAlpha(1);
    this._rocketGraphics.setAlpha(1);
    this.exhaustEmitter1.start();
    this.exhaustEmitter2.start();

    this.notificationEarnings.setColor("#00FF00");
    this.notificationLarge.setText("ROUND STARTS NOW");
    this.notificationMiddle.setAlpha(0);

    if (this.placedBet > 0) this.stakeButton.setText("Cash out");
    this.stakeButton.setDisabled(true);
    this.stakeButton.setAlpha(0.2);
    this.stakebutton100.setDisabled(true);
    this.stakebutton100.setAlpha(0.2);
    this.stakeButton500.setDisabled(true);
    this.stakeButton500.setAlpha(0.2);
    this.stakeButton1000.setDisabled(true);
    this.stakeButton1000.setAlpha(0.2);
    this.stakeBackground.setAlpha(0.2);
    this.wagerText.setAlpha(0.2);
    this.coin.setAlpha(0.2);

    this.stake = this.placedBet;
    this.rocketaContainer.setX(-600 - this.rocketa.width / 2);
    this.rocketaContainer.setY(300 + this.rocketa.height / 2);
    this.rocketaContainer.setAngle(90);

    this.tweens.killAll();
    this.timeStarted = Date.now();
  }

  public onGameState(minPlayableAmount: number, multiplierHistory: number[]) {
    this.minStake = minPlayableAmount;
    dispatchEvent(EnumGameEvents.GameCrashMultiplierHistory, multiplierHistory);
  }

  public onGameTick(multiplier: number, time: number) {
    if (this.placedBet > 0 && multiplier >= 1.1) {
      this.stakeButton.setDisabled(false);
      this.stakeButton.setAlpha(1);
    }
    this._graphSpace.addHistoryPoint(multiplier, time);

    this.currentMultiplier = multiplier;
    this.notificationLarge.setText(
      this.currentMultiplier.toLocaleString() + "x",
    );
    this.notificationLarge.setScale(
      Math.min(1.75, this.notificationLarge.scaleX + 0.01),
      Math.min(1.75, this.notificationLarge.scaleX + 0.01),
    );
    if (!isMobile()) {
      this.xAxis.startRender(Date.now() - this.timeStarted);
      this.yAxis.startRender(
        this.currentMultiplier,
        this.currentMultiplier * 10,
        this.rocketaContainer.x,
        this.rocketaContainer.y,
      );
    }
    if (this.stake > 0) {
      this.notificationEarnings.setAlpha(1);
      this.notificationEarnings.setText(
        `+${this._matchData["currency"].toUpperCase()} ` +
          (this.stake * this.currentMultiplier).toLocaleString(),
      );
    }

    this.timer.paused = false;
  }

  public onPlayerJoined(userId: string, username: string) {
    console.log(`Player ${username} joined`);
  }

  public onPlayerLeft(userId: string, username: string) {
    console.log(`Player ${username} left`);
  }

  public onResize(gameSize, baseSize, displaySize, resolution) {
    const width = gameSize.width;
    const height = gameSize.height;

    const scaleX = width / this.originalWidth;
    const scaleY = height / this.originalHeight;

    const smallerScale = Math.min(scaleX, scaleY);

    //@ts-ignore
    this.minigamesafearea.setPosition(
      this.scale.gameSize.width / 2,
      this.scale.gameSize.height / 2,
    );
    this.background_rockets
      .setSize(
        Math.min(this.scale.gameSize.width * 0.8, 1200),
        Math.min(this.scale.gameSize.height * 0.8, 800),
      )
      .setOrigin(0.5, 0.5);
    this.background_rockets_border
      .setSize(
        Math.min(this.scale.gameSize.width * 0.8, 1200),
        Math.min(this.scale.gameSize.height * 0.8, 800),
      )
      .setOrigin(0.5, 0.5);

    this._graphSpace.setDimensions(
      this.background_rockets_border.width,
      this.background_rockets.height,
    );
    this._rocketGraphics.setPosition(
      -this._graphSpace.renderWidth / 2,
      this._graphSpace.renderHeight / 2,
    );
  }

  public update(time: number, deltaTime: number) {
    super.update(time, deltaTime);
    this.drawRocketPathLine();
  }

  protected preload() {
    super.preload();
    TrailParticleEmitterManager.Preload(this);
    this.load.image(
      "rocketA",
      "assets/game/mini/crash-game/rockets/rocketA.png",
    );
    this.load.image(
      "background1",
      "assets/game/mini/crash-game/moonmoon_crash_background.png",
    );
    this.load.image(
      "background2",
      "assets/game/mini/crash-game/guitar girl.png",
    );
    this.load.image(
      "rocketB",
      "assets/game/mini/crash-game/rockets/rocket.png",
    );
    // this.load.image("bang", "assets/game/mini/crash-game/bang.png");
    this.load.image(
      "betButtonNormal",
      "assets/game/mini/crash-game/buttons/normal-button.png",
    );
    this.load.image(
      "betButtonPressed",
      "assets/game/mini/crash-game/buttons/pressed-button.png",
    );
    this.load.image(
      "betButtonHover",
      "assets/game/mini/crash-game/buttons/hover-button.png",
    );
    this.load.image(
      "betButtonDisabled",
      "assets/game/mini/crash-game/buttons/disabled-button.png",
    );

    this.load.image(
      "halfButtonNormal",
      "assets/game/mini/crash-game/buttons/normal button.png",
    );
    this.load.image(
      "halfButtonPressed",
      "assets/game/mini/crash-game/buttons/pressed.png",
    );
    this.load.image(
      "halfButtonHover",
      "assets/game/mini/crash-game/buttons/hover.png",
    );
    this.load.image(
      "halfButtonDisabled",
      "assets/game/mini/crash-game/buttons/button-09.png",
    );

    //Background assets
    this.load.image(
      "backgroundCrash",
      "assets/game/mini/crash-game/background/background.png",
    );
    this.load.image(
      "foreground",
      "assets/game/mini/crash-game/background/foreground.png",
    );
    this.load.image(
      "midground",
      "assets/game/mini/crash-game/background/midground.png",
    );
    this.load.image(
      "rockbelt",
      "assets/game/mini/crash-game/background/rockbelt.png",
    );
    this.load.image("p0", "assets/game/mini/crash-game/background/p0.png");
    this.load.image("p1", "assets/game/mini/crash-game/background/p1.png");
    this.load.image("p2", "assets/game/mini/crash-game/background/p2.png");
    this.load.image("p3", "assets/game/mini/crash-game/background/p3.png");
    this.load.image("p4", "assets/game/mini/crash-game/background/p4.png");
    this.load.image("p5", "assets/game/mini/crash-game/background/p5.png");
    this.load.image("p6", "assets/game/mini/crash-game/background/p6.png");

    this.load.image("exhaust", "assets/game/mini/crash-game/square.png");
    switch (this._matchData["currency"]) {
      case "tickets": {
        this.load.image("coin_tickets", "assets/overlay/icons/tickets.png");
        break;
      }
      case "sbth": {
        this.load.image("coin_sbth", "assets/overlay/icons/coin-stack-bth.png");
        break;
      }
    }
    this.load.image(
      "numberBackground",
      "assets/game/mini/crash-game/bg_for_sk.png",
    );
    this.load.audio(
      "buttonclick1",
      "assets/game/mini/crash-game/audio/Button_click1.wav",
    );
    this.load.audio(
      "buttonclick2",
      "assets/game/mini/crash-game/audio/Button_click2.wav",
    );
    this.load.audio(
      "cashout",
      "assets/game/mini/crash-game/audio/Cash_out.wav",
    );
    this.load.audio(
      "crash",
      "assets/game/mini/crash-game/audio/crash_rocket_minigame_v2.wav",
    );
    this.load.audio(
      "explosion",
      "assets/game/mini/crash-game/audio/Rocket_explosion.wav",
    );
    this.load.audio(
      "liftoff",
      "assets/game/mini/crash-game/audio/Rocket_lifting_off.wav",
    );

    // this.load.atlas("rocketbase1", "assets/game/mini/crash-game/rockets/rocket trial 1.png", "assets/game/mini/crash-game/rockets/rocket trial 1.json");

    this.load.atlas(
      "crashword",
      "assets/game/mini/crash-game/crash word.png",
      "assets/game/mini/crash-game/crash word.json",
    );
    this.load.atlas(
      "explosion",
      "assets/game/mini/crash-game/explosion.png",
      "assets/game/mini/crash-game/explosion.json",
    );

    //load bases
    for (let loadIndex = 1; loadIndex <= 5; loadIndex++) {
      this.load.image(
        "base" + loadIndex,
        "assets/game/mini/crash-game/rockets/base/base" + loadIndex + ".png",
      );
      this.load.image(
        "base" + loadIndex + "mini",
        "assets/game/mini/crash-game/rockets/base/base" +
          loadIndex +
          "mini.png",
      );
      this.load.image(
        "base" + loadIndex + "maxi",
        "assets/game/mini/crash-game/rockets/base/base" +
          loadIndex +
          "maxi.png",
      );
    }

    //load bottom windows
    for (let loadIndex = 1; loadIndex <= 8; loadIndex++) {
      this.load.image(
        "bottomwindow" + loadIndex,
        "assets/game/mini/crash-game/rockets/bottomwindow/bottomwindow" +
          loadIndex +
          ".png",
      );
      this.load.image(
        "bottomwindow" + loadIndex + "mini",
        "assets/game/mini/crash-game/rockets/bottomwindow/bottomwindow" +
          loadIndex +
          "mini.png",
      );
      this.load.image(
        "bottomwindow" + loadIndex + "maxi",
        "assets/game/mini/crash-game/rockets/bottomwindow/bottomwindow" +
          loadIndex +
          "maxi.png",
      );
    }

    //load top windows
    for (let loadIndex = 1; loadIndex <= 9; loadIndex++) {
      this.load.image(
        "topwindow" + loadIndex,
        "assets/game/mini/crash-game/rockets/topwindow/topwindow" +
          loadIndex +
          ".png",
      );
      this.load.image(
        "topwindow" + loadIndex + "mini",
        "assets/game/mini/crash-game/rockets/topwindow/topwindow" +
          loadIndex +
          "mini.png",
      );
      this.load.image(
        "topwindow" + loadIndex + "maxi",
        "assets/game/mini/crash-game/rockets/topwindow/topwindow" +
          loadIndex +
          "maxi.png",
      );
    }

    //load thrusters
    for (let loadIndex = 1; loadIndex <= 11; loadIndex++) {
      this.load.image(
        "thruster" + loadIndex,
        "assets/game/mini/crash-game/rockets/thruster/thruster" +
          loadIndex +
          ".png",
      );
      this.load.image(
        "thruster" + loadIndex + "mini",
        "assets/game/mini/crash-game/rockets/thruster/thruster" +
          loadIndex +
          "mini.png",
      );
      this.load.image(
        "thruster" + loadIndex + "maxi",
        "assets/game/mini/crash-game/rockets/thruster/thruster" +
          loadIndex +
          "maxi.png",
      );
    }

    ConfettiVFX.preload(this);
  }

  protected override onCreated(): void {
    const buttonclick1 = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "buttonclick1",
      path: "assets/game/mini/crash-game/audio/Button_click1.wav",
    };
    const buttonclick2 = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "buttonclick2",
      path: "assets/game/mini/crash-game/audio/Button_click2.wav",
    };
    const cashout = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "cashout",
      path: "assets/game/mini/crash-game/audio/Cash_out.wav",
    };
    const explosion = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "explosion",
      path: "assets/game/mini/crash-game/audio/Rocket_explosion.wav",
    };
    const liftoff = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: false,
        delay: 0,
      },
      key: "liftoff",
      path: "assets/game/mini/crash-game/audio/Rocket_lifting_off.wav",
    };
    const bgm = {
      config: {
        mute: false,
        volume: 1,
        rate: 1,
        detune: 0,
        seek: 0,
        loop: true,
        delay: 0,
      },
      key: "crash",
      path: "assets/game/mini/crash-game/audio/crash_rocket_minigame_v2.wav",
    };

    this._audioComponent.addSoundEffects([
      buttonclick1,
      buttonclick2,
      cashout,
      explosion,
      liftoff,
    ]);
    this._audioComponent.addBackgroundMusic(bgm);
    this._audioComponent.playBackgroundAudio(true, 0.15);
    this._loadingSceneComponent // Alright we can remove this now
      .setText("done");
    this._loadingSceneComponent.hide(true);

    setTimeout(() => {
      if (currentAccount.wallet[this._matchData["currency"]] < 100) {
        return $modals.openModal("NoTicketsModal");
      }
    }, 500);
  }

  protected async create() {
    super.create();

    // For pretty trails
    this._trailParticleEmitterManager = new TrailParticleEmitterManager(this);

    this.background = new BackgroundMangment(this);
    this.trailParticleEmitterManager = new TrailParticleEmitterManager(this);

    this.minigamesafearea = this.add.container(
      this.scale.gameSize.width / 2,
      this.scale.gameSize.height / 2,
    );

    this.background_rockets = new GameObjects.Rectangle(
      this,
      0,
      0,
      Math.min(this.scale.gameSize.width * 0.8, 1200),
      Math.min(this.scale.gameSize.height * 0.8, 800),
    ).setOrigin(0.5, 0.5);
    this.background_rockets.setFillStyle(0x15282e);
    this.background_rockets.alpha = 0.9;
    this.minigamesafearea.add(this.background_rockets);
    this.originalWidth = this.minigamesafearea.first.width + 400;
    this.originalHeight = this.minigamesafearea.first.height;
    this.background_rockets_border = new GameObjects.Rectangle(
      this,
      0,
      0,
      Math.min(this.scale.gameSize.width * 0.8, 1200),
      Math.min(this.scale.gameSize.height * 0.8, 800),
    );

    this.confetti = new ConfettiVFX(
      this.minigamesafearea.x,
      this.minigamesafearea.y,
      Math.min(this.scale.gameSize.width * 0.8, 1200),
      Math.min(this.scale.gameSize.height * 0.8, 800),
      this,
    );

    this.background_rockets_border.setStrokeStyle(5, 0xffffff);
    this.minigamesafearea.add(this.background_rockets_border);
    //randomly select background
    let backgroundName;
    if (Math.floor(Math.random() * 2)) {
      backgroundName = "background1";
    } else {
      backgroundName = "background2";
    }
    const background = this.add.sprite(0, 0, backgroundName);
    this.minigamesafearea.add(background);
    this.minigamesafearea.setDepth(1);
    background.setAlpha(0.5);
    background.setScale(1.2);

    //how to solve increase of rocket is logarithmic function.

    this._rocketGraphics = this.add.graphics();
    this.minigamesafearea.add(this._rocketGraphics);
    this._rocketGraphics.setAlpha(0);
    this.rocketaContainer = new GameObjects.Container(this, 0, 0);
    this.rocketaContainer.setDepth(1);
    this.minigamesafearea.add(this.rocketaContainer);
    this.rocketGenerator = new RocketGenerator(this);
    this.rocketGenerator.generateRocket();
    this.rocketa = this.rocketGenerator.getContainer();
    this.rocketa.setAlpha(0);
    if (isMobile()) this.rocketa.scale = 0.5;
    this.rocketaContainer.setAngle(0);

    this._rocketCurvePath = new Phaser.Curves.Path(0, 0);

    this.bang = this.add.sprite(0, 0, "explosion");
    this.bang.setAlpha(0);
    this.minigamesafearea.add(this.bang);
    this.bang.anims.create({
      key: "start",
      frames: this.anims.generateFrameNames("explosion", {
        prefix: "crash explosion 1000",
        suffix: ".png",
        start: 1,
        end: 9,
      }),
      frameRate: 12,
      repeat: 0,
      duration: 1000,
      hideOnComplete: true,
    });
    if (!isMobile()) {
      this.xAxis = new XaxisComponent(
        this.minigamesafearea,
        -this.background_rockets.width / 2 + 10,
        this.background_rockets.height / 2 - 50,
      );
      this.yAxis = new YaxisComponent(
        this.minigamesafearea,
        this.background_rockets.width / 2 - 75,
        this.background_rockets.height / 2 - 30,
      );
    }

    this.crashword = this.add.sprite(0, 0, "crashword");
    this.minigamesafearea.add(this.crashword);
    this.crashword.anims.create({
      key: "start",
      frames: this.anims.generateFrameNames("crashword", {
        prefix: "crash explosion word000",
        suffix: ".png",
        start: 1,
        end: 8,
      }),
      frameRate: 12,
      repeat: 0,
      duration: 1000,
      hideOnComplete: true,
    });
    this.particles = this.add.particles("exhaust");

    this.rocketaContainer.add(this.particles);
    this.rocketaContainer.add(this.rocketa);

    this.exhaustEmitter1 = this.particles.createEmitter({
      x: this.rocketa.x - 5,
      y: this.rocketa.y + 70,
      lifespan: 750,
      angle: { min: 50, max: 130 },
      speed: { min: 300, max: 350 },
      scale: { start: 0.3, end: 0 },
      collideTop: false,
      collideBottom: false,
      blendMode: "ADD",
    });
    this.exhaustEmitter2 = this.particles.createEmitter({
      x: this.rocketa.x + 5,
      y: this.rocketa.y + 70,
      lifespan: { min: 750, max: 1000 },
      angle: { min: 65, max: 115 },
      speed: { min: 300, max: 350 },
      scale: { start: 1, end: 0 },
      rotate: { min: 0, max: 360 },
      collideTop: false,
      collideBottom: false,
      blendMode: "ADD",
    });
    this.exhaustEmitter1.stop();
    this.exhaustEmitter2.stop();

    this.notificationLarge = new GameObjects.Text(
      this,
      0,
      -90,
      "PREPARING NEXT ROUND",
      { font: !isMobile() ? "40px depixel" : "18px depixel" },
    );
    this.notificationLarge.setOrigin(0.5, 0.5);
    this.minigamesafearea.add(this.notificationLarge);
    this.notificationMiddle = new GameObjects.Text(
      this,
      0,
      -50,
      "Starting in " + this.timeUntilNextRound().toLocaleString() + "s",
      { font: !isMobile() ? "20px depixel" : "14px depixel", color: "#0fa" },
    );
    this.notificationMiddle.setOrigin(0.5, 0.5);
    this.notificationMiddle.setAlpha(0);
    this.minigamesafearea.add(this.notificationMiddle);

    this.notificationEarnings = new GameObjects.Text(
      this,
      0,
      -50,
      `+${this._matchData["currency"].toUpperCase()} ` +
        (this.placedBet * this.currentMultiplier).toLocaleString(),
      { font: !isMobile() ? "20px depixel" : "14px depixel", color: "#00FF00" },
    );
    this.minigamesafearea.add(this.notificationEarnings);
    this.notificationEarnings.setOrigin(0.5, 0.5);
    this.notificationEarnings.setAlpha(0);
    this.notificationEarnings.setScale(1.1);

    this.timer = this.time.addEvent({
      delay: 30,
      callback: this.refreshTimer,
      callbackScope: this,
      loop: true,
    });

    this.coin = this.add.sprite(
      this._matchData["currency"] === "tickets"
        ? !isMobile()
          ? 50
          : 40
        : !isMobile()
        ? 50
        : 40,
      !isMobile() ? 215 : 60,
      `coin_${this._matchData["currency"]}`,
    );
    switch (this._matchData["currency"]) {
      case "tickets":
        this.coin.setScale(0.7, 0.7);
        break;
      case "sbth":
        this.coin.setScale(0.7, 0.7);
        break;
    }
    this.coin.setDepth(2);
    this.wagerText = this.add.text(
      !isMobile() ? 90 : 60,
      !isMobile() ? 210 : 60,
      "Wager: ",
      {
        font: !isMobile() ? "15px depixel outlined" : "15px depixel outlined",
      },
    );
    this.wagerText.setDepth(2);

    this.stakeBackground = this.add.sprite(
      !isMobile() ? 145 : 95,
      !isMobile() ? 257 : 100,
      "numberBackground",
    );
    this.stakeBackground.setDepth(2);
    this.stakeBackground.scaleX = !isMobile() ? 0.6 : 0.4;
    if (isMobile()) this.stakeBackground.scaleY = 0.7;
    this.stakeValueText = this.add.text(75, !isMobile() ? 240 : 90, "0", {
      font: !isMobile() ? "30px depixel" : "16px depixel",
      color: "#2D5D6A",
    });
    this.stakeValueText.setDepth(2);

    this.stakeButton = new Button(
      this,
      !isMobile() ? 80 : 60,
      !isMobile() ? 220 : 120,
      "betButtonNormal",
      "Place Wager",
      "betButtonPressed",
      "betButtonHover",
      "betButtonDisabled",
    );
    this.stakeButton.setDepth(2);
    this.stakeButton.setTextStyle({
      font: !isMobile() ? "20px depixel" : "14px depixel",
      color: "#FFFFFF",
    });
    this.stakeButton.addOnClickEvent("stake");
    this.stakeButton.scaleX = !isMobile() ? 0.8 : 0.6;
    this.stakeButton.scaleY = !isMobile() ? 0.8 : 0.6;
    this.stakeButton.setAlpha(0.2);
    this.stakeButton.setDisabled(true);
    this.events.on("stake", this.handlePlaceBet, this);
    this.add.existing(this.stakeButton);

    this.stakebutton100 = new Button(
      this,
      !isMobile() ? 40 : 20,
      !isMobile() ? 165 : 85,
      "halfButtonNormal",
      "100",
      "halfButtonPressed",
      "halfButtonHover",
    );
    this.stakebutton100.setDepth(2);

    if (isMobile()) this.stakebutton100.scale = 0.7;
    this.stakebutton100.addOnClickEvent("stakeButton100Clicked");
    this.stakebutton100.setAlpha(0.2);
    this.stakebutton100.setDisabled(true);
    this.events.on("stakeButton100Clicked", this.handleStakeButton100, this);
    this.add.existing(this.stakebutton100);

    this.stakeButton500 = new Button(
      this,
      !isMobile() ? 70 : 50,
      !isMobile() ? 165 : 85,

      "halfButtonNormal",
      "500",
      "halfButtonPressed",
      "halfButtonHover",
    );
    if (isMobile()) this.stakeButton500.scale = 0.7;
    this.stakeButton500.addOnClickEvent("stakeButton500Clicked");
    this.stakeButton500.setAlpha(0.2);
    this.stakeButton500.setDisabled(true);
    this.stakeButton500.setDepth(2);
    this.events.on("stakeButton500Clicked", this.handleStakeButton500, this);
    this.add.existing(this.stakeButton500);

    this.stakeButton1000 = new Button(
      this,
      !isMobile() ? 100 : 80,
      !isMobile() ? 165 : 85,
      "halfButtonNormal",
      "1000",
      "halfButtonPressed",
      "halfButtonHover",
    );
    if (isMobile()) this.stakeButton1000.scale = 0.7;
    this.stakeButton1000.setAlpha(0.2);
    this.stakeButton1000.setDisabled(true);
    this.stakeButton1000.addOnClickEvent("stakeButton1000Clicked");
    this.stakeButton1000.setDepth(2);
    this.events.on("stakeButton1000Clicked", this.handleStakeButton1000, this);
    this.add.existing(this.stakeButton1000);

    this._networkedComponent = this.addComponent<CrashNetworkedComponent>(
      new CrashNetworkedComponent(this._matchData),
    );
    await this._networkedComponent.connect();
    await this._networkedComponent.findAndJoinMatch();
    this.scale.on("resize", this.onResize, this);

    this._graphSpace = new CrashGraphSpace(
      this.background_rockets_border.width,
      this.background_rockets_border.height,
    );

    this._rocketGraphics.setPosition(
      -this._graphSpace.renderWidth / 2,
      this._graphSpace.renderHeight / 2,
    );
  }

  protected override init(data?: object) {
    // initialize global variables according to game size
    // set game size variables
    this._matchData = (data as any)?.matchData;
    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });
    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );
  }

  protected onShutdown(): void {
    super.onShutdown();
    this.events.removeListener("stake");
    this.events.removeListener("stakeButton100Clicked");
    this.events.removeListener("stakeButton500Clicked");
    this.events.removeListener("stakeButton1000Clicked");
    this.exhaustEmitter1.killAll();
    this.exhaustEmitter2.killAll();
    this.particles.destroy();
    this.background.destroy();
    this.coin.destroy();
    this.crashword.destroy();
    this.bang.destroy();
    this.rocketaContainer.destroy();
    this.stakebutton100.destroy();
    this.stakeButton500.destroy();
    this.stakeButton1000.destroy();
    this.stakeButton.destroy();
    this._rocketGraphics.destroy();
    this._rocketCurvePath.destroy();
    this.rocketGenerator.destroy();
    this.background_rockets.destroy();
    this.background_rockets_border.destroy();
    this.rocketa.destroy(false);
    this.stake = 0;
    this.placedBet = 0;
    this.cashoutEnabled = false;
    this.confetti.onShutdown();
  }

  private drawRocketPathLine() {
    if (!this._graphSpace) return;

    this._rocketGraphics.clear();
    this._rocketGraphics.lineStyle(7, 0x59909f, 1);

    this._rocketCurvePath = new Phaser.Curves.Path();
    this._rocketCurvePath.startPoint = new Vector2().copy(
      this._graphSpace.historyPointToGraphPoint({
        multiplier: 1,
        time: 0,
      }),
    );

    const curvePoints = this._graphSpace.getCurvePoints();

    curvePoints.forEach((point) => {
      this._rocketCurvePath.lineTo(point.x!, point.y!);
    });

    this._rocketCurvePath.draw(this._rocketGraphics);
    if (curvePoints.length > 0) {
      this.rocketaContainer.setPosition(
        curvePoints[curvePoints.length - 1].x + this._rocketGraphics.x,
        curvePoints[curvePoints.length - 1].y + this._rocketGraphics.y,
      );
    }

    if (curvePoints.length > 1) {
      const point2 = curvePoints[curvePoints.length - 1];
      const point1 = curvePoints[curvePoints.length - 2];
      const angle =
        Phaser.Math.Angle.BetweenPoints(point1, point2) *
        Phaser.Math.RAD_TO_DEG;

      this.rocketaContainer.angle = 90 + angle;
    }
  }

  private handlePlaceBet() {
    if (!this.cashoutEnabled) {
      this._networkedComponent.sendGameJoin(this.stake);
      this.cashoutEnabled = true;
      this._audioComponent.play("buttonClick1");
      this.stakeButton.setText("Cancel Wager");
      this.placedBet = this.stake;
    } else {
      this._networkedComponent.sendGameLeft();
      this.cashoutEnabled = false;
      this._audioComponent.play("cashout");
      this.notificationEarnings.setText(
        `+${this._matchData["currency"].toUpperCase()} ` +
          (this.stake * this.currentMultiplier).toLocaleString(),
      );
      this.stake = 0;
      this.placedBet = 0;
      this.stakeButton.setText("Place Wager");
      if (this.gameStarted) {
        this.stakeButton.setDisabled(true);
        this.stakeButton.setAlpha(0.2);
        this.confetti.play();
        setTimeout(() => {
          this.trailParticleEmitterManager.animate(
            this.minigamesafearea.x + this.notificationEarnings.x,
            this.minigamesafearea.y + this.notificationEarnings.y,
            this.scale.width - 20,
            this.scale.height / 5 + 20,
          );
        }, 400);
      }
    }
    this.updateStake();
  }

  private handleStakeButton100() {
    this._audioComponent.play("buttonClick2");
    if (currentAccount.wallet[this._matchData["currency"]] < this.minStake)
      return;

    this.stake = Math.min(
      100,
      currentAccount.wallet[this._matchData["currency"]],
    );
    this.updateStake();
  }

  private handleStakeButton1000() {
    this._audioComponent.play("buttonClick2");
    if (currentAccount.wallet[this._matchData["currency"]] < this.minStake)
      return;

    this.stake = Math.min(
      1000,
      currentAccount.wallet[this._matchData["currency"]],
    );
    this.updateStake();
  }

  private handleStakeButton500() {
    this._audioComponent.play("buttonClick2");
    if (currentAccount.wallet[this._matchData["currency"]] < this.minStake)
      return;

    this.stake = Math.min(
      500,
      currentAccount.wallet[this._matchData["currency"]],
    );
    this.updateStake();
  }

  private refreshTimer() {
    this.notificationMiddle.setText(
      "Starting in " + this.timeUntilNextRound().toLocaleString() + "s",
    );
  }

  private timeUntilNextRound() {
    if (this.timerTimestamp - Date.now() > 0) {
      return (this.timerTimestamp - Date.now()) / 1000;
    } else {
      return 0;
    }
  }

  private updateStake() {
    this.stakeValueText.setText(this.stake.toLocaleString());
    if (this.stake > 0) {
      this.stakeButton.setAlpha(1);
      this.stakeButton.setDisabled(false);
    } else {
      this.stakeButton.setAlpha(0.2);
      this.stakeButton.setDisabled(true);
    }
  }
}
