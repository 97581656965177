import Container = Phaser.GameObjects.Container;
import Image = Phaser.GameObjects.Image;
import LoaderPlugin = Phaser.Loader.LoaderPlugin;
import COMPLETE = Phaser.Loader.Events.COMPLETE;
import OrderablePulsatingLoadingIcon from "@game/mini/shop/gameobjects/OrderablePulsatingLoadingIcon";
import { OrderableType } from "@game/engine/enums/OrderableType";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import { appear } from "@game/engine/utilities/AnimationHelper";

export default class RemoteImage
  extends Container
  implements IIsometricOrderable
{
  public static EventTypes = { Loaded: "imageloaded", Started: "imagestarted" };
  public image: Image;
  public orderableType: OrderableType = OrderableType.Static;

  constructor(
    scene: OrderableIsometricGameScene,
    x,
    y,
    tilePosition: TilePosition3D,
    identifier: string,
    imageURL: string,
    debugMode: boolean = false,
  ) {
    super(scene, x, y);

    // Add a loading icon
    const loadingIcon = new OrderablePulsatingLoadingIcon(scene, 0xff0040, 8);
    loadingIcon.tilePosition = this.tilePosition;
    this.add(loadingIcon);

    const init = () => {
      // Start loading the external image
      const loader = new LoaderPlugin(scene);
      const key = identifier.replaceAll(" ", "_") + "_remote";
      loader.image(key, imageURL);
      loader.once(Phaser.Loader.Events.COMPLETE, () => {
        // No need for the loader anymore
        const tween = loadingIcon.done();
        if (!tween) {
          return;
        }
        tween.on(COMPLETE, () => {
          // Add the image
          this.image = new Image(scene, 0, scene.tileOffset.y, key).setOrigin(
            0.5,
            1,
          );
          this.add(this.image);
          appear(scene.tweens, this.image, true);

          // Let others know, potentially
          this.onLoadedImage();
          this.emit(RemoteImage.EventTypes.Loaded, this);
        });
      });
      if (!loader.isLoading()) {
        loader.start();
      }

      // Let others know
      this.emit(RemoteImage.EventTypes.Started, this);
    };

    // Only time out if we're debugging
    debugMode ? setTimeout(init, Phaser.Math.Between(1024, 4096)) : init();
  }

  private _tilePosition: TilePosition3D;

  public get tilePosition(): TilePosition3D {
    return this._tilePosition;
  }

  public set tilePosition(value) {
    this._tilePosition = value;
  }

  protected onLoadedImage = () => {};
}
