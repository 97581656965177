import { CRYPTO_BOMBER_WAITING_FOR_MAXIMUM_PLAYERS_DURATION } from "@game/mini/cryptobomber/common/Constants";
import Container = Phaser.GameObjects.Container;
import Size = Phaser.Structs.Size;
import Text = Phaser.GameObjects.Text;

export default class CryptoBomberWaitingForPlayersCountdown extends Container {
  private static FontStyle = {
    align: "center",
    color: "#ffffff",
    fontFamily: "Depixel",
    fontSize: "24px",
    padding: { x: 8, y: 8 },
  };
  private _isCountingDown: boolean;
  private readonly _startTime: number;
  private readonly _totalMillisecondsLeft: number;
  private readonly _waitingForPlayersSecondsLeft: Text;
  private readonly _waitingForPlayersText: Text;

  constructor(scene, milliSecondsLeft) {
    super(scene, 0, 0);

    this._totalMillisecondsLeft =
      typeof milliSecondsLeft !== "undefined" &&
      (milliSecondsLeft !== null) & (milliSecondsLeft >= 0)
        ? milliSecondsLeft
        : CRYPTO_BOMBER_WAITING_FOR_MAXIMUM_PLAYERS_DURATION;
    this._isCountingDown = true;
    this._startTime = Date.now();
    this._waitingForPlayersSecondsLeft = new Text(
      scene,
      0,
      0,
      this._totalMillisecondsLeft / 1000 + "s",
      CryptoBomberWaitingForPlayersCountdown.FontStyle,
    )
      .setOrigin(0.5)
      .setScrollFactor(0);

    this._waitingForPlayersText = new Text(
      scene,
      0,
      -64,
      "waiting for more players",
      CryptoBomberWaitingForPlayersCountdown.FontStyle,
    )
      .setOrigin(0.5)
      .setScrollFactor(0);
    this.add(this._waitingForPlayersSecondsLeft);

    this.add(this._waitingForPlayersText);

    this.setDepth(999);
  }

  public centerOnDisplay(gameSize: Size) {
    this.y = gameSize.height * 0.5;
    this.x = gameSize.width * 0.5;
  }

  public updateTimePassed() {
    if (!this._isCountingDown) {
      return;
    }

    // Calculate the time left in seconds
    const difference = Math.ceil(
      (this._totalMillisecondsLeft - (Date.now() - this._startTime)) * 0.001,
    );

    // Check if we're done
    if (difference <= 0 && this._isCountingDown) {
      this._isCountingDown = false;
      this._waitingForPlayersSecondsLeft.destroy();
      return;
    }

    this._waitingForPlayersSecondsLeft.text = difference.toFixed(0) + "s";
  }
}
