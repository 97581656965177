import PublicSpaceGameScene from "@engine/scenes/PublicSpaceGameScene";
import { ISceneSwitchingData } from "@engine/interfaces/ISceneSwitchingData";
import LobbyScene from "../lobby/LobbyScene";
import Rectangle = Phaser.Geom.Rectangle;
import { EnumGameEvents } from "@shared/enums";
import RemotePlayerCharacter from "../lobby/gameobjects/RemotePlayerCharacter";
import { dispatchEvent } from "@shared/Events";

class AudioKeys {
  public static Theme = "arcadetheme";
}

export default class CaspersConferenceScene extends PublicSpaceGameScene {
  public static SceneKey: string = "CaspersConferenceScene";

  constructor() {
    super(
      {
        key: CaspersConferenceScene.SceneKey,
      },
      "assets/game/mini/caspersconferenceroom/",
      "casper_conference_room.tmj",
      [
        "grass_floor_tile.png",
        "Highlight.png",
        "floor_tile.png",
        "furniture-sheets/brokencolumngravestone_1x1x2_1.png",
        "furniture-sheets/columngravestone_1x1x2_1.png",
        "furniture-sheets/crossgravestone_1x1x2_1.png",
        "furniture-sheets/damagedcolumngravestone_1x1x2_1.png",
        "furniture-sheets/fence_2x1x2_1.png",
        "furniture-sheets/hauntedlamp_2x1x3_1.png",
        "furniture-sheets/peakgravestone_1x1x2_1.png",
        "furniture-sheets/roundgravestone_1x1x2_1.png",
        "furniture-sheets/coffee_table/coffeetable_2x2x1_1.png",
        "furniture-sheets/coffee_table/coffeetable_sh.png",
        "furniture-sheets/double_chair/doublechair_2x1x2_1.png",
        "furniture-sheets/double_chair/doublechair_sh_a.png",
        "furniture-sheets/double_chair/doublechair_sh_b.png",
        "furniture-sheets/fancy_long_table/fancylongtable_2x1x2_1.png",
        "furniture-sheets/fancy_long_table/fancylongtable_sh_a.png",
        "furniture-sheets/fancy_long_table/fancylongtable_sh_b.png",
        "furniture-sheets/fancy_short_table/fancyshorttable_1x1x2_1.png",
        "furniture-sheets/fancy_short_table/fancyshorttable_sh.png",
        "furniture-sheets/single_chair/singlechair_1x1x2_1.png",
        "furniture-sheets/single_chair/singlechair_sh.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_5x1x2_1.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_sh_a.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_sh_b.png",
      ], // Tilesets
      [
        "furniture-full images/fence_one.png",
        "furniture-full images/fence_two.png",
        "furniture-full images/haunted_lamp_one.png",
        "furniture-full images/haunted_lamp_two.png",
        "furniture-full images/seats/wooden_throne.png",
        "furniture-full images/tables/reception_table.png",
        "furniture-full images/tables/dining_table.png",
        "brick_wall_1.png",
        "brick_wall_2.png",
        "brick_wall_3.png",
        "ground_dirt.png",
        "haunted_mirror.png",
        "haunted_tree_one.png",
        "haunted_tree_two.png",
        "knight_statue_back.png",
        "knight_statue_front.png",
        "left_wall_one.png",
        "right_side_wall.png",
        "second_floor_railing.png",
        "second_floor_railings_one.png",
        "second_floor_railings_two.png",
        "second_floor_railings_three.png",
        "stair_divider_2.png",
        //"half_wall_b_b.png",
        "stair_railing.png",
        "stone_stair_rail_top.png",
        "background_caspers.png",
        // "background.png",
        "zombie_gravestone/zombie_gravestone_0.png",
      ], // Image layers
      [
        "furniture-sheets/brokencolumngravestone_1x1x2_1.png",
        "furniture-sheets/columngravestone_1x1x2_1.png",
        "furniture-sheets/crossgravestone_1x1x2_1.png",
        "furniture-sheets/damagedcolumngravestone_1x1x2_1.png",
        "furniture-sheets/fence_2x1x2_1.png",
        "furniture-sheets/hauntedlamp_2x1x3_1.png",
        "furniture-sheets/peakgravestone_1x1x2_1.png",
        "furniture-sheets/roundgravestone_1x1x2_1.png",
        "furniture-sheets/coffee_table/coffeetable_2x2x1_1.png",
        "furniture-sheets/coffee_table/coffeetable_sh.png",
        "furniture-sheets/double_chair/doublechair_2x1x2_1.png",
        "furniture-sheets/double_chair/doublechair_sh_a.png",
        "furniture-sheets/double_chair/doublechair_sh_b.png",
        "furniture-sheets/fancy_long_table/fancylongtable_2x1x2_1.png",
        "furniture-sheets/fancy_long_table/fancylongtable_sh_a.png",
        "furniture-sheets/fancy_long_table/fancylongtable_sh_b.png",
        "furniture-sheets/fancy_short_table/fancyshorttable_1x1x2_1.png",
        "furniture-sheets/fancy_short_table/fancyshorttable_sh.png",
        "furniture-sheets/single_chair/singlechair_1x1x2_1.png",
        "furniture-sheets/single_chair/singlechair_sh.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_5x1x2_1.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_sh_a.png",
        "furniture-sheets/wooden_long_chair/woodenlongchair_sh_b.png",
      ], // Spritesheets
      [
        "portal/portal.json",
        "pumpkin/pumpkin.json",
        "zombie_gravestone/zombie_gravestone.json",
      ], //Animation atlas
      true,
    );

    // Adding audio for loading
    // this._audioComponent.addSoundEffects();
  }

  public getBounds(): Rectangle {
    return new Rectangle(-10000, -10000, 20000, 20000);
  }

  public override getNetworkMatchIdentifier(): string {
    return "caspersconference";
  }
  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  protected async create(): Promise<void> {
    await super.create();
  }

  protected init(data?) {
    super.init(data);

    // Check the origin and adjust the spawn point accordingly
    if (data && "origin" in data) {
      const { origin } = <ISceneSwitchingData>data;
      const validOrigins = [LobbyScene.SceneKey];
      if (validOrigins.indexOf(origin) > -1) {
        this.setSpawnLocation(origin);
      }
    }
  }

  protected onCreated() {
    super.onCreated();
    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "assets/game/mini/arcade/audio/theme.mp3",
    });

    // this._audioComponent.pauseOnBlur = true;
    this._audioComponent.playBackgroundAudio(true);
    // this._audioComponent.masterVolume = 1.0;
    // this._audioComponent.soundEffectVolume = 1.0;
  }

  protected override preload() {
    this.load.audio(
      AudioKeys.Theme,
      "/assets/game/mini/arcade/audio/theme.mp3",
    );
    super.preload();
  }
}
