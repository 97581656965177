import Basic2DCharacter from "@game/engine/Basic2DCharacter";
import OrderableTopDownGameScene from "@game/mini/cryptobomber/OrderableTopDownGameScene";
import EntityComponent from "@game/engine/components/EntityComponent";
import { Direction } from "@game/engine/navigation/Direction";

export default class AnimatedTopDownAvatarComponent extends EntityComponent {
  public readonly sprite: Phaser.GameObjects.Sprite;
  private readonly availableAnimationTypes: string[] = ["idle", "walk"];
  private readonly directionToAnimationName: string[] = [
    "top_down_left", // North: mirrorX
    "top_down_down", // East: mirrorX
    "top_down_left", // South
    "top_down_up", // West
  ];

  private _direction: Direction = Direction.South;

  public get direction(): Direction {
    return this._direction;
  }

  constructor(
    private scene: OrderableTopDownGameScene,
    private characterName: string,
    private frameRate: number = 12,
  ) {
    super();

    // Add a sprite to show us on the screen
    this.sprite = new Phaser.GameObjects.Sprite(scene, 0, 0, characterName);
    this.sprite.setFrame(0);
    this.sprite.setOrigin(0.5, 1);
    this.scene.add.existing(this.sprite);

    this.loadCharacter(characterName);
  }

  public face(direction: Direction) {
    this._direction = direction;

    this.sprite.flipX =
      this._direction === Direction.North || this._direction === Direction.East;

    this.playCurrentStateAnimation();
  }

  // Returns true if direction changed
  public faceIfNotAlreadyFacing(direction: Direction): boolean {
    if (!this.isFacing(direction)) {
      this.face(direction);

      return true;
    }

    return false;
  }

  public loadCharacter(characterName: string) {
    this.characterName = characterName;
    if (!this.scene.anims.exists(this.characterName)) {
      // Check if the animations exist
      const json = this.scene.cache.json.get(this.characterName + "_json");

      // Some sanity checking
      if (!json || !("animations" in json)) {
        console.log(
          "[x] Invalid animation JSON provided for character " +
            this.characterName +
            ': missing "animations - Did you preload the JSON and Spritesheet?"',
        );
        return;
      }

      // Retrieve the meta data
      const animationMetaData = json["animations"];

      // The atlas to retrieve the animations from
      const characterAtlasKey = this.characterName + "_atlas";

      // Iterate over both the animation types and all the available directions
      this.availableAnimationTypes.forEach((availableAnimationType) => {
        // Quickly make it a set to filter out duplicates MARKTWAIN?
        new Set(this.directionToAnimationName).forEach((animationName) => {
          // Sanity check
          const prefix = animationName + "_" + availableAnimationType;
          if (!(prefix in animationMetaData)) {
            return;
          }

          // Calculate the number of frames
          const numberOfFrames = animationMetaData[prefix].length - 1;

          this.scene.anims.create({
            frameRate: this.frameRate,
            frames: this.scene.anims.generateFrameNames(characterAtlasKey, {
              end: numberOfFrames,
              prefix: prefix + "_",
              suffix: ".png",
              zeroPad: 3,
            }),
            key:
              this.characterName +
              "_" +
              animationName +
              "_" +
              availableAnimationType,
            repeat: -1,
          });
        });
      });

      // Used for checking if the animations already exist
      this.scene.anims.create({ key: characterName });
    }
    // So there's a default state
    this.sprite.play(this.characterName + "_top_down_left_idle");
  }

  protected onOwnerSet() {
    super.onOwnerSet();

    this.owner.add(this.sprite);

    // If the character does anything, make sure we're up to date
    this.owner.on(Basic2DCharacter.EventTypes.StateChanged, () => {
      this.playCurrentStateAnimation();
    });
  }

  private isFacing(direction: Direction): boolean {
    return this._direction === direction;
  }

  private playCurrentStateAnimation() {
    const animationKey =
      this.characterName +
      "_" +
      this.directionToAnimationName[this._direction] +
      "_" +
      this.owner.state;
    this.sprite.play(animationKey);
  }
}
