import MemoryTile from "@game/mini/memory/client/gameobjects/MemoryTile";
import TileSplashAnimation from "@game/mini/memory/client/animations/TileSplashAnimation";

export default class TileSinkAnimation extends Phaser.Events.EventEmitter {
  private _splashAnim: TileSplashAnimation;

  constructor(protected scene: Phaser.Scene, protected tile: MemoryTile) {
    super();

    // this._splashAnim = new TileSplashAnimation(scene);
    // this._splashAnim.anims.play("tile_splash");
    // tile.add(this._splashAnim);
  }

  playSink(delay: number = 0) {
    this.scene.tweens
      .add({
        targets: this.tile,
        ease: "Sine.Out",
        duration: 1000,
        y: this.tile.y + 300,
        delay: delay,
      })
      .once("start", this.playSplash, this)
      .once("complete", () => this.emit("complete"), this);

    this.scene.tweens.add({
      targets: this.tile.waterImage,
      alpha: 0.7,
      delay: 200 + delay,
      duration: 500,
    });
  }

  playFloat(delay: number = 0) {
    this.scene.tweens
      .add({
        targets: this.tile,
        ease: "Back.Out",
        duration: 1000,
        y: this.tile.defaultPos.y,
        delay: delay,
      })
      .once("complete", () => this.emit("complete"), this);

    this.scene.tweens.add({
      targets: this.tile.waterImage,
      alpha: 0,
      delay: delay,
      duration: 500,
    });
  }

  private playSplash() {
    // this.tile.add(this._splashAnim);
    // this._splashAnim.visible = true;
    // this._splashAnim.anims.play("tile_splash").once(
    //   "complete",
    //   () => {
    //     console.log("DONE");
    //     this._splashAnim.destroy();
    //   },
    //   this._splashAnim,
    // );
  }
}
