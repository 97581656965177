import OrderableTopDownGameScene from "@game/mini/cryptobomber/OrderableTopDownGameScene";
import OrderableTopDownCharacter from "@game/engine/gameobjects/OrderableTopDownCharacter";
import { scaleAndFadeOut } from "@game/engine/utilities/AnimationHelper";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";
import { TilePositionMath } from "@game/engine/utilities/TileHelper";
import { Vector2ToTilePosition3D } from "@game/engine/utilities/Utilities";
import {
  DirectionDeltas,
  DirectionsCardinal,
} from "@game/engine/navigation/Direction";
import { TilePosition3DZero } from "@game/engine/utilities/TilePositionHelper";
import Tween = Phaser.Tweens.Tween;
import Vector2 = Phaser.Math.Vector2;

export default class CryptoBomberCharacter extends OrderableTopDownCharacter {
  public static EventTypes = {
    Death: "playerdeath",
    StateChanged: "playerstatechanged",
    MovedToTile: "playermovedtotile",
  };
  private _health: number;
  private readonly _identifier: string;
  private _movementTween: Tween;
  private _previousPosition: Vector2 = Vector2.ZERO;
  private readonly _tweenDamage: Tween;

  private _isAlive: boolean;

  public get isAlive(): boolean {
    return this._isAlive;
  }

  public get color(): number {
    return this._color;
  }

  public get identifier(): string {
    return this._identifier;
  }

  public get isInvincible(): boolean {
    return this._tweenDamage.isPlaying();
  }

  constructor(
    scene: OrderableTopDownGameScene,
    identifier: string,
    characterName: string,
    private _color: number,
    tilePosition: TilePosition2D,
  ) {
    const worldPosition = scene.worldPositionAtTilePosition(tilePosition);
    super(scene, worldPosition.x, worldPosition.y, characterName);

    this._health = 3;
    this._identifier = identifier;
    this._isAlive = true;

    this._tweenDamage = this.scene.tweens.add({
      targets: this,
      props: {
        alpha: 0.32,
      },
      ease: "Cubic.easeIn",
      duration: 256,
      repeat: 3,
      yoyo: true,
      paused: true,
    });

    // const graphics = new Phaser.GameObjects.Graphics(scene);
    // graphics.fillStyle(0xff9900);
    // graphics.fillCircle(0, 0, 16);
    // this.add(graphics);

    this.registerForTick();

    this._previousPosition.set(this.x, this.y);
  }

  public damage() {
    // Check if we're invincible
    if (!this._isAlive || this.isInvincible) {
      return;
    }
    if (--this._health > 0) {
      // this._tweenDamage.restart();
      this._tweenDamage.play();
    } else {
      this.die();
    }
  }

  public moveToAndMakeFace(x: number, y: number) {
    if (this._movementTween) {
      this._movementTween.stop();
    }

    this._movementTween = this.scene.tweens.add({
      duration: 1000 / 10,
      ease: "Linear",
      props: {
        x: x,
        y: y,
      },
      targets: this,
    });

    const position = new Vector2(x, y);
    const difference = this._previousPosition.subtract(position);
    this._previousPosition = position;

    const signed = TilePositionMath.sign(Vector2ToTilePosition3D(difference));
    const corrected = { x: -signed.y, y: signed.x, z: 0 };

    if (TilePositionMath.equals(corrected, TilePosition3DZero)) {
      return;
    }

    // Only use the obtuse directions
    for (let index = 0; index < DirectionsCardinal.length; index++) {
      const direction = DirectionsCardinal[index];
      const delta = DirectionDeltas[direction];

      // Seems like we're moving in that direction
      if (TilePositionMath.equals(delta, corrected)) {
        this.face(direction);
        return;
      }
    }
  }

  protected onDeath() {}

  private die() {
    this._isAlive = false;

    // And we're out
    scaleAndFadeOut(this.scene.tweens, this);

    // In case we want to do some more cleaning up
    this.onDeath();

    // Let others know
    this.emit(CryptoBomberCharacter.EventTypes.Death, this);
  }
}
