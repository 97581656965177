import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import MemoryBoard from "@game/mini/memory/client/gameobjects/MemoryBoard";
import BoardFallAnimation from "@game/mini/memory/client/animations/BoardFallAnim";
import BoardResetAnimation from "@game/mini/memory/client/animations/BoardResetAnim";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import IsometricUtils from "@game/mini/memory/utils/IsometricUtils";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import { IMemoryPlayerData } from "@common/modules/memory/interfaces/IMemoryPlayerData";
import { EnumGameEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";
import { MEMORY_GAMEOVER_NUM_ROUNDS } from "@common/modules/memory/constants/MemoryConstants";
import Vector2 = Phaser.Math.Vector2;

enum RoundState {
  Intro,
  Preview,
  Choice,
  Resolve,
}

export default class MemoryGameClient {
  public get board(): MemoryBoard {
    return this._board;
  }

  private _alive: boolean = true;
  private _board: MemoryBoard;
  private _chosenTileId: number;
  private _scene: MemoryGameScene;
  private _roundState: RoundState;

  constructor(scene: MemoryGameScene) {
    this._scene = scene;
    this._board = new MemoryBoard(scene, 4, 4, scene.map);

    this._scene.add.existing(this._board);
  }

  public onInputDirectionChanged(direction: Vector2) {
    if (this._roundState === RoundState.Resolve || !this._alive) return;

    this._scene.onInputDirectionChanged(direction);
  }

  public onTileClicked(
    tile: Phaser.Tilemaps.Tile,
    tilePosition: TilePosition3D,
  ) {
    if (this._roundState === RoundState.Resolve || !this._alive) return;

    const isoPosition = IsometricUtils.orthoToIso(
      tilePosition.x + 0.5,
      tilePosition.y + 0.5,
    );

    this._scene.networkedComponent.sendPlayerMove({
      x: tilePosition.x + 0.5,
      y: tilePosition.y + 0.5,
    });

    this._scene
      .getPlayerControllers()
      .player.moveToIsoCoords(isoPosition.x, isoPosition.y);
  }

  public onRoundIntro(
    board: number[][],
    roundNumber: number,
    numPlayers: number,
  ) {
    this._roundState = RoundState.Intro;

    for (let iCol = 0; iCol < board.length; iCol++) {
      for (let iRow = 0; iRow < board[0].length; iRow++) {
        this._board.getTileAt(iCol, iRow).setTileId(board[iCol][iRow]);
      }
    }

    this._board.hideAllTiles();
    this._scene.statusUI.startIntro(roundNumber, numPlayers);
  }

  public onPreviewUpdate(revealedPositions: Vector2Like[]) {
    const board = this._board;
    this._board.hideAllTiles();

    revealedPositions.forEach((position) => {
      board.getTileAt(position.x, position.y).setElementVisible(true);
    });
  }

  public onPreviewStart(timeUntilChoice: number) {
    this._roundState = RoundState.Preview;
    this._scene.statusUI.startPreview(timeUntilChoice);
  }

  public onChoiceStart(chosenTileId) {
    this._roundState = RoundState.Choice;

    this._board.hideAllTiles();
    this._scene.statusUI.startChoice(chosenTileId);

    this._chosenTileId = chosenTileId;
  }

  public onResolveStart(players: IMemoryPlayerData[], roundNumber: number) {
    const currentPlayerData = players.find(
      (playerData) => playerData.id === this._scene.playerId,
    );

    this._roundState = RoundState.Resolve;

    this._board.hideAllTiles();
    this._board.showTilesWithId(this._chosenTileId);

    this.movePlayersToResolvePositions(players);
    this.processRoundResult(this._chosenTileId, players, roundNumber);

    if (
      !currentPlayerData.alive &&
      currentPlayerData.round === roundNumber &&
      roundNumber !== MEMORY_GAMEOVER_NUM_ROUNDS - 1
    ) {
      this._scene.time.delayedCall(2000, () =>
        dispatchEvent(EnumGameEvents.GameUiMinigameEnd),
      );
    }
  }

  private processRoundResult(
    chosenTileId: number,
    playersData: IMemoryPlayerData[],
    roundNumber: number,
  ) {
    this.playBoardAnimations(chosenTileId);
    this.sinkPlayersOverWrongTiles(playersData, roundNumber);
  }

  private movePlayersToResolvePositions(players) {
    const controllers = this._scene.getPlayerControllers();

    players.forEach((playerData) => {
      const controller =
        playerData.id === this._scene.playerId
          ? controllers.player
          : controllers.opponents.get(playerData.id);

      const isoCoords = IsometricUtils.orthoToIso(
        playerData.position.x,
        playerData.position.y,
      );

      controller.moveToIsoCoords(isoCoords.x, isoCoords.y);
    });
  }

  private playBoardAnimations(chosenTileId) {
    const anim = new BoardFallAnimation(this._scene, this._board);
    anim.play(chosenTileId);

    anim.once(
      "complete",
      () => {
        new BoardResetAnimation(this._scene, this._board).play();
      },
      this,
    );
  }

  private sinkPlayersOverWrongTiles(
    playersData: IMemoryPlayerData[],
    roundNumber: number,
  ) {
    const controllers = this._scene.getPlayerControllers();

    playersData.forEach((playerData) => {
      if (!playerData.alive && playerData.round === roundNumber) {
        if (playerData.id === this._scene.playerId) {
          controllers.player.sink();
          this._alive = false;
        } else {
          const controller = controllers.opponents.get(playerData.id);
          controller.sink();
        }
      }
    });
  }
}
