import RemoteImage from "@game/mini/shop/gameobjects/RemoteImage";
import ShopDisplayItem from "@game/mini/shop/gameobjects/ShopDisplayItem";
import SceneComponent from "@game/engine/components/SceneComponent";
import { InteractableEventTypes } from "@game/engine/eventtypes/InteractableEventTypes";
import { ShopDisplayEventTypes } from "@game/engine/eventtypes/ShopDisplayEventTypes";
import { IShopDisplaysDelegate } from "@game/engine/interfaces/IShopDisplaysDelegate";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import OrderableIsometricGameScene from "@game/engine/scenes/OrderableIsometricGameScene";
import { ShopDisplayItemData } from "@game/engine/api/ShopAPI";
import EventEmitter = Phaser.Events.EventEmitter;

export default class ShopDisplaysComponent extends SceneComponent {
  public events: EventEmitter;
  protected declare _scene: OrderableIsometricGameScene;
  private _locationToDisplay: { [location: number]: TilePosition3D };

  private _delegate: IShopDisplaysDelegate;

  public set delegate(value: IShopDisplaysDelegate) {
    this._delegate = value;
  }

  public get numberOfDisplays(): number {
    return Object.keys(this._locationToDisplay).length;
  }

  constructor() {
    super();

    this._locationToDisplay = {};
    this.events = new EventEmitter();
  }

  public display(items: ShopDisplayItemData[]) {
    items.forEach((item, index) => {
      // Get the position for the display, hopefully there's no overlap
      const tilePosition =
        "location" in item && "position" in item.location
          ? this._locationToDisplay[item.location.position]
          : this._locationToDisplay[index];

      // Calculate the position in the world
      const { x, y } = this._scene.worldPositionAtTilePosition(
        tilePosition,
        false,
      );

      // Offset it if possible
      const offsetX =
        "location" in item && "offsetX" in item.location
          ? item.location.offsetX
          : 0;
      const offsetY =
        "location" in item && "offsetY" in item.location
          ? item.location.offsetY
          : 0;
      const remoteImage = new ShopDisplayItem(
        this._scene,
        x + offsetX,
        y + offsetY,
        tilePosition,
        item,
        item.id,
        item.metadata.description,
        item.metadata.image,
        false,
      );
      remoteImage.tilePosition = tilePosition;

      // Keep an eye on when it's done with loading
      remoteImage.on(
        RemoteImage.EventTypes.Loaded,
        (remoteImage: ShopDisplayItem) => {
          this.registerInteractable(remoteImage);
        },
      );

      this._scene.add.existing(remoteImage);
      this._scene.addOrderable(remoteImage);
    });
  }

  public registerInteractable(shopDisplayItem: ShopDisplayItem) {
    // Attach ourselves to interactable NPCs
    shopDisplayItem.on(InteractableEventTypes.OnHover, (shopDisplayItem) => {
      this._delegate?.onShopDisplayHover(shopDisplayItem);
      this.events.emit(ShopDisplayEventTypes.OnHover, shopDisplayItem);
    });
    shopDisplayItem.on(InteractableEventTypes.OnInteract, (shopDisplayItem) => {
      this._delegate?.onShopDisplayInteract(shopDisplayItem);
      this.events.emit(ShopDisplayEventTypes.OnInteract, shopDisplayItem);
    });
    shopDisplayItem.on(InteractableEventTypes.OnOut, (shopDisplayItem) => {
      this._delegate?.onShopDisplayOut(shopDisplayItem);
      this.events.emit(ShopDisplayEventTypes.OnOut, shopDisplayItem);
    });
  }

  protected onSceneSet(scene?: OrderableIsometricGameScene) {
    super.onSceneSet(scene);

    const displayPropertiesContainers =
      scene.getPropertiesContainersOfType("display");

    this._locationToDisplay = {};
    displayPropertiesContainers.forEach((displayPropertiesContainer) => {
      const location = displayPropertiesContainer.getProperty("Location");
      this._locationToDisplay[location] =
        displayPropertiesContainer.tilePosition;
    });
  }
}
