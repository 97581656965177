import { NetworkedComponent } from "@engine/components/scenecomponents/v2/NetworkedComponent";
import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import { MatchData } from "@heroiclabs/nakama-js";
import { MemoryOpCodes } from "@common/modules/memory/enums/MemoryOpCodes";
import { IMemoryPlayerData } from "@common/modules/memory/interfaces/IMemoryPlayerData";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import { MemoryGamePhase } from "@common/modules/memory/enums/MemoryGamePhase";
import { dispatchEvent } from "@shared/Events";
import { EnumNetworkEvents } from "@overlay/enums/Events";

interface IOnBoardPreviewData {
  revealedPositions: Vector2Like[];
}

interface IOnInitializedWorldData {
  countdown?: number;
  players: IMemoryPlayerData[];
  userId: string;
}

interface IOnPlayerMovedData {
  destination: Vector2Like;
  userId: string;
}

interface IOnRoundChoiceData {
  chosenTileId: number;
}

interface IOnRoundIntroData {
  board: number[][];
  numPlayers: number;
  roundNumber: number;
}

interface IOnRoundPreviewData {
  timeUntilChoice: number;
}

interface IOnRoundResolveData {
  players: IMemoryPlayerData[];
  roundNumber: number;
}

interface IOnGameCompleteData {
  players: IMemoryPlayerData[];
}

interface IOnPlayerLeaveData {
  userId: string;
}

export class MemoryNetworkedComponent extends NetworkedComponent {
  protected declare _scene: MemoryGameScene;

  constructor(matchData: { [key: string]: string }) {
    super("memory", matchData);
  }

  public override async findAndJoinMatch(): Promise<boolean> {
    return super.findAndJoinMatch(this._module, this._matchData);
  }

  public sendPlayerMove(destination: Vector2Like) {
    this.sendMatchState(MemoryOpCodes.OnPlayerMove, destination);
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case MemoryOpCodes.OnGameMovedToPhase: {
        const phase = <MemoryGamePhase>(
          parseInt(this.decodeMatchData(matchData.data))
        );
        switch (phase) {
          case MemoryGamePhase.WaitingForMaximumNumberOfPlayers:
            this._scene.startWaitingForPlayers();
            break;

          case MemoryGamePhase.Ready:
            dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
            break;

          // case CryptoBomberGamePhase.Playing:
          //   this._scene.gameStart();
          //   break;
        }
        break;
      }
      case MemoryOpCodes.OnBoardPreview:
        const { revealedPositions } =
          this.decodeAndParseMatchData<IOnBoardPreviewData>(matchData.data);

        this._scene.onPreviewUpdate(revealedPositions);
        break;
      case MemoryOpCodes.OnInitializeWorld:
        const { players, userId, countdown } =
          this.decodeAndParseMatchData<IOnInitializedWorldData>(matchData.data);

        this._scene.initializeWorld(players, userId, countdown);
        break;
      case MemoryOpCodes.OnPlayerJoined:
        const playerData = this.decodeAndParseMatchData<IMemoryPlayerData>(
          matchData.data,
        );
        this._scene.onPlayerJoined(playerData);
        break;
      case MemoryOpCodes.OnPlayerLeave:
        const onLeaveData = this.decodeAndParseMatchData<IOnPlayerLeaveData>(
          matchData.data,
        );
        this._scene.onPlayerLeft(onLeaveData.userId);
        break;
      case MemoryOpCodes.OnPlayerMove:
        const moveData = this.decodeAndParseMatchData<IOnPlayerMovedData>(
          matchData.data,
        );
        this._scene.onPlayerMoved(moveData.userId, moveData.destination);
        break;
      case MemoryOpCodes.OnRoundChoice:
        const { chosenTileId } =
          this.decodeAndParseMatchData<IOnRoundChoiceData>(matchData.data);
        this._scene.onRoundChoice(chosenTileId);
        break;
      case MemoryOpCodes.OnRoundIntro:
        const { board, roundNumber, numPlayers } =
          this.decodeAndParseMatchData<IOnRoundIntroData>(matchData.data);
        this._scene.onRoundIntro(board, roundNumber, numPlayers);
        break;
      case MemoryOpCodes.OnRoundPreview:
        const { timeUntilChoice } =
          this.decodeAndParseMatchData<IOnRoundPreviewData>(matchData.data);
        this._scene.onRoundPreview(timeUntilChoice);
        break;
      case MemoryOpCodes.OnRoundResolve:
        const resolveData = this.decodeAndParseMatchData<IOnRoundResolveData>(
          matchData.data,
        );
        console.log("ON ROUND RESOLVE");
        this._scene.onRoundResolve(
          resolveData.players,
          resolveData.roundNumber,
        );
        break;
      case MemoryOpCodes.OnGameComplete:
        const completeData = this.decodeAndParseMatchData<IOnGameCompleteData>(
          matchData.data,
        );
        console.log("ON GAME COMPLETE");
        this._scene.onGameComplete(completeData.players);
        break;
    }
  }
}
