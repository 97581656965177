import GameScene from "@engine/scenes/GameScene";
import { GameObjects } from "phaser";

export class ConfettiVFX {
  private declare _scene: GameScene;
  private _isPlaying: boolean = false;
  private _frame: GameObjects.Sprite | undefined;
  private _i: number = -1;
  private _timeout: NodeJS.Timeout | undefined;
  private readonly FRAME_TICK = 15; //ms
  constructor(
    private _x: number,
    private _y: number,
    private _height: number,
    private _width: number,
    scene: GameScene,
  ) {
    this._scene = scene;
  }
  private clear = () => {
    clearTimeout(this._timeout);
    this._timeout = undefined;
    this._frame?.destroy();
    this._frame = undefined;
    this._i = -1;
  };

  private frameTime = () =>
    setTimeout(() => {
      if (this._i >= 180) {
        this.clear();
        return;
      }
      if (this._frame) this._frame.destroy();
      this._i++;
      this._frame = this._scene.add.sprite(
        this._x,
        this._y,
        `confetti_${this._i}`,
      );
      this._timeout = this.frameTime();
    }, this.FRAME_TICK);

  public static preload(scene: GameScene) {
    for (let i = 0; i < 180; i++) {
      scene.load.image(
        `confetti_${i}`,
        `assets/game/mini/crash-game/confetti/confetti_${i}.png`,
      );
    }
  }

  public play() {
    if (!this._isPlaying) {
      this._timeout = this.frameTime();
    } else {
      this.clear();
      this._timeout = this.frameTime();
    }
  }

  public onShutdown() {
    this.clear();
  }
}
