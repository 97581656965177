import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import MemoryGameCharacter from "@game/mini/memory/client/gameobjects/MemoryGameCharacter";
import IsometricUtils from "@game/mini/memory/utils/IsometricUtils";
import PlayerController from "@game/engine/controllers/PlayerController";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { TilePosition2D } from "@common/interfaces/TilePosition2D";

export default class CurrentPlayerController extends PlayerController {
  public get alive(): boolean {
    return this._alive;
  }

  public get position(): TilePosition2D {
    return {
      x: this._pawn.x,
      y: this._pawn.y,
    };
  }

  private _alive: boolean = true;

  private _pawn: MemoryGameCharacter;

  constructor(scene: IsometricGameScene, pawn: MemoryGameCharacter) {
    super(scene, pawn);

    this._pawn = pawn;
  }

  public start() {
    super.start();
  }

  public onTilePressed(tilePosition: TilePosition3D) {
    const isoPosition = IsometricUtils.orthoToIso(
      tilePosition.x + 0.5,
      tilePosition.y + 0.5,
    );
    this._pawn.walkTo(isoPosition.x, isoPosition.y);
  }

  public moveToIsoCoords(x: number, y: number) {
    this._pawn.walkTo(x, y);
  }

  public stop() {
    this._pawn.stop();
  }

  public sink() {
    if (!this._alive) return;

    this._alive = false;
    this._pawn.sink();
  }
}
