import LobbyScene from "@game/mini/lobby/LobbyScene";
import ShopDisplayItem from "@game/mini/shop/gameobjects/ShopDisplayItem";
import ShopAPI from "@game/engine/api/ShopAPI";
import RemoteImage from "@game/mini/shop/gameobjects/RemoteImage";
import ShopDisplaysComponent from "@game/engine/components/scenecomponents/ShopDisplaysComponent";
import OrderableNonPlayerCharacter from "@game/engine/gameobjects/OrderableNonPlayerCharacter";
import { ISceneSwitchingData } from "@game/engine/interfaces/ISceneSwitchingData";
import { IShopDisplaysDelegate } from "@game/engine/interfaces/IShopDisplaysDelegate";
import OrderableImage from "@game/engine/objects/OrderableImage";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { stretch, twitch } from "@game/engine/utilities/AnimationHelper";
import { EnumGameEvents, EnumUiEvents } from "@shared/enums";
import { dispatchEvent } from "@shared/Events";
import RemotePlayerCharacter from "../lobby/gameobjects/RemotePlayerCharacter";
import Rectangle = Phaser.Geom.Rectangle;

class AudioKeys {
  public static Theme = "shoptheme";
}

export default class ShopScene
  extends PublicSpaceGameScene
  implements IShopDisplaysDelegate
{
  public static SceneKey: string = "ShopScene";
  private _shopAPI: ShopAPI;
  private _shopDisplaysComponent: ShopDisplaysComponent;

  constructor() {
    super(
      {
        key: ShopScene.SceneKey,
        // debugMode: true,
        offlineMode: false,
      },
      "/assets/game/mini/shop/",
      "shop.tmj",
      [
        "bitshop_tilemap.png",
        "new_tileset.png",
        "furniture/purple_carpet/purple_carpet_1.png",
        "furniture/purple_carpet/purple_carpet_2.png",
        "tilesheets/Highlight.png",
      ], // Tilesets
      [
        "FullImages/bit_light.png",
        "FullImages/glassWall.png",
        "FullImages/glassWall_top.png",
        "FullImages/glassWall_bottom.png",
        "FullImages/glassWall_bottomLeft.png",
        "FullImages/bit_shop_palm/bit_shop_palm_a_top.png",
        "FullImages/bit_shop_palm/bit_shop_palm_a.png",
        "furniture/posed_full_body_mannequin/posed_full_body_mannequin_3_0_1_0/000.png",
        "walls/left_wall_d_crop.png",
        "furniture/head_mannequin/head_mannequin_0_0_1_0/000.png",
        "furniture/head_mannequin/head_mannequin_3_0_1_0/000.png",
        "FullImages/b_desk/bit_shop_front.png",
        "FullImages/b_desk/bit_shop_mid.png",
        "FullImages/b_desk/b_desk_a.png",
        "FullImages/b_desk/b_desk_d.png",
        "FullImages/b_desk/b_desk_b.png",
        "FullImages/bit_shop_palm/bit_shop_palm_b_top.png",
        "FullImages/bit_shop_palm/bit_shop_palm_b.png",
        "FullImages/bit_shop_palm/bit_shop_palm_b_mid.png",
        "furniture/chic_plant/chic_plant_0_0_1_0/000.png",
        "FullImages/bit_light.png",
        "furniture/full_body_mannequin/full_body_mannequin_3_0_1_0/000.png",
        "FullImages/Walls/left_purple_wall_a.png",
        "FullImages/bit_shop_curtain/bit_shop_curtain_a.png",
        "FullImages/Walls/right_purple_wall.png",
        "FullImages/Walls/left_purple_wall_b.png",
        "FullImages/display_case/display_case_fronts.png",
        "FullImages/hologram_case/hologram_case_d.png",
        "FullImages/display_case/display_case_backs.png",
        "FullImages/hologram_case/hologram_case_c.png",
        "FullImages/hologram_case/hologram_case_b.png",
        "FullImages/hologram_case/hologram_case_a.png",
        "FullImages/showcase_display/showcase_display_b.png",
        "FullImages/showcase_display/showcase_display_c.png",
        "FullImages/showcase_display/showcase_display_d.png",
        "FullImages/showcase_display/showcase_display_a.png",
        "FullImages/showcase_display/showcase_display_shadow.png",
        "FullImages/wall_display/wall_display_c.png",
        "FullImages/wall_display/wall_display_b.png",
        "FullImages/wall_display/wall_display_a.png",
        "FullImages/wall_display/wall_display_shadow.png",
        "FullImages/four_arm_showcase/four_arm_showcase.png",
        "FullImages/four_arm_showcase/four_arm_showcase_shadow.png",
        "FullImages/shop_wall_backdrop/shop_wall_backdrop.png",
        "FullImages/shop_wall_backdrop/shop_wall_backdrop.png",
        "FullImages/Walls/right_wall.png",
        "FullImages/white_carpet/white_carpet.png",
        "FullImages/Walls/left_window_wall.png",
        "FullImages/Walls/left_wooden_wall.png",
        "FullImages/ShopDisplayTable_Right.png",
        "FullImages/DisplayTableManequin_right.png",
        "FullImages/DisplayTableManequin_left.png",
        "FullImages/bit_shop_glass_rail.png",
        "furniture/GumballMachine/img1.png",
        "furniture/GumballMachine/img2.png",
        "fogWall/fog_wall_tileable_a.png",
        "fogWall/fog_wall_tileable_b.png",
      ], // Image layers
      [
        "furniture/ShopDisplayTable/shop_display_table.png",
        "furniture/short_display_table/short_display_table.png",
        "furniture/tall_display_table/tall_display_table.png",
        "furniture/torso_mannequin/torso_mannequin.png",
        "furniture/bit_shop_glass_rail/bit_shop_glass_rail.png",
        "furniture/bit_shop_palm/bit_shop_palm.png",
        "furniture/chic_plant/chic_plant.png",
      ], // Spritesheets
      [], //Animation atlas
      true,
    );

    this._shopAPI = new ShopAPI(
      import.meta.env.VITE_API_SHOP || "https://apigateway.bithotel.io/shop",
    );
  }

  // MARKTWAIN - make this correct at some point
  public override getBounds(): Rectangle {
    return new Rectangle(-10000, -10000, 20000, 20000);
  }

  public override getNetworkMatchIdentifier(): string {
    return "shop";
  }

  public override onInteractableImageInteract(image: OrderableImage) {
    if ("interact" in image.interaction) {
      stretch(this.tweens, image.image);
    }
  }

  public override onNPCHover(npc: OrderableNonPlayerCharacter) {
    if ("hover" in npc.interaction) {
      npc.lookAt(this._localPlayer.tilePosition);
      npc.say(npc.interaction["hover"]);
    }
  }

  public override onNPCInteract(npc: OrderableNonPlayerCharacter) {
    if ("interact" in npc.interaction) {
      npc.twitch();
      if (npc.interaction["interact"].includes("shop_", 0)) {
        const obj = JSON.parse(npc.interaction["interact"].slice(5));
        dispatchEvent(EnumUiEvents.UiOpenStoreModal, obj);
      } else {
        npc.say(npc.interaction["interact"]);
      }
    }
  }

  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  public onShopDisplayHover(shopDisplayItem: ShopDisplayItem) {}

  public onShopDisplayInteract(shopDisplayItem: ShopDisplayItem) {
    dispatchEvent(EnumUiEvents.UiOpenStoreItemModal, shopDisplayItem.itemTile);
    twitch(this.tweens, shopDisplayItem);
  }

  public onShopDisplayOut(image: RemoteImage) {}

  protected override async create() {
    await super.create();

    // For handling the spawning of shop items
    this._shopDisplaysComponent = this.addComponent<ShopDisplaysComponent>(
      new ShopDisplaysComponent(),
    );
    // So we retrieve NPC interaction events
    this._shopDisplaysComponent.delegate = this;
  }

  protected override async init(data?: object) {
    await super.init(data);

    // Check the origin and adjust the spawn point accordingly
    if (data && "origin" in data) {
      const { origin } = <ISceneSwitchingData>data;
      const validOrigins = [LobbyScene.SceneKey];
      if (validOrigins.indexOf(origin) > -1) {
        this.setSpawnLocation(origin);
      }
    } else {
      this.setSpawnLocation("default");
    }
  }

  protected override async onCreated() {
    super.onCreated();

    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/lobby/audio/theme.ogg",
    });
    this._audioComponent.playBackgroundAudio();

    // Load items and display them
    let items = await this._shopAPI.getCollectionShowcase();

    // Make sure there aren't too many to show
    items = items.slice(0, this._shopDisplaysComponent.numberOfDisplays);
    this._shopDisplaysComponent.display(items);
  }

  protected override preload() {
    this.load.audio(AudioKeys.Theme, "/assets/game/mini/lobby/audio/theme.ogg");
    super.preload();
  }
}
