import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import MemoryBoard from "@game/mini/memory/client/gameobjects/MemoryBoard";
import TileSinkAnimation from "@game/mini/memory/client/animations/TileSinkAnimation";

export default class BoardFallAnimation extends Phaser.Events.EventEmitter {
  constructor(protected _scene: MemoryGameScene, private _board: MemoryBoard) {
    super();
  }

  play(correctTileId: number) {
    const tiles = this._board.tiles;
    let lastAnim: TileSinkAnimation = null;

    for (let i = 0; i < tiles.length; i++) {
      if (tiles[i].tileId !== correctTileId) {
        lastAnim = tiles[i].sink(Phaser.Math.RND.integerInRange(0, 250));
      }
    }

    lastAnim.once("complete", () => this.emit("complete"), this);
  }
}
