import GameScene from "@game/engine/scenes/GameScene";
import { GameObjects, Tweens } from "phaser";
import { isMobile } from "web3modal";

class BackgroundMangment {
  private scene: GameScene;

  private planets: GameObjects.Sprite[] = [];
  private bkg: GameObjects.Sprite | undefined;
  private midgr: GameObjects.Sprite | undefined;
  private foreground: GameObjects.Sprite | undefined;
  private planetSpawner: NodeJS.Timeout | undefined;

  constructor(scene: GameScene) {
    this.scene = scene;

    scene.scale.gameSize.height;
    scene.scale.gameSize.width;

    this.addBackgrounds();
    this.foregroundSet();
    if (!isMobile()) this.initPlanets();
  }

  private clearPlanets() {
    this.planets.forEach((planet) => {
      this.scene.tweens.add({
        targets: planet,
        alpha: 0,
        duration: 2000,
      });
      setTimeout(() => {
        planet.destroy();
      }, 2000);
    });
    this.planets = [];
  }

  private initPlanets() {
    //init 3 planets
    for (let i = 0; i < 4; i++) {
      const rand = Math.random();
      this.initPlanet(
        Math.round(rand) === 0
          ? this.scene.scale.gameSize.width -
              50 +
              (i * this.scene.scale.gameSize.width) / 100
          : this.scene.scale.gameSize.width / 5 +
              (i * this.scene.scale.gameSize.width) / 100,
      );
    }

    this.spawnPlanets();
  }

  private spawnPlanets() {
    this.planetSpawner = setTimeout(() => {
      this.clearPlanets();
      this.initPlanets();
    }, 10000);
  }

  private initPlanet(x?: number) {
    const random =
      Math.floor(
        Math.random() *
          (this.scene.scale.gameSize.height -
            this.scene.scale.gameSize.height * 0.3 +
            1),
      ) + 1;

    const randomPlanet = Math.floor(Math.random() * 7);

    if (x === undefined) {
      x = this.scene.scale.gameSize.width + 500;
    }

    const planet = this.scene.add.sprite(x, random, "p" + randomPlanet);
    planet.rotation = Math.random() * Math.PI * 2;
    planet.alpha = 0;
    this.scene.tweens.add({
      targets: planet,
      alpha: 1,
      duration: 300,
    });

    const moveTime = Math.floor(Math.random() * (155000 - 100000 + 1)) + 90000;

    this.scene.tweens.add({
      targets: planet,
      x: -500,
      duration: Math.random() * moveTime * 3,
    });
    this.planets?.push(planet);
  }

  private addBackgrounds(prev: number = 0) {
    this.bkg = this.scene.add.sprite(0, 0, "backgroundCrash");

    this.setBkgPositionAndScale(this.bkg, prev);

    this.midgr = this.scene.add.sprite(0, 0, "midground");

    this.setBkgPositionAndScale(this.midgr, prev);

    if (this.midgr.x < this.scene.scale.gameSize.width) {
      this.addBackgrounds(++prev);
    }
  }

  private setBkgPositionAndScale(sp: GameObjects.Sprite, prev: number) {
    const ar = this.scene.scale.gameSize.height / sp.height;
    sp.scale = ar;

    sp.x = (sp.width * ar) / 2 + prev * sp.width * ar;
    sp.y = (sp.height * ar) / 2;
  }

  private foregroundSet() {
    this.foreground = this.scene.add.sprite(0, 0, "foreground");

    const ar = this.scene.scale.gameSize.width / this.foreground.width;
    this.foreground.scale = ar;
    this.foreground.y =
      this.scene.scale.gameSize.height - (this.foreground.height * ar) / 2;
    this.foreground.x = this.scene.scale.gameSize.width / 2;
  }

  public destroy() {
    this.planets.forEach((planet) => planet.destroy());
    this.bkg?.destroy();
    this.midgr?.destroy();
    this.foreground?.destroy();
    this.scene.removeComponent(this.scene);
    clearTimeout(this.planetSpawner);
  }
}

export { BackgroundMangment };
