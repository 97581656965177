import EntityComponent from "@game/engine/components/EntityComponent";
import Vector2 = Phaser.Math.Vector2;
import { GameSettings } from "@game/mini/memory/common/data/GameSettings";

export default class LineWalkerComponent extends EntityComponent {
  public static EventTypes = { Completed: "completed", Started: "started" };

  public moveSpeed = GameSettings.PLAYER_SPEED;
  public events: Phaser.Events.EventEmitter = new Phaser.Events.EventEmitter();

  private _startPoint: Vector2 = new Vector2();
  private _endPoint: Vector2 = new Vector2();
  private _distance: number = 0.0;
  private _walking: boolean = false;
  private _progress: number = 0.0;

  constructor() {
    super();

    this.isTickEnabled = true;
  }

  protected onOwnerSet() {
    super.onOwnerSet();

    this.owner.depth = this.owner.y;
  }

  walkTo(x: number, y: number) {
    this._startPoint.set(this.owner.x, this.owner.y);
    this._endPoint.set(x, y);
    this._distance = Phaser.Math.Distance.BetweenPoints(
      this._startPoint,
      this._endPoint,
    );
    this._progress = 0.0;

    this._walking = true;

    this.events.emit(LineWalkerComponent.EventTypes.Started);
  }

  stop() {
    this._walking = false;
    this.events.emit(LineWalkerComponent.EventTypes.Completed);
  }

  tick(deltaSeconds: number, deltaTime: number, time?: number) {
    if (this._walking) {
      this._progress = Math.min(
        1,
        this._progress + (deltaSeconds * this.moveSpeed) / this._distance,
      );

      this.owner.x = Phaser.Math.Interpolation.Linear(
        [this._startPoint.x, this._endPoint.x],
        this._progress,
      );
      this.owner.y = Phaser.Math.Interpolation.Linear(
        [this._startPoint.y, this._endPoint.y],
        this._progress,
      );
      this.owner.depth = this.owner.y;

      if (this._progress === 1) {
        this.events.emit(LineWalkerComponent.EventTypes.Completed);
        this._walking = false;
      }
    }
  }
}
