import { Network } from "@game/engine/networking/Network";

export default class Tracking {
  static getDeviceTrackingInfo(): {
    generalFingerprint: string;
    platform: string;
    manufacturer: string;
    uniqueId: string;
  } {
    const data: {
      generalFingerprint: string;
      platform: string;
      manufacturer: string;
      uniqueId: string;
    } = {
      generalFingerprint: "",
      platform: "",
      manufacturer: "",
      uniqueId: "",
    };
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get("platform")) {
      data.generalFingerprint = "";
      data.platform = searchParams.get("platform");
      data.manufacturer = searchParams.get("manufacturer");
      data.uniqueId = searchParams.get("uniqueId");
    } else {
      data.generalFingerprint = window.navigator.userAgent;
      data.platform = data.generalFingerprint.substring(
        data.generalFingerprint.indexOf("(") + 1,
        data.generalFingerprint.indexOf(")"),
      );
      data.generalFingerprint =
        data.generalFingerprint.slice(0, data.generalFingerprint.indexOf("(")) +
        data.generalFingerprint.slice(data.generalFingerprint.indexOf(")") + 1);
      data.manufacturer = "";
      data.uniqueId = "";
    }
    return data;
  }

  static async storeTrackingInfo() {
    const privateMetadata = await Network.Core.core.retrievePrivateMetadata();
    privateMetadata.deviceInfo = {
      ...this.getDeviceTrackingInfo(),
    };
    await Network.Core.core.storePrivateMetadata(privateMetadata);
  }
}
