import EventEmitter = Phaser.Events.EventEmitter;
import StampWarsScene, {
  STAMP_WARS_TEAM_COLORS,
  STAMP_WARS_TEAM_COLORS_LIGHT,
} from "@game/mini/stamp_wars/StampWarsScene";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import TileChangeFX from "@game/mini/stamp_wars/gameobjects/TileChangeFX";
import StampWarsBoard from "@game/mini/stamp_wars/gameobjects/StampWarsBoard";
import {
  STAMP_WARS_TILE_LEVEL_MAX,
  STAMP_WARS_TILE_LEVEL_TOTAL,
} from "@common/modules/stampwars/constants/StampWarsConstants";
import ScoreFloater from "@game/mini/stamp_wars/gameobjects/ScoreFloater";

export default class TileFillAnimation extends EventEmitter {
  private _board: StampWarsBoard;
  private _scene: StampWarsScene;
  private _tileChangeFX: TileChangeFX;

  constructor(
    scene: StampWarsScene,
    board: StampWarsBoard,
    tileChangeFX: TileChangeFX,
  ) {
    super();

    this._scene = scene;
    this._board = board;
    this._tileChangeFX = tileChangeFX;
  }

  public playFill(
    filledArea: Vector2Like[],
    playerPos: Vector2Like,
    playerTeam: number,
  ) {
    this._scene.audioComponent.play("fill_area");

    let numFilledTiles = 0;

    filledArea.forEach((tilePosition) => {
      const tile = this._board.getTileAt(tilePosition.x, tilePosition.y);
      const level = this._board.getTileLevelFromValue(tile.index);
      const worldPos = this._scene.getPositionForTileAt(tile.x, tile.y);

      if (level < STAMP_WARS_TILE_LEVEL_MAX) {
        numFilledTiles++;

        this._scene.time.delayedCall(
          Phaser.Math.Distance.BetweenPoints(worldPos, playerPos) * 1.5,
          () => {
            tile.index =
              playerTeam * STAMP_WARS_TILE_LEVEL_TOTAL +
              STAMP_WARS_TILE_LEVEL_MAX +
              1;

            const color = Phaser.Display.Color.ValueToColor(
              STAMP_WARS_TEAM_COLORS[playerTeam],
            ).color;

            this._tileChangeFX.playTileGlow(worldPos, color);
            this._tileChangeFX.playParticles(worldPos, color);
          },
        );
      }
    });

    const floater = new ScoreFloater(
      this._scene,
      "+" + numFilledTiles,
      STAMP_WARS_TEAM_COLORS_LIGHT[playerTeam],
      {
        x: playerPos.x,
        y: playerPos.y - 150,
      },
    );

    floater.once("complete", () => floater.destroy(true), floater);
  }
}
