import Container = Phaser.GameObjects.Container;
import { OrderableType } from "@game/engine/enums/OrderableType";
import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { scaleOut } from "@game/engine/utilities/AnimationHelper";
import { TilePosition3DZero } from "@game/engine/utilities/TilePositionHelper";
import Graphics = Phaser.GameObjects.Graphics;
import Tween = Phaser.Tweens.Tween;

export default class OrderablePulsatingLoadingIcon
  extends Container
  implements IIsometricOrderable
{
  public orderableType: OrderableType = OrderableType.Static;
  public tilePosition: TilePosition3D = TilePosition3DZero;

  private readonly _pulse: Graphics;
  private readonly _tween: Tween;

  constructor(scene, color: number = 0xffffff, radius: number = 8) {
    super(scene);
    const dot = new Graphics(scene, { fillStyle: { color: color } });
    dot.fillCircle(0, 0, radius);
    this.add(dot);

    this._pulse = new Graphics(scene, {
      lineStyle: { color: color, width: 4 },
    });
    this._pulse.strokeCircle(0, 0, radius * 2).setScale(0);
    this.add(this._pulse);

    this._tween = scene.tweens.add({
      duration: 2048,
      ease: "Cubic.easeOut",
      loop: -1,
      props: {
        alpha: 0,
        scaleX: 2,
        scaleY: 2,
      },
      targets: this._pulse,
    });
  }

  public done = (): Tween => {
    this._tween.stop();

    if (this.scene) {
      return scaleOut(this.scene.tweens, this).once("complete", this.destroy);
    }
    return null;
  };
}
