import equals, {
  TilePosition3DZero,
} from "@game/engine/utilities/TilePositionHelper";
import Pawn from "./Pawn";
import { TilePosition3D } from "./navigation/Pathfinder";
import TiledGameScene from "./scenes/TiledGameScene";

export enum PlayerState {
  Idle = "idle",
  // Sitting = "sit",
  Walking = "walk",
}

export default class Basic2DCharacter extends Pawn {
  public static EventTypes = {
    StateChanged: "playerstatechanged",
    MovedToTile: "playermovedtotile",
  };

  public get flooredPosition() {
    return new Phaser.Math.Vector2(
      this.x + this.floorOffset.x,
      this.y + this.floorOffset.y,
    );
  }

  public declare state: PlayerState;

  protected previousTilePosition: TilePosition3D = TilePosition3DZero;
  protected tilePosition: TilePosition3D = TilePosition3DZero;

  constructor(
    scene: Phaser.Scene,
    x: number,
    y: number,
    public floorOffset: Phaser.Math.Vector2 = Phaser.Math.Vector2.ZERO,
  ) {
    super(scene, x, y);
  }

  public addedToScene() {
    super.addedToScene();

    // Start out idling
    this.setPlayerState(PlayerState.Idle);
  }

  public tick(deltaSeconds: number, deltaTime: number, time: number) {
    super.tick(deltaSeconds, deltaTime, time);

    const tileAndPosition = (<TiledGameScene>this.scene).getTileAndPositionAt(
      new Phaser.Math.Vector2(this.x, this.y),
      0,
    );

    if (tileAndPosition && tileAndPosition.tile) {
      const tilePos: TilePosition3D = {
        x: tileAndPosition.tile.x,
        y: tileAndPosition.tile.y,
        z: tileAndPosition.tile.index,
      };

      if (!equals(tilePos, this.tilePosition)) {
        this.previousTilePosition = this.tilePosition;
        this.tilePosition = tilePos;

        this.emit(
          Basic2DCharacter.EventTypes.MovedToTile,
          this.tilePosition,
          this.previousTilePosition,
        );
      }
    }
  }

  public setPlayerState(state: PlayerState) {
    if (this.state === state) {
      return;
    }
    const previousState: PlayerState = this.state;
    this.state = state;

    // Make sure we don't do any unneeded updates - only update when we're walking
    switch (this.state) {
      case PlayerState.Idle:
        this.unregisterForTick();
        break;

      case PlayerState.Walking:
        this.registerForTick();
        break;
    }

    this.emit(
      Basic2DCharacter.EventTypes.StateChanged,
      this,
      this.state,
      previousState,
    );
  }
}
