import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { ISceneSwitchingData } from "@game/engine/interfaces/ISceneSwitchingData";
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import LobbyScene from "@game/mini/lobby/LobbyScene";
import PlinkoScene from "@game/mini/plinko/PlinkoScene";
import Rectangle = Phaser.Geom.Rectangle;
import { EnumGameEvents } from "@shared/enums";
import RemotePlayerCharacter from "../lobby/gameobjects/RemotePlayerCharacter";
import { dispatchEvent } from "@shared/Events";

class AudioKeys {
  public static Theme = "arcadetheme";
}

export default class MoonbeamScene extends PublicSpaceGameScene {
  public static SceneKey: string = "MoonbeamScene";

  constructor() {
    super(
      {
        key: MoonbeamScene.SceneKey,
      },
      "/assets/game/mini/moonbeam/",
      "moonbeam.tmj",
      [
        "floor_tile_sprite_sheet.png",
        "Highlight.png",
        "furniture/moonbeamarcademachine_1x1x2_1.png",
        "furniture/moonbeambarstool_1x1x2_1.png",
        "furniture/moonbeamhologram_3x1x3_1.png",
        "furniture/moonbeamplant_1x1x2_1.png",
        "furniture/moonbeamseatblue_1x1x2_1.png",
        "furniture/moonbeamseatpink_1x1x2_1.png",
        "furniture/moonbeamsofablue_4x1x2_1.png",
        "furniture/moonbeamsofapink_4x1x2_1.png",
        "furniture/moonbeamtable_2x2x2_1.png",
        "furniture/rockettableblue_1x1x2_1.png",
        "furniture/rockettablepink_1x1x2_1.png",
        "furniture/scifiairhockeytable_3x2x2_1.png",
        "furniture/scifisidetable_1x1x2_1.png",
      ], // Tilesets
      [
        "furniture/scifiairhockeytable_sh_a.png",
        "background.png",
        "crates.png",
        "dividers_a_l.png",
        "dividers_a_r.png",
        "dividers_b_l.png",
        "dividers_b_r.png",
        "door_cover_a.png",
        "door_cover_b.png",
        "half_wall_a.png",
        "half_wall_a_a.png",
        "half_wall_a_a_left.png",
        "half_wall_a_b.png",
        "half_wall_b_a.png",
        "half_wall_b_b.png",
        "moon_l.png",
        "moon_r.png",
        "moonbeam_plant_bed_top.png",
        "moonbeam_plant_bed.png",
      ], // Image layers
      [
        "furniture/moonbeamarcademachine_1x1x2_1.png",
        "furniture/moonbeambarstool_1x1x2_1.png",
        "furniture/moonbeamhologram_3x1x3_1.png",
        "furniture/moonbeamplant_1x1x2_1.png",
        "furniture/moonbeamseatblue_1x1x2_1.png",
        "furniture/moonbeamseatpink_1x1x2_1.png",
        "furniture/moonbeamsofablue_4x1x2_1.png",
        "furniture/moonbeamsofapink_4x1x2_1.png",
        "furniture/moonbeamtable_2x2x2_1.png",
        "furniture/rockettableblue_1x1x2_1.png",
        "furniture/rockettablepink_1x1x2_1.png",
        "furniture/scifiairhockeytable_3x2x2_1.png",
        "furniture/scifisidetable_1x1x2_1.png",
      ], // Spritesheets
      [], //Animation atlas
      true,
    );

    // Adding audio for loading
    // this._audioComponent.addSoundEffects();
  }

  public getBounds(): Rectangle {
    return new Rectangle(-10000, -10000, 20000, 20000);
  }

  public override getNetworkMatchIdentifier(): string {
    return "moonbeam";
  }
  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  protected async create(): Promise<void> {
    await super.create();
  }

  protected init(data?) {
    super.init(data);

    // Check the origin and adjust the spawn point accordingly
    if (data && "origin" in data) {
      const { origin } = <ISceneSwitchingData>data;
      const validOrigins = [LobbyScene.SceneKey];
      if (validOrigins.indexOf(origin) > -1) {
        this.setSpawnLocation(origin);
      }
    }
  }

  protected onCreated() {
    super.onCreated();
    // this._audioComponent.pauseOnBlur = true;
    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/arcade/audio/theme.mp3",
    });
    this._audioComponent.playBackgroundAudio();
    // this._audioComponent.masterVolume = 1.0;
    // this._audioComponent.soundEffectVolume = 1.0;
  }

  protected preload(): void {
    this.load.audio(
      AudioKeys.Theme,
      "/assets/game/mini/arcade/audio/theme.mp3",
    );
    super.preload();
  }
}
