import Entity from "@game/engine/Entity";
import Tilemap = Phaser.Tilemaps.Tilemap;
import MemoryTile from "@game/mini/memory/client/gameobjects/MemoryTile";
import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import { MEMORY_BOARD_TILE_RESOLUTION } from "@common/modules/memory/constants/MemoryConstants";

export default class MemoryBoard extends Entity {
  public get numCols(): number {
    return this._numCols;
  }
  public get numRows(): number {
    return this._numRows;
  }

  private _numCols: number;
  private _numRows: number;

  private _map: Tilemap;
  private _grid: MemoryTile[][] = [];
  private _tiles: MemoryTile[] = [];

  constructor(
    scene: MemoryGameScene,
    numCols: number,
    numRows: number,
    map: Tilemap,
  ) {
    super(scene);

    this.scene = scene;

    this._numCols = numCols;
    this._numRows = numRows;
    this._map = map;

    this.initGrid();
  }

  private initGrid() {
    for (let iCol = 0; iCol < this._numCols; iCol++) {
      this._grid[iCol] = new Array(this._numRows);

      for (let iRow = 0; iRow < this._numRows; iRow++) {
        const memoryTile = new MemoryTile(this.scene);
        const tile = this._map.getTileAt(
          iCol * MEMORY_BOARD_TILE_RESOLUTION,
          iRow * MEMORY_BOARD_TILE_RESOLUTION,
        );

        memoryTile.setDefaultPosition(
          tile.pixelX + tile.width / 2,
          tile.pixelY,
        );

        this.scene.add.existing(memoryTile);

        this._grid[iCol][iRow] = memoryTile;
        this._tiles.push(memoryTile);
      }
    }
  }

  public get tiles() {
    return this._tiles.slice(0);
  }

  public getTileAt(col, row): MemoryTile {
    if (col < 0 || col >= this._numCols || row < 0 || row >= this._numRows) {
      return null;
    }

    return this._grid[col][row];
  }

  public hideAllTiles() {
    this._tiles.forEach((tile) => tile.setElementVisible(false));
  }

  public showTilesWithId(tileId: number) {
    this._tiles.forEach((tile) =>
      tile.setElementVisible(tile.tileId === tileId),
    );
  }
}
