import Container = Phaser.GameObjects.Container;
import Size = Phaser.Structs.Size;
import Text = Phaser.GameObjects.Text;

export default class CryptoBomberEndScreen extends Container {
  constructor(scene, hasWon: boolean) {
    super(scene, 0, 0);

    const gameOverText = new Text(scene, 0, -32, "GAME OVER", {
      align: "center",
      color: "#ffffff",
      fontFamily: "Depixel",
      fontSize: "32px",
      padding: { x: 8, y: 8 },
    })
      .setOrigin(0.5)
      .setScrollFactor(0);

    const youWonLost = new Text(
      scene,
      0,
      32,
      "You " + (hasWon ? "won" : "lost"),
      {
        align: "center",
        color: "#ffffff",
        fontFamily: "Depixel",
        fontSize: "64px",
      },
    )
      .setAlpha(0)
      .setOrigin(0.5)
      .setScrollFactor(0);

    // Make it blink?
    scene.tweens.add({
      duration: 1000,
      ease: function (v) {
        return v <= 0.5 ? 0 : 1;
      },
      props: {
        alpha: 1,
      },
      repeat: -1,
      targets: youWonLost,
    });

    this.add(gameOverText);
    this.add(youWonLost);

    this.setDepth(999);
  }

  public centerOnDisplay(gameSize: Size) {
    this.y = gameSize.height * 0.5;
    this.x = gameSize.width * 0.5;
  }
}
