import { Scene } from "phaser";
import Vector2Like = Phaser.Types.Math.Vector2Like;

export default class ScoreFloater extends Phaser.GameObjects.Text {
  constructor(
    scene: Scene,
    text: string,
    color: string,
    position: Vector2Like,
  ) {
    super(scene, position.x, position.y, text, {
      fontFamily: "Depixel",
      fontSize: "40px",
      stroke: "#000",
      strokeThickness: 12,
      color: color || "#ffffff",
    });

    scene.add.existing(this);
    this.setOrigin(0.5);
    this.setDepth(Number.MAX_VALUE);

    this.play();
  }

  private play() {
    this.scene.tweens.add({
      targets: this,
      y: "-=150",
      duration: 1000,
      ease: "Sine.easeOut",
    });
    this.scene.tweens.add({
      targets: this,
      scale: {
        from: 0,
        to: 1,
      },
      duration: 500,
      ease: "Back.easeOut",
    });
    this.scene.tweens
      .add({
        targets: this,
        alpha: 0,
        delay: 700,
        duration: 300,
      })
      .once("complete", () => this.emit("complete"), this);
  }
}
