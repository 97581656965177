import GameScene from "@game/engine/scenes/GameScene";
import { GameObjects } from "phaser";
import * as rocketdata from "./rocketdata.json";
import * as rocketdatamini from "./rocketdata_mini.json";
import * as rocketdatamaxi from "./rocketdata_maxi.json";

interface RocketPart {
  name: string;
  rarity: number;
}

interface RocketConfig {
  base: RocketPart[];
  bottomwindow: RocketPart[];
  topwindow: RocketPart[];
  thruster: RocketPart[];
}

class RocketGenerator {
  private scene: GameScene;
  private container: GameObjects.Container;
  private basePart: GameObjects.Sprite | undefined;
  private bottomwindowPart: GameObjects.Sprite | undefined;
  private topwindowPart: GameObjects.Sprite | undefined;
  private thrustPart: GameObjects.Sprite | undefined;

  constructor(scene: GameScene) {
    this.scene = scene;

    this.container = this.scene.add.container(0, 0);
  }

  private getRocketPart(partData: RocketPart): GameObjects.Sprite {
    const part = this.scene.add.sprite(0, 0, partData.name);

    // tining removed as per dev request
    // part.setTint(this.getColourTint(partData.rarity));

    return part;
  }

  private getColourTint(rarity: number): number {
    switch (rarity) {
      case 25:
        return 0x4de69d;
      case 20:
        return 0x8508f3;
      case 15:
        return 0xd73bb3;
      case 10:
        return 0xf88527;
      case 5:
        return 0xfdc515;
      default:
        return 0x4de69d;
    }
  }

  private randomlySelectRocketPart(partList: RocketPart[]): RocketPart {
    let raritySum = 0;

    for (let baseId = 0; baseId < partList.length; baseId++) {
      const element = partList[baseId];
      raritySum += element.rarity;
    }

    //TODO: generation should be linked
    let selectedRarity = Math.floor(Math.random() * raritySum);
    raritySum = 0;

    for (let baseId = 0; baseId < partList.length; baseId++) {
      const element = partList[baseId];
      raritySum += element.rarity;

      if (raritySum >= selectedRarity) {
        return element;
      }
    }
  }

  public generateRocket() {
    // const generatedRocketContainer = this.scene.add.container(250, 450);
    const generatedRocketContainer = this.container;
    generatedRocketContainer.removeAll(true);

    const dataNormal: RocketConfig = rocketdata;
    const dataMini: RocketConfig = rocketdatamini;
    const dataMaxi: RocketConfig = rocketdatamaxi;

    const rocketType = Math.floor(Math.random() * 3);
    let data: RocketConfig;

    if (rocketType === 0) {
      data = dataNormal;
    } else if (rocketType === 1) {
      data = dataMini;
    } else {
      data = dataMaxi;
    }

    // const data:any = ;

    const basePart = this.randomlySelectRocketPart(data.base);
    const bottomwindowPart = this.randomlySelectRocketPart(data.bottomwindow);
    const topwindowPart = this.randomlySelectRocketPart(data.topwindow);
    const thrustPart = this.randomlySelectRocketPart(data.thruster);

    this.basePart = this.getRocketPart(thrustPart);
    this.bottomwindowPart = this.getRocketPart(basePart);
    this.topwindowPart = this.getRocketPart(bottomwindowPart);
    this.thrustPart = this.getRocketPart(topwindowPart);

    generatedRocketContainer.add(this.basePart);
    generatedRocketContainer.add(this.bottomwindowPart);
    generatedRocketContainer.add(this.topwindowPart);
    generatedRocketContainer.add(this.thrustPart);
  }

  public destroy() {
    this.basePart?.destroy();
    this.bottomwindowPart?.destroy();
    this.topwindowPart?.destroy();
    this.thrustPart?.destroy();
  }

  public getContainer(): GameObjects.Container {
    return this.container;
  }
}

export { RocketGenerator };
export type { RocketPart };
