import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import Tile = Phaser.Tilemaps.Tile;
import FurnitureEditorUI from "@game/mini/room/objects/FurnitureEditorUI";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import Pointer = Phaser.Input.Pointer;
import FurnitureFactory from "@game/mini/room/FurnitureFactory";
import FurnitureComponent from "@game/engine/components/scenecomponents/furniture/FurnitureComponent";
import { NFTHelpers } from "@shared/nft";
import NFT = NFTHelpers.NFT;
import { TilePosition2D } from "@common/interfaces/TilePosition2D";

enum FurniturePlacerState {
  Idle,
  Placing,
  Moving,
}

enum FurnitureType {
  FloorTile,
  Normal,
}

export default class FurniturePlacer extends Phaser.Events.EventEmitter {
  private _editorUI: FurnitureEditorUI;
  private _editingFurniture: FurnitureObject;
  private _floorPreviewTile: Tile | undefined;
  private _floorTileId: number;
  private _floorTilePreviewPreviousValue: number;
  private _floorTileTokenId: string;
  private _furnitureComponent: FurnitureComponent;
  private _furniturePreview: FurnitureObject;
  private _furnitureType: FurnitureType;
  private _scene: CustomizableRoomScene;
  private _state: FurniturePlacerState = FurniturePlacerState.Idle;

  constructor(
    scene: CustomizableRoomScene,
    furnitureComponent: FurnitureComponent,
  ) {
    super();

    this._scene = scene;
    this._furnitureComponent = furnitureComponent;

    this._editorUI = new FurnitureEditorUI(scene);
    this._editorUI.on("rotate", this.rotateFurniture, this);
    this._editorUI.on("move", this.moveFurniture, this);
    this._editorUI.on("close", this.stopEditing, this);
    this._editorUI.on("pick_up", this.pickUpFurniturePiece, this);

    scene.add.existing(this._editorUI);
  }

  public getFloorTileFromNavigatableTile(navigatableTile: Tile): Tile {
    const layerIndex = navigatableTile.layer.name.split("navigatable ")[1];
    const layer = navigatableTile.tilemap.layers.find(
      (layer) => layer.name === "floor " + layerIndex,
    );
    const floorTile = layer!.tilemapLayer.getTileAt(
      navigatableTile.x,
      navigatableTile.y,
    );

    return floorTile;
  }

  public get editorUI(): FurnitureEditorUI {
    return this._editorUI;
  }

  public onTileHovered(position: TilePosition3D): boolean {
    const navigatableTile = this._scene.getNavigatableTileAt(position);

    if (this._state !== FurniturePlacerState.Idle) {
      if (this._furnitureType === FurnitureType.Normal) {
        this._furniturePreview.setPosition(position);
        this.updatePreviewVisibility();
        this._scene.sortOrderables();
      } else {
        const floorTile = this.getFloorTileFromNavigatableTile(navigatableTile);
        if (floorTile === this._floorPreviewTile) return;

        if (this._floorPreviewTile) {
          this._floorPreviewTile.index = this._floorTilePreviewPreviousValue;
        }

        this._floorPreviewTile = floorTile;
        this._floorTilePreviewPreviousValue = floorTile.index;

        this._scene.previewFloorTile(floorTile, this._floorTileId);
      }
      return true;
    }
    return false;
  }

  public rotateFurniture() {
    this._scene.setFurnitureFloorAsBlocked(this._editingFurniture, false);
    this._editingFurniture.increaseRotation();

    while (
      !this._furnitureComponent.doesFurnitureObjectFitIn(this._editingFurniture)
    ) {
      this._editingFurniture.increaseRotation();
    }

    this._scene.setFurnitureFloorAsBlocked(this._editingFurniture, true);

    this.emit("furniture_rotated", this._editingFurniture);
  }

  public startPlacing(name: string, id: string) {
    if (this._state !== FurniturePlacerState.Idle) {
      this._furniturePreview.destroy();
    }

    this._furniturePreview = FurnitureFactory.createFurniture(
      this._scene,
      name,
      id,
    );
    this._furniturePreview.alpha = 0;

    this._state = FurniturePlacerState.Placing;
    this._furnitureType = FurnitureType.Normal;
  }

  public startPlacingFloorTile(tileId: string, id: string) {
    this._state = FurniturePlacerState.Placing;
    this._furnitureType = FurnitureType.FloorTile;
    this._floorTileId = Number.parseInt(tileId);
    this._floorTileTokenId = id;
  }

  private editFurniture(furnitureObject: FurnitureObject) {
    this._editingFurniture = furnitureObject;

    const worldPos = this._scene.worldPositionAtTilePosition(
      furnitureObject.position,
    );

    this._editorUI.setPosition(worldPos.x, worldPos.y);
    this._editorUI.show();
  }

  private moveFurniture() {
    this._furniturePreview = FurnitureFactory.createFurniture(
      this._scene,
      this._editingFurniture.name,
      this._editingFurniture.id,
    );

    this._furniturePreview.alpha = 0;
    this._furniturePreview.setPosition(this._editingFurniture.position);
    this._furniturePreview.setDirection(this._editingFurniture.direction);

    this._state = FurniturePlacerState.Moving;
    this._furnitureType = FurnitureType.Normal;
  }

  public onEditFurniture(funitureObject: FurnitureObject) {
    if (this._editorUI.open) return;

    const role = this._scene.playerRole;

    if (role !== "owner" && role !== "vip") {
      return;
    }

    this.editFurniture(funitureObject);
  }

  public onTilePressed(tilePosition: TilePosition3D): boolean {
    const tile = this._scene.getNavigatableTileAt(tilePosition);

    if (this._state !== FurniturePlacerState.Idle) {
      if (this._furnitureType === FurnitureType.Normal) {
        this.placeNormalFurniture(tilePosition);
      } else if (this._furnitureType === FurnitureType.FloorTile) {
        this.placeFloorTile(tile);
      }
      this._state = FurniturePlacerState.Idle;
      return true;
    }

    return false;
  }

  private placeFloorTile(navigatableTile: Tile) {
    this._floorPreviewTile = undefined;
    this.emit("floor_tile_placed", this._floorTileTokenId, {
      x: navigatableTile.x,
      y: navigatableTile.y,
      z: Number(navigatableTile.layer.name.split("navigatable ")[1]),
    });
  }

  private placeNormalFurniture(position: TilePosition3D) {
    this._furniturePreview.setPosition(position);
    if (
      this._furnitureComponent.doesFurnitureObjectFitIn(this._furniturePreview)
    ) {
      if (this._state === FurniturePlacerState.Placing) {
        this.updatePreviewVisibility();
        this._furniturePreview.alpha = 1;
        this.emit("furniture_placed", this._furniturePreview);
      } else {
        this.emit(
          "furniture_moved",
          this._editingFurniture,
          this._furniturePreview,
        );
        this._furniturePreview.destroy();
        const worldPos = this._scene.worldPositionAtTilePosition(
          this._editingFurniture.position,
        );

        this._editorUI.move(worldPos.x, worldPos.y);
      }
    } else {
      this._furniturePreview.destroy();
    }

    this._furniturePreview = null;
    this._scene.sortOrderables();
  }

  private pickUpFurniturePiece() {
    this.emit("furniture_picked_up", this._editingFurniture);
    this.stopEditing();
  }

  private stopEditing() {
    this._editorUI.hide();
    this._editingFurniture = null;

    if (this._state !== FurniturePlacerState.Idle) {
      this._furniturePreview.destroy();
    }

    this._state = FurniturePlacerState.Idle;
  }

  private updatePreviewVisibility() {
    this._furniturePreview.alpha =
      this._furnitureComponent.doesFurnitureObjectFitIn(this._furniturePreview)
        ? 0.5
        : 0;
  }

  destroy() {
    super.destroy();
  }
}
