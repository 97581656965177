import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import { IMemoryPlayerData } from "@common/modules/memory/interfaces/IMemoryPlayerData";
import BitmapText = Phaser.GameObjects.BitmapText;
import Text = Phaser.GameObjects.Text;
import NarratorComponent from "@engine/components/scenecomponents/NarratorComponent";

export default class GameNarrationUI {
  private _narratorComponent: NarratorComponent;
  private _texts: Array<Text | BitmapText> = [];
  private _scene: MemoryGameScene;

  constructor(scene: MemoryGameScene, narratorComponent: NarratorComponent) {
    this._scene = scene;
    this._narratorComponent = narratorComponent;
  }

  public narrate(textString: string, delay?: number, color?: string) {
    this._narratorComponent.narrate(textString, delay, color);
  }

  public setVisible(visible: boolean) {
    this._narratorComponent.setVisible(visible);
  }

  public narrateRoundResult(players: IMemoryPlayerData[], roundNumber: number) {
    const eliminatedPlayers = players.filter(
      (playerData) => !playerData.alive && playerData.round === roundNumber,
    );

    eliminatedPlayers.forEach((playerData, index) => {
      this.narrate(
        this._scene.getNakamaUserById(playerData.id)!.username +
          " was eliminated!",
        index * 100,
        "#dc3d62",
      );
    });
  }

  private animateText(text: BitmapText | Text, delay: number = 0) {
    text.x -= 500;

    this._scene.tweens.add({
      targets: text,
      x: text.x + 500,
      ease: "Sine.easeOut",
      duration: 500,
      delay: delay,
    });

    this._scene.tweens
      .add({
        targets: text,
        alpha: 0,
        ease: "Sine.easeIn",
        duration: 2000,
        delay: delay + 5000,
      })
      .once("complete", () => this.removeText(text), this);
  }

  private removeText(text: BitmapText | Text) {
    const index = this._texts.indexOf(text);

    if (index >= 0) {
      this._texts.splice(index, 1);
    }

    text.destroy(true);
    this.repositionTextsVertically();
  }

  private repositionTextsVertically() {
    this._texts.forEach((text, index) => {
      text.y = this._scene.scale.height - 40 - 50 * index;
    });
  }
}
