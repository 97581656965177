import { Scene } from "phaser";

const WIDTH = 194;
const HEIGHT = 77;
const ISO_TAN = Math.tan(Phaser.Math.DEG_TO_RAD * 26.55);

export default class UIScreen extends Phaser.GameObjects.Mesh {
  constructor(scene: Scene, flipped: boolean = false) {
    super(scene, 0, 0, "memory_game_ui");

    const frame = scene.textures.getFrame("memory_game_ui");
    const relativeHeight = HEIGHT / frame.height;
    const relativeWidth = WIDTH / frame.width;
    const flip = flipped ? -1 : 1;

    const vertices = [
      -relativeWidth,
      relativeHeight - ISO_TAN * relativeWidth * flip,
      relativeWidth,
      relativeHeight + ISO_TAN * relativeWidth * flip,
      -relativeWidth,
      -relativeHeight - ISO_TAN * relativeWidth * flip,
      relativeWidth,
      -relativeHeight + ISO_TAN * relativeWidth * flip,
    ];

    const uvs = [0, 1, 1, 1, 0, 0, 1, 0];
    const indicies = [0, 2, 1, 2, 3, 1];

    this.addVertices(vertices, uvs, indicies);

    this.panZ(this.scene.scale.height / 134);
  }
}
