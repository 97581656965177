export enum CryptoBomberOpCodes {
  // ALWAYS start at 1
  OnBombPlaced = 1,
  OnInitializeWorld,
  OnGameMovedToPhase,
  OnPlayerJoined,
  OnPlayersDamaged,
  OnPlayerLeft,
  OnPlayerMoved,
  OnPlayersDied,
  OnPlayersRanked,
  OnPlayerStartMovement,
  OnPlayerStopMovement,
  OnPowerUpCollected,
  OnPowerUpsFound,
  OnTileConquered,

  GameEnd,
  GameReady,
  GameStart,
  InitializeWorld,
  PlayerJoined,
  PlayerLeft,
  PlayerMoved,
  PlayersDamaged,
  PlayersDied,
  PlayerStartMovement,
  PlayerStopMovement,
  PowerUpCollected,
  PowerUpsFound,
  TileConquered,
  TimeUp,
  WaitingForMorePlayers,
}
