import IIsometricOrderable from "@game/engine/interfaces/IIsometricOrderable";
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import { Grid } from "@game/engine/objects/Grid";
import IsometricOrderableStack from "@game/engine/objects/IsometricOrderableStack";
import {
  TilemapImageLayer,
  TilemapLayer,
  TilemapLayerTypes,
  TilemapTileLayer,
} from "@game/engine/objects/TilemapLayer";
import GameScene from "@game/engine/scenes/GameScene";
import {
  IOrderableTileset,
  IOrderableTilesetImportData,
  ISpritesheetImportData,
} from "@game/engine/scenes/OrderableIsometricGameScene";
import { loadAllAvailableCharacterAtlasAndJSONIntoScene } from "@game/engine/utilities/AssetLoaderHelper";
import Tileset = Phaser.Tilemaps.Tileset;
import Tilemap = Phaser.Tilemaps.Tilemap;
import Tile = Phaser.Tilemaps.Tile;
import PhysicsImage = Phaser.Physics.Arcade.Image;
import Vector2 = Phaser.Math.Vector2;
import { TilePosition2D } from "@common/interfaces/TilePosition2D";

export default class OrderableTopDownGameScene extends GameScene {
  public get map(): Tilemap {
    return this._map;
  }

  protected _collidableTiles: PhysicsImage[];
  protected _imagePaths: { [key: string]: string };
  protected _map: Tilemap;
  protected _tilesets: Tileset[];

  private readonly _assetPath: string;
  private _layers: TilemapLayer[];
  private _spritesheetImportDatas: ISpritesheetImportData[];
  private readonly _tilemapPath: string;
  private _tilesetPaths: string[];
  private _orderableGrid: Grid<ITopDownOrderable>;
  private _orderables: ITopDownOrderable[];
  private _orderableTilesets: { [key: number]: IOrderableTileset };

  constructor(
    config,
    assetPath: string,
    tilemapPath: string,
    tilesetPathOrPaths: string[],
    imagePathOrPaths: string[] = [],
    orderableTilesetImportDatas: (string | IOrderableTilesetImportData)[] = [],
  ) {
    super(config);
    this._assetPath = assetPath;
    this._tilemapPath = this._assetPath + tilemapPath;

    // Load the image paths
    this._imagePaths = {};
    imagePathOrPaths.forEach((imagePath) => {
      this._imagePaths[imagePath] = this._assetPath + imagePath;
    });

    // Load the tilesets
    this._tilesetPaths = tilesetPathOrPaths.map(
      (tilesetPath: string) => this._assetPath + tilesetPath,
    );

    // Add all spritesheets for now
    const defaultFrameConfig = {
      frameHeight: 64,
      frameWidth: 64,
    };
    this._spritesheetImportDatas = [];
    orderableTilesetImportDatas.forEach((importData) => {
      let path;
      let frameConfig = defaultFrameConfig;
      if ("string" === typeof importData) {
        path = importData;
      } else {
        frameConfig = importData.frameConfig || defaultFrameConfig;
        path = importData.path;
      }
      const pathSplit = path.split("/");
      const filename = pathSplit[pathSplit.length - 1].split(".")[0];
      this._spritesheetImportDatas.push({
        frameConfig: frameConfig,
        key: filename + "_spritesheet",
        path: this._assetPath + path,
      });

      this._orderables = [];
      this._orderableTilesets = {};
    });
  }

  public addOrderable(orderable: ITopDownOrderable) {
    this._orderables.push(orderable);
  }

  public removeOrderable(orderable: IIsometricOrderable) {
    const index = this._orderables.indexOf(orderable);
    if (index >= 0) {
      this._orderables.splice(index, 1);
    }
  }

  public tilePositionAtCoordinates(x: number, y: number): TilePosition3D {
    return {
      x: Math.floor(x / this._map.tileWidth),
      y: Math.floor(y / this._map.tileHeight),
      z: 0,
    };
  }

  public worldPositionAtTilePosition(
    tilePosition: TilePosition2D,
    isCentered: boolean = true,
  ): Vector2 {
    const worldPosition = new Vector2(
      this._map.tileWidth * tilePosition.x,
      this._map.tileHeight * tilePosition.y,
    );
    if (isCentered) {
      worldPosition.set(
        worldPosition.x + this._map.tileWidth * 0.5,
        worldPosition.y + this._map.tileHeight * 0.5,
      );
    }
    return worldPosition;
  }

  protected override create() {
    super.create();

    this._collidableTiles = [];

    const jsonMap = this.cache.json.get(this.scene.key + "_tilemap_json");

    this.loadOrderableTilesets(jsonMap);

    // Load the actual map
    this._map = this.make.tilemap({ key: this.scene.key + "_tilemap" });

    this.createLayerOrder(jsonMap);

    // Adding all actual tilesets
    this.loadTilesets();

    const numberOfLevels = 2;

    // For bottom levels only
    this._orderableGrid = new Grid<IsometricOrderableStack>(
      this._map.width,
      this._map.height,
      numberOfLevels,
    );

    // For keeping track of all obstacles
    const stackGraph = new Grid<IsometricOrderableStack>(
      this._map.width,
      this._map.height,
      numberOfLevels,
    );

    this._layers.forEach((tilemapLayer: TilemapLayer) => {
      if (!tilemapLayer.visible) {
        return;
      }
      const types = tilemapLayer.getProperty("Types");

      const layerData = tilemapLayer.original;

      if (types && types.includes(TilemapLayerTypes.Orderable)) {
        const level = tilemapLayer.getProperty("Level");
        if (null === level) {
          console.error(
            'Orderable layer "' +
              layerData.name +
              '" is missing the "Level" property',
          );
          return;
        }

        const tilemap = layerData.data;
        for (let y = 0; y < layerData.height; ++y) {
          for (let x = 0; x < layerData.width; ++x) {
            const tile: Tile = tilemap[y][x];

            // Only add if it's not an empty tile
            if (tile.index > -1) {
              const tileset: IOrderableTileset =
                this._orderableTilesets[tile.index];

              let image;
              // Based on if we're collision enabled, choose how to add the image
              if (types.includes(TilemapLayerTypes.Collidable)) {
                image = this.createAddAndRegisterCollidableTile(
                  tile.pixelX + layerData.x,
                  tile.pixelY + layerData.y,
                  tileset.texture,
                  tile.index - tileset.firstGid,
                );
              } else {
                image = this.add
                  .image(
                    tile.pixelX + layerData.x,
                    tile.pixelY + layerData.y,
                    tileset.texture,
                    tile.index - tileset.firstGid,
                  )
                  .setOrigin(0);
              }

              let stack;
              // Check if there's something under this (move it one down as well)
              if (level > 0 && stackGraph.has(x, y + 1, level - 1)) {
                stack = stackGraph.get(x, y + 1, level - 1);
              } else {
                // Create a new stack and add it
                stack = new IsometricOrderableStack();
                this._orderableGrid.add(x, y, level, stack);
              }
              stack.addPart({ x: x, y: y, z: level }, image);
              stackGraph.add(x, y, level, stack);
            }
          }
        }
      } else {
        // Just create and then add the layer as a default tile layer
        this._map.createLayer(layerData.name, this._tilesets);
      }
    });
  }

  protected centerCamera() {
    this.cameras.main?.centerOn(
      this._map.widthInPixels * 0.5,
      this._map.heightInPixels * 0.5,
    );
  }

  protected sortOrderables() {
    if (!this._orderableGrid) {
      return;
    }

    const movableOrderablesGrid = new Grid<ITopDownOrderable[]>(
      this._orderableGrid.width,
      this._orderableGrid.depth,
      this._orderableGrid.height,
    );

    this._orderables.forEach((orderable) => {
      const tilePosition = orderable.tilePosition;
      if (!tilePosition) {
        return;
      }
      if (movableOrderablesGrid.hasAtPosition(tilePosition)) {
        movableOrderablesGrid.getAtPosition(tilePosition).push(orderable);
      } else {
        movableOrderablesGrid.add(
          tilePosition.x,
          tilePosition.y,
          tilePosition.z,
          [orderable],
        );
      }
    });

    let depth: number = 1;
    for (let level: number = 0; level < this._orderableGrid.height; ++level) {
      for (let y: number = 0; y < this._orderableGrid.depth; ++y) {
        for (let x: number = 0; x < this._orderableGrid.width; ++x) {
          // First check if there's a tile here
          if (this._orderableGrid.has(x, y, level)) {
            this._orderableGrid.get(x, y, level).setDepth(depth - 1);
          }

          // Next check if there's a potential moving one here
          if (movableOrderablesGrid.has(x, y, level)) {
            // We need to add 2 because for some reason same depth doesn't mean order is fixed
            const orderables = movableOrderablesGrid.get(x, y, level);
            for (let index = 0; index < orderables.length; ++index) {
              orderables[index].setDepth(depth);
            }
            depth += 2;
          }
        }
      }
    }
  }

  private addOrderableTileset(
    firstGid: number = 1,
    tileCount: number,
    name: string,
  ) {
    for (let index = firstGid; index < firstGid + tileCount; ++index) {
      this._orderableTilesets[index] = {
        firstGid: firstGid,
        texture: name + "_spritesheet",
      };
    }
  }

  private createAddAndRegisterCollidableTile(
    x,
    y,
    texture,
    frame,
  ): PhysicsImage {
    const image: PhysicsImage = this.physics.add.image(x, y, texture, frame);

    image.setImmovable(true).setOrigin(0);
    // image.body.setOffset(this._map.tileWidth * 0.5, this._map.tileHeight * 0.5);
    this._collidableTiles.push(<PhysicsImage>image);
    return image;
  }

  private createLayerOrder(jsonMap) {
    // Setting up for merging image- and tile layers
    // Retrieve the different supported layer types individually
    const { images, layers } = this._map;

    // Place for storing the different layers
    this._layers = new Array(images.length + layers.length);

    // Retrieve the data needed for organising the map structure
    const layerOrderMap = {};

    // Store layers in order
    const numberOfLayers = jsonMap.layers.length;
    for (let index = 0; index < numberOfLayers; ++index) {
      const layer = jsonMap.layers[index];
      layerOrderMap[layer.name] = index;
    }

    // Add and place imagelayers
    images.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapImageLayer(
        original,
      );
      this._layers[layerOrderMap[original.name]].x = original.x - 1344;
    });

    // Add and place tilelayers
    layers.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapTileLayer(
        original,
      );
    });
  }

  private loadOrderableTilesets(jsonMap) {
    // Some sanity checking
    if (jsonMap && "tilesets" in jsonMap) {
      const tilesets = jsonMap.tilesets;
      for (let index = 0; index < tilesets.length; ++index) {
        const tileset = tilesets[index];
        this.addOrderableTileset(
          tileset.firstgid,
          tileset.tilecount,
          tileset.name,
        );
      }
    }
  }

  private loadTilesets() {
    this._tilesets = [];
    this._tilesetPaths.forEach((tilesetURL, index) => {
      // For now, this is fine.. Right? Surely.
      const pathSplit = tilesetURL.split("/");
      const filename = pathSplit[pathSplit.length - 1].split(".")[0];

      // Retrieve the tileset
      this._tilesets.push(
        this._map.addTilesetImage(
          filename,
          this.scene.key + "_tileset_" + index,
        ),
      );
    });
  }

  protected override preload() {
    super.preload();

    // Load all the tilesets (if needed)
    this._tilesetPaths.forEach((tilesetURL, index) => {
      this.load.image(this.scene.key + "_tileset_" + index, tilesetURL);
    });

    // Load images
    for (const [key, path] of Object.entries(this._imagePaths)) {
      this.load.image(key, path);
    }

    // Load spritesheets
    this._spritesheetImportDatas.forEach((spritesheetImportData) => {
      this.load.spritesheet(
        spritesheetImportData.key,
        spritesheetImportData.path,
        spritesheetImportData.frameConfig,
      );
    });

    // Load the tilemap as a tilemap and as straight up JSON (for parsing)
    this.load.json(this.scene.key + "_tilemap_json", this._tilemapPath);
    this.load.tilemapTiledJSON(this.scene.key + "_tilemap", this._tilemapPath);

    loadAllAvailableCharacterAtlasAndJSONIntoScene(this);
  }

  protected override preUpdate(time, deltaTime) {
    super.preUpdate(time, deltaTime);

    // Only sort when needed (or always sort to fix some clipping)
    // if (this._isDirty || this._sortEveryFrame) {
    this.sortOrderables();
    // this._isDirty = false;
    // }
  }
}
