import IsometricGameScene from "@game/engine/scenes/IsometricGameScene";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import MemoryGameClient from "@game/mini/memory/client/MemoryGameClient";
import GamesStatusUI from "@game/mini/memory/client/gameobjects/ui/GameStatusUI";
import OpponentPlayerController from "@game/mini/memory/client/gameobjects/OpponentPlayerController";
import CurrentPlayerController from "@game/mini/memory/client/gameobjects/CurrentPlayerController";
import MemoryGameCharacter from "@game/mini/memory/client/gameobjects/MemoryGameCharacter";
import UIScreen from "@game/mini/memory/client/gameobjects/ui/UIScreen";
import AudioComponent from "@game/engine/components/scenecomponents/AudioComponent";
import { MemoryNetworkedComponent } from "@game/mini/memory/components/scenecomponents/MemoryNetworkedComponent";
import { IMemoryPlayerData } from "@common/modules/memory/interfaces/IMemoryPlayerData";
import IsometricUtils from "@game/mini/memory/utils/IsometricUtils";
import { Network } from "@network";
import {
  MEMORY_BOARD_TILE_RESOLUTION,
  MEMORY_COUNTDOWN_WAITING_PLAYERS_DURATION,
} from "@common/modules/memory/constants/MemoryConstants";
import LoadingSceneComponent from "@engine/components/scenecomponents/LoadingSceneComponent";
import { dispatchEvent, EnumApplicationEvents } from "@shared/Events";
import { EnumGameEvents, EnumNetworkEvents } from "@overlay/enums/Events";
import { TNakamaUser } from "@shared/types/nakama";
import GameNarrationUI from "@game/mini/memory/client/gameobjects/ui/GameNarrationUI";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import MovementInputComponent from "@engine/components/scenecomponents/MovementInputComponent";
import Vector2 = Phaser.Math.Vector2;
import NarratorComponent from "@engine/components/scenecomponents/NarratorComponent";
import { Toast } from "@engine/notifications/Toast";
import TouchControlsSceneComponent from "@engine/components/scenecomponents/TouchControlsSceneComponent";

export default class MemoryGameScene extends IsometricGameScene {
  public static SceneKey = "MemoryGameScene";

  public get networkedComponent(): MemoryNetworkedComponent {
    return this._networkedComponent;
  }

  public narratorUI: GameNarrationUI;
  public statusUI: GamesStatusUI;
  public uiScreens: UIScreen[] = [];

  private _audioComponent: AudioComponent;
  private _character: MemoryGameCharacter;
  private _loadingSceneComponent: LoadingSceneComponent;
  private _matchData: { [key: string]: string };
  private _movementInputComponent: MovementInputComponent;
  private _narratorComponent: NarratorComponent;
  private _networkedComponent: MemoryNetworkedComponent;
  private _opponentControllers = new Map<string, OpponentPlayerController>();
  private _playerController: CurrentPlayerController;
  private _playerCharacterName: string;
  private _playerId: string;
  private _playersNakamaData: TNakamaUser[] = [];
  private _touchControlsSceneComponent: TouchControlsSceneComponent;

  // get networkedComponent(): MemoryNetworkedComponent {
  //   return this._networkedComponent;
  // }

  get playerId(): string {
    return this._playerId;
  }

  protected _client: MemoryGameClient;

  constructor() {
    super(
      {
        key: MemoryGameScene.SceneKey,
        debugMode: false,
      },
      "/assets/game/mini/memory/",
      "memory_2.tmj",
      ["floor-tilesheet.png"],
    );
  }

  protected override init(data?: object): void {
    this._matchData = (data as any)?.matchData;

    this._loadingSceneComponent = this.addComponent<LoadingSceneComponent>(
      new LoadingSceneComponent(false),
    ).on(LoadingSceneComponent.EventTypes.LoadingCompleted, () => {
      this._loadingSceneComponent.setText("connecting..");
    });
    this._audioComponent = this.addComponent<AudioComponent>(
      new AudioComponent(),
    );
  }

  protected preload() {
    super.preload();

    this.load.image("selector", "/assets/game/selector.png");
    this.load.image(
      "memory_background",
      "/assets/game/mini/memory/sample_background.png",
    );
    this.load.atlas(
      "memory_atlas",
      "/assets/game/mini/memory/memory_atlas.png",
      "/assets/game/mini/memory/memory_atlas.json",
    );
    this.load.atlas(
      "animations_atlas",
      "/assets/game/mini/memory/animations_atlas.png",
      "/assets/game/mini/memory/animations_atlas.json",
    );

    this.load.bitmapFont(
      "font",
      "/assets/game/mini/memory/font/font.png",
      "/assets/game/mini/memory/font/font.fnt",
    );
    this.load.audio("music", [
      "/assets/game/mini/memory/audio/memory_music.ogg",
      "/assets/game/mini/memory/audio/memory_music.mp3",
    ]);
  }

  protected override create() {
    super.create();

    this.add.image(66, 276, "memory_background");

    // Hide underlying tiled map
    this.map.forEachTile((tile) => (tile.alpha = 0));

    this._client = new MemoryGameClient(this);

    this.initUI();

    this.hookSceneEvents();

    this._movementInputComponent = this.addComponent<MovementInputComponent>(
      new MovementInputComponent(),
    );
    this._movementInputComponent.on(
      MovementInputComponent.EventTypes.DirectionChanged,
      this._client.onInputDirectionChanged,
      this._client,
    );

    if (!this.game.device.os.desktop) {
      this._touchControlsSceneComponent =
        this.addComponent<TouchControlsSceneComponent>(
          new TouchControlsSceneComponent(
            {
              pinchZoom: false,
              pointerDragCamera: false,
              zoomLimits: { max: 1, min: 0.15 },
            },
            { enable: true },
            false,
          ),
        );

      this._movementInputComponent.addVirtualJoystick(
        this._touchControlsSceneComponent.joystick,
      );
    }

    this.initializeNetwork();
    this.narratorUI.narrate(
      "Welcome to Fruity Memory! Memorize the fruits. The more rounds you win, the more you earn.",
      0,
      "#61f0fa",
    );

    this.onResized();
  }

  public getNakamaUserById(id: string): TNakamaUser | undefined {
    return this._playersNakamaData.find((player) => player.id === id);
  }

  public getPlayerControllers(): {
    player: CurrentPlayerController;
    opponents: Map<string, OpponentPlayerController>;
  } {
    return {
      player: this._playerController,
      opponents: this._opponentControllers,
    };
  }

  public async initializeWorld(
    players: IMemoryPlayerData[],
    userId: string,
    countdown?: number,
  ) {
    this._playerId = userId;
    this._playerCharacterName = await this.getPlayerCharacterName(userId);

    players.forEach((playerData) => {
      // This is not really used in the overlay UI, other than to have the
      // ability to display it (it's a rendering condition in Main.vue)
      dispatchEvent(
        //@ts-ignore
        EnumGameEvents.GameMemoryPlayerJoined,
        {
          id: playerData.id,
        },
      );
      if (playerData.id === this._playerId) {
        this.instantiatePlayerChar(playerData.position);
      } else {
        const isoDestination = IsometricUtils.orthoToIso(
          playerData.destination.x,
          playerData.destination.y,
        );
        this.instantiateOpponentChar(playerData.id, playerData.position).then(
          (controller) => {
            controller.moveToIsoCoords(isoDestination.x, isoDestination.y);
          },
        );
      }
    });

    this._loadingSceneComponent.setText("done");
    this._loadingSceneComponent.hide();

    if (countdown) {
      //@ts-ignore
      dispatchEvent(EnumGameEvents.GameMemoryCountdownStarted);
      this.statusUI.startWaitingPlayers(countdown);
    }
  }

  public onCreated() {
    this._audioComponent.addBackgroundMusic({
      key: "memory_music",
      type: 0,
      path: "/assets/game/mini/memory/audio/memory_music.ogg",
    });

    this._audioComponent.playBackgroundAudio(true);
  }

  public onGameComplete(players: IMemoryPlayerData[]) {
    //@ts-ignore
    dispatchEvent(EnumGameEvents.GameMemoryGameOver);
    this.time.delayedCall(2050, this.showGameCompleteDialog, [players], this);
  }

  public onInputDirectionChanged(direction: Vector2) {
    const playerOrthoPosition = IsometricUtils.isoToOrtho(
      this._playerController.position.x,
      this._playerController.position.y,
    );

    if (direction.equals(Vector2.ZERO)) {
      const target = {
        x: Math.floor(playerOrthoPosition.x) + 0.5,
        y: Math.floor(playerOrthoPosition.y) + 0.5,
      };

      const isoTarget = IsometricUtils.orthoToIso(target.x, target.y);

      this._networkedComponent.sendPlayerMove({
        x: target.x,
        y: target.y,
      });

      this._playerController.moveToIsoCoords(isoTarget.x, isoTarget.y);

      return;
    }

    direction.rotate(-Math.PI / 4);

    const directionLine = new Phaser.Geom.Line(
      playerOrthoPosition.x + 0.5 * direction.x, // Add 0.5 in the direction of the movement to make sure the line only intersects the bounds at one point
      playerOrthoPosition.y + 0.5 * direction.y,
      playerOrthoPosition.x + 1000 * direction.x,
      playerOrthoPosition.y + 1000 * direction.y,
    );

    const boardBounds = new Phaser.Geom.Rectangle(
      0.5,
      0.5,
      this._client.board.numCols * MEMORY_BOARD_TILE_RESOLUTION - 1,
      this._client.board.numRows * MEMORY_BOARD_TILE_RESOLUTION - 1,
    );

    const intersections = Phaser.Geom.Intersects.GetLineToRectangle(
      directionLine,
      boardBounds,
    );

    if (intersections.length === 0) {
      return;
    }

    const target = intersections[0];

    target.x = Math.floor(target.x) + 0.5;
    target.y = Math.floor(target.y) + 0.5;

    const isoTarget = IsometricUtils.orthoToIso(target.x, target.y);

    this._networkedComponent.sendPlayerMove({
      x: target.x,
      y: target.y,
    });

    this._playerController.moveToIsoCoords(isoTarget.x, isoTarget.y);
  }

  public async onPlayerJoined(playerData: IMemoryPlayerData) {
    if (playerData.id !== this._playerId) {
      await this.instantiateOpponentChar(playerData.id, playerData.position);
      this.narratorUI.narrate(
        this._playersNakamaData.find(
          (nakamaData) => nakamaData.id === playerData.id,
        ).username + " joined the game.",
      );
    }
  }

  public async onPlayerLeft(userId: string) {
    const controller = this._opponentControllers.get(userId);

    if (controller) {
      controller.destroy();

      this.narratorUI.narrate(
        this._playersNakamaData.find((nakamaData) => nakamaData.id === userId)
          ?.username + " left the game.",
      );
    }
  }

  public onPreviewUpdate(revealedPositions: Vector2Like[]) {
    this._client.onPreviewUpdate(revealedPositions);
  }

  public onPlayerMoved(userId: string, destination: Vector2Like) {
    if (userId === this._playerId) return;
    else {
      const isoCoords = IsometricUtils.orthoToIso(destination.x, destination.y);
      this._opponentControllers
        .get(userId)
        .moveToIsoCoords(isoCoords.x, isoCoords.y);
    }
  }

  public onRoundChoice(chosenTileId: number) {
    this._client.onChoiceStart(chosenTileId);
  }

  public onRoundIntro(
    board: number[][],
    roundNumber: number,
    numPlayers: number,
  ) {
    this._client.onRoundIntro(board, roundNumber, numPlayers);
  }

  public onRoundPreview(timeUntilChoice: number) {
    this._client.onPreviewStart(timeUntilChoice);
  }

  public onRoundResolve(players: IMemoryPlayerData[], roundNumber: number) {
    this._client.onResolveStart(players, roundNumber);
    this.narratorUI.narrateRoundResult(players, roundNumber);
  }

  public startWaitingForPlayers() {
    //@ts-ignore
    dispatchEvent(EnumGameEvents.GameMemoryCountdownStarted);
    this.statusUI.startWaitingPlayers(
      MEMORY_COUNTDOWN_WAITING_PLAYERS_DURATION,
    );
  }

  private async getPlayerCharacterName(
    userId: string,
  ): Promise<string | undefined> {
    const userData = await Network.Core.core.getUsersData(userId);

    if (
      userData.length > 0 &&
      "metadata" in userData[0] &&
      "activeCharacter" in userData[0].metadata &&
      "name" in userData[0].metadata.activeCharacter
    ) {
      this._playersNakamaData.push(userData[0]);
      return userData[0].metadata.activeCharacter.name;
    }

    return undefined;
  }

  private async initializeNetwork() {
    this._networkedComponent = this.addComponent<MemoryNetworkedComponent>(
      new MemoryNetworkedComponent(this._matchData),
    );
    await this._networkedComponent.connect();

    if (this._matchData.matchId) {
      try {
        await this._networkedComponent.joinMatch(this._matchData.matchId);
      } catch (error) {
        // Can't join the match because it has already started
        if ((error as { code: number; message: string }).code === 5) {
          dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
            sceneKey: "ArcadeScene",
            senderSceneKey: "MemoryGameScene",
          });
          Toast.error(
            "Could not join the match, the game has already started.",
          );
        }
      }
    } else {
      await this._networkedComponent.findAndJoinMatch();
    }
  }

  private instantiatePlayerChar(position: Vector2Like) {
    const isoPos = IsometricUtils.orthoToIso(position.x, position.y);
    this._character = new MemoryGameCharacter(
      this,
      isoPos.x + 1,
      isoPos.y,
      this._playerCharacterName,
    );
    this.add.existing(this._character);

    this.cameras.main.startFollow(this._character);

    const controller = new CurrentPlayerController(this, this._character);

    this.registerController(controller);
    this._playerController = controller;
  }

  private async instantiateOpponentChar(
    userId: string,
    position: Vector2Like,
  ): Promise<OpponentPlayerController> {
    const characterName = await this.getPlayerCharacterName(userId);
    const isoPos = IsometricUtils.orthoToIso(position.x, position.y);
    const character = new MemoryGameCharacter(
      this,
      isoPos.x,
      isoPos.y,
      characterName,
    );
    this.add.existing(character);

    const controller = new OpponentPlayerController(this, character, userId);

    this.registerController(controller);
    this._opponentControllers.set(userId, controller);

    return controller;
  }

  protected override onResized() {
    this.cameras.main.setZoom(Math.min(this.scale.height / 944, 1));
    this.narratorUI.setVisible(this.cameras.main.zoom > 0.8);
  }

  protected onTileClicked(
    tile: Phaser.Tilemaps.Tile,
    tilePosition: TilePosition3D,
  ) {
    this._client.onTileClicked(tile, tilePosition);
  }

  private hookSceneEvents() {
    // this.events.on("update", this.onUpdate, this);
    this.events.on("destroy", this.onDestroy, this);
  }

  private initUI() {
    this._narratorComponent = this.addComponent<NarratorComponent>(
      new NarratorComponent(),
    );

    this.narratorUI = new GameNarrationUI(this, this._narratorComponent);

    this.statusUI = new GamesStatusUI(this);
    this.uiScreens.push(new UIScreen(this).setPosition(-749, 187));
    this.uiScreens.push(new UIScreen(this).setPosition(-240, -69));
    this.uiScreens.push(new UIScreen(this, true).setPosition(402, -69));
    this.uiScreens.push(new UIScreen(this, true).setPosition(913, 187));

    this.uiScreens.forEach((screen) => this.add.existing(screen));
  }

  private onDestroy() {
    // this.events.off("update", this.onUpdate, this);
    this.events.off("destroy", this.onDestroy, this);
  }

  private showGameCompleteDialog(players: IMemoryPlayerData[]) {
    players.sort((a, b) => b.round - a.round);
    //@ts-ignore
    dispatchEvent(EnumGameEvents.GameUiMinigameShowScoreboard, {
      winners: players,
      matchData: this._matchData,
      disablePodium: true,
    });

    dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
  }
}
