import AnimatedIsometricAvatarComponent from "@game/engine/components/entitycomponents/AnimatedIsometricAvatarComponent";
import IsometricCharacter from "@game/engine/IsometricCharacter";
import { AngleToDirection } from "@game/mini/memory/client/navigation/AngleToDirection";
import Vector2 = Phaser.Math.Vector2;
import PlayerSinkAnimation from "@game/mini/memory/client/animations/PlayerSinkAnimation";

export default class AnimatedMemoryAvatarComponent extends AnimatedIsometricAvatarComponent {
  override onOwnerSet() {
    this.owner.add(this._sprite);

    // If the character does anything, make sure we're up to date
    this.owner.on(IsometricCharacter.EventTypes.StateChanged, () => {
      this.playCurrentStateAnimation();
    });

    // If we're moving, make sure to face the right way
    this.owner.on(
      IsometricCharacter.EventTypes.MovedToTile,
      (targetPosition: Vector2, oldPosition: Vector2) => {
        const angle = Phaser.Math.Angle.BetweenPoints(
          oldPosition,
          targetPosition,
        );
        const direction = AngleToDirection(angle);

        this.faceIfNotAlreadyFacing(direction);
      },
    );
  }

  public sink() {
    new PlayerSinkAnimation(this.sprite.scene, this).play();
  }
}
