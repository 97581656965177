import { AssetList } from "@game/engine/components/scenecomponents/DynamicLoadComponent";
import { Scene } from "phaser";

export default class RoomAssetReader {
  public static getMapAssetList(scene: Scene, mapName: string): AssetList {
    const mapData = scene.cache.json.get(mapName + "_tilemap_json");
    const basePath = "/assets/game/mini/room/room_maps/" + mapName + "/";
    const assetList: AssetList = {
      image: [],
      spritesheet: [],
    };

    mapData.layers.forEach((layer) => {
      if (layer.image) {
        assetList.image.push({
          key: layer.image,
          url: basePath + layer.image,
        });
      }
    });

    mapData.tilesets.forEach((tileset) => {
      if (tileset.image !== "Highlight.png") {
        assetList.spritesheet.push({
          key: tileset.image,
          url: basePath + tileset.image,
          frameConfig: {
            frameHeight: tileset.tileheight,
            frameWidth: tileset.tilewidth,
          },
        });
      }
    });

    return assetList;
  }
}
