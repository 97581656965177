import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import MemoryBoard from "@game/mini/memory/client/gameobjects/MemoryBoard";
import MemoryTile from "@game/mini/memory/client/gameobjects/MemoryTile";
import TileSinkAnimation from "@game/mini/memory/client/animations/TileSinkAnimation";

export default class BoardResetAnimation extends Phaser.Events.EventEmitter {
  constructor(protected _scene: MemoryGameScene, private _board: MemoryBoard) {
    super();
  }

  play() {
    const tiles = this._board.tiles;
    let delay: number = 1500;
    let lastAnim: TileSinkAnimation = null;

    for (let i = 0; i < tiles.length; i++) {
      if (tiles[i].defaultPos.y !== tiles[i].y) {
        lastAnim = tiles[i].float(delay);
      }
    }

    lastAnim.once("complete", () => this.emit("complete"), this);
  }

  resetTile(tile: MemoryTile, delay: number) {
    tile.float(delay);
  }
}
