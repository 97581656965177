import {
  MEMORY_TILE_HALF_HEIGHT,
  MEMORY_TILE_HALF_WIDTH,
} from "@common/modules/memory/constants/MemoryConstants";

const TILE_HALF_WIDTH = MEMORY_TILE_HALF_WIDTH;
const TILE_HALF_HEIGHT = MEMORY_TILE_HALF_HEIGHT;

export default class MemoryIsometricUtils {
  public static orthoToIso(
    orthoX: number,
    orthoY: number,
  ): { x: number; y: number } {
    // Not sure why, but in the memory scene we need to add TILE_HALF_WIDTH to the isometric X result
    return {
      x: orthoX * TILE_HALF_WIDTH - orthoY * TILE_HALF_WIDTH + TILE_HALF_WIDTH,
      y: orthoX * TILE_HALF_HEIGHT + orthoY * TILE_HALF_HEIGHT,
    };
  }

  public static isoToOrtho(
    isoX: number,
    isoY: number,
  ): { x: number; y: number } {
    // Same as orthoToIso, we need to substract TILE_HALF_WIDTH from isoX, but this is not the standard conversion
    return {
      x:
        ((isoX - TILE_HALF_WIDTH) / TILE_HALF_WIDTH + isoY / TILE_HALF_HEIGHT) /
        2,
      y:
        (isoY / TILE_HALF_HEIGHT - (isoX - TILE_HALF_WIDTH) / TILE_HALF_WIDTH) /
        2,
    };
  }
}
