import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";

class FurnitureIcon extends Phaser.GameObjects.Container {
  constructor(scene: CustomizableRoomScene, furnitureObject: FurnitureObject) {
    super(scene);

    const worldPos = scene.worldPositionAtTilePosition({
      x: furnitureObject.position.x,
      y: furnitureObject.position.y,
      z: furnitureObject.position.z,
    });

    furnitureObject.tiles.forEach((tile) => {
      tile.x -= worldPos.x;
      tile.y -= worldPos.y;
      this.add(tile);
    });

    this.depth = Number.MAX_VALUE;

    this.setPosition(worldPos.x, worldPos.y);
  }
}

export default class ItemPickUpAnimation extends Phaser.Events.EventEmitter {
  constructor(scene: CustomizableRoomScene, furnitureObject: FurnitureObject) {
    super();

    const icon = new FurnitureIcon(scene, furnitureObject);

    scene.add.existing(icon);
    scene.tweens.add({
      targets: icon,
      x: scene.scale.width / 2 + scene.cameras.main.x - 150,
      duration: 500,
    });

    scene.tweens.add({
      targets: icon,
      scale: 0.1,
      ease: Phaser.Math.Easing.Sine.Out,
      duration: 500,
    });

    scene.tweens.add({
      targets: icon,
      y: icon.y - 150,
      ease: Phaser.Math.Easing.Sine.Out,
      duration: 250,
    });

    scene.tweens
      .add({
        targets: icon,
        y: scene.scale.height / 2 + scene.cameras.main.y + 50,
        ease: Phaser.Math.Easing.Sine.In,
        duration: 250,
        delay: 250,
      })
      .once("complete", () => {
        furnitureObject.destroy();
        icon.destroy();
      });

    icon.depth = Number.MAX_VALUE;
  }
}
