export enum StampWarsOpCodes {
  // Must start at 1
  OnCountdownUpdated = 1,
  OnGameComplete,
  OnGameMovedToPhase,
  OnInitializeWorld,
  OnPlayerJoined,
  OnPlayerLeave,
  OnPlayerMove,
  OnTick,
}
