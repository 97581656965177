export enum MemoryOpCodes {
    // Must start at 1
    OnBoardPreview = 1,
    OnGameComplete,
    OnGameMovedToPhase,
    OnInitializeWorld,
    OnPlayerJoined,
    OnPlayerLeave,
    OnPlayerMove,
    OnRoundChoice,
    OnRoundIntro,
    OnRoundPreview,
    OnRoundResolve
}