import AnimatedMemoryAvatarComponent from "@game/mini/memory/client/components/AnimatedMemoryAvatarComponent";
import { Direction } from "@game/engine/navigation/Direction";
import { Scene } from "phaser";

const SINK_DURATION = 1500;

export default class PlayerSinkAnimation extends Phaser.Events.EventEmitter {
  private _avatar: AnimatedMemoryAvatarComponent;
  private _waterEffect: Phaser.GameObjects.Sprite;
  private _scene: Scene;

  constructor(scene: Scene, avatar: AnimatedMemoryAvatarComponent) {
    super();

    this._avatar = avatar;
    this._scene = scene;
    this._waterEffect = new Phaser.GameObjects.Sprite(
      scene,
      0,
      0,
      "animations_atlas",
      "splash_avatar_00",
    );

    this._waterEffect.anims.create({
      key: "splash",
      frames: scene.anims.generateFrameNames("animations_atlas", {
        prefix: "splash_avatar_",
        end: 7,
        zeroPad: 2,
      }),
      repeat: -1,
    });
  }

  play() {
    const cropRect = new Phaser.Geom.Rectangle(
      0,
      0,
      this._avatar.sprite.width,
      this._avatar.sprite.height,
    );

    this._avatar.faceIfNotAlreadyFacing(Direction.SouthEast);

    this._scene.tweens.addCounter({
      from: 1,
      to: 0,
      duration: SINK_DURATION,
      ease: "Sine.Out",
      onUpdate: (tween) => {
        cropRect.height = this._avatar.sprite.height * tween.getValue();
        this._avatar.sprite.setCrop(cropRect);
        this._avatar.sprite.y = this._avatar.sprite.height - cropRect.height;
      },
    });

    this._scene.add.existing(this._waterEffect);
    this._avatar.sprite.parentContainer.add(this._waterEffect);
    this._waterEffect.setPosition(
      this._avatar.sprite.x,
      this._avatar.sprite.y + 10,
    );
    this._waterEffect.anims.play("splash");

    this._scene.tweens.add({
      targets: this._waterEffect,
      duration: SINK_DURATION / 2,
      scaleX: 0,
      delay: SINK_DURATION * 0.6,
    });
  }
}
