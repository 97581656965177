import FurniturePlacer from "@game/mini/room/FurniturePlacer";
import {
  dispatchEvent,
  EnumApplicationEvents,
  EnumGameEvents,
  EnumUiEvents,
  handleEvent,
  TEventHandler,
} from "@shared/Events";
import ItemPickUpAnimation from "@game/mini/room/animations/ItemPickUpAnimation";
import FurnitureObject from "@game/engine/components/scenecomponents/furniture/furniture-objects/FurnitureObject";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import RoomAssetReader from "@game/mini/room/utils/RoomAssetReader";
import { AssetList } from "@game/engine/components/scenecomponents/DynamicLoadComponent";
import RemotePlayerCharacter from "../lobby/gameobjects/RemotePlayerCharacter";
import { IPublicSpacePlayer } from "@common/modules/publicspace/interfaces/IPublicSpacePlayer";
import { TInventoryTile, TRoomRole } from "@shared/types";
import { EnumNetworkEvents } from "@shared/enums";
import { TFurniturePlacement } from "@game/engine/components/scenecomponents/furniture/FurnitureComponent";
import { RoomNetworkedComponent } from "@game/mini/room/components/scenecomponents/RoomNetworkedComponent";
import { IInteractiveFurnitureUseResponse } from "@common/modules/rooms/interfaces/FurnitureOperations";
import { IInteractiveFurnitureData } from "@common/modules/rooms/interfaces/IInteractiveFurnitureData";
import { IPlayerPermissionsChangeRequest } from "@common/modules/rooms/interfaces/PlayerOperations";
import { Toast } from "@engine/notifications/Toast";
import { useGameState } from "@overlay/store/gameStateStore";
import { NFTHelpers } from "@shared/nft";
import { TilePosition3D } from "@engine/navigation/Pathfinder";
import { PublicSpaceInputEvent } from "@engine/components/scenecomponents/PublicSpaceInputComponent";
import Rectangle = Phaser.Geom.Rectangle;
import Pointer = Phaser.Input.Pointer;
import TimerEvent = Phaser.Time.TimerEvent;
import Tileset = Phaser.Tilemaps.Tileset;
import NFT = NFTHelpers.NFT;
import Tile = Phaser.Tilemaps.Tile;

// TODO: - Move these types into the shared submodule and remove them from both client and server
export type RoomData = {
  map: string;
  permissions: { [key: string]: TRoomRole }[];
  furniture: Array<TFurniturePlacement>;
};

const TMP_FLOOR_EDITABLE_MAPS = [
  "basic_room",
  "comfortable_studio",
  "extravagant_studio",
  "starter_room",
  "two_person_room",
];

export default class CustomizableRoomScene extends PublicSpaceGameScene {
  public static SceneKey: string = "CustomizableRoomScene";

  public get localPlayerId(): string {
    return this._localPlayerId;
  }

  public get playerRole(): TRoomRole {
    return this._playerRole;
  }

  public get roomId(): string {
    return this._roomId;
  }

  //TODO (Mario): Clean up the input when there's time. The scene and furniture should not be
  // taking care of managing this
  private _clickEvent: TimerEvent;
  private _defaultFloors: number[][][] = [];
  private _roomAssetList: AssetList;
  private _furnitureList: FurnitureObject[] = [];
  private _furniturePlacer: FurniturePlacer;
  private _playerRole: TRoomRole;
  private _roomData: RoomData;
  private _roomId: string;
  private _uiEventHandlers: TEventHandler[] = [];

  constructor() {
    super(
      {
        key: "CustomizableRoomScene",
      },
      undefined,
      undefined,
      [],
      [],
      [],
      [], //Animation atlas
    );
  }

  public addFloorTileFromBackend(placement: TFurniturePlacement) {
    const nft = NFT.fromAddress(placement.id.split("_")[0]);
    const layer = this.map.layers.find(
      (layer) => layer.name === "floor " + placement.position.z,
    );

    const tile = layer!.tilemapLayer.getTileAt(
      placement.position.x,
      placement.position.y,
    );

    tile.index =
      Number(nft.texture?.substring(11)) +
      this._map.tilesets[this._map.tilesets.length - 1].firstgid;

    this._furnitureComponent.addFloorTile(placement);
  }

  public async addFurnitureFromBackend(placement: TFurniturePlacement) {
    if (placement.name === "floor_tile") {
      return this.addFloorTileFromBackend(placement);
    }

    await this.loadFurnitureAssets(placement.name);

    const furnitureObject = this._furnitureComponent.addFurniture(placement);

    this.setFurnitureFloorAsBlocked(furnitureObject, true);
    this.movePlayerIfBlockedByFurniture(furnitureObject);
  }

  public override getBounds(): Rectangle {
    return new Rectangle(
      -this.scale.width * 2,
      -this.scale.height * 2,
      Number.MAX_VALUE,
      Number.MAX_VALUE,
    );
  }

  public getNavigatableTileAt(tilePosition: TilePosition3D): Tile {
    return this._navigatableLayers[tilePosition.z].getTileAt(
      tilePosition.x,
      tilePosition.y,
    );
  }

  public override getNetworkMatchIdentifier(): string {
    return "room";
  }

  public initializeWorld(otherPlayers: IPublicSpacePlayer[]) {
    super.initializeWorld(otherPlayers);

    this._playerRole = this._roomData.permissions[this._localPlayerId]
      ? this._roomData.permissions[this._localPlayerId]
      : "guest";

    this._furnitureComponent.setPlayerRole(this._playerRole);
  }

  public moveFurnitureFromBackend(placement: TFurniturePlacement) {
    const movingObject = this._furnitureComponent.moveFurniture(placement);

    if (movingObject !== undefined) {
      this.movePlayerIfBlockedByFurniture(movingObject);
    }
  }

  public removeFurnitureFromBackend(furnitureId: string) {
    this._furnitureComponent.removeFurniture(furnitureId, true);
  }

  public onDoorStateChanged(door: FurnitureObject, open: boolean) {
    this.setFurnitureFloorAsBlocked(door, !open);
  }

  public onFurnitureActivated(response: IInteractiveFurnitureUseResponse) {
    if (!response.success) return;

    this._furnitureComponent.onFurnitureActivated(response);
  }

  public onFurnitureInit(interactiveFurniture: {
    [key: string]: IInteractiveFurnitureData;
  }) {
    this._furnitureComponent.onFurnitureInit(interactiveFurniture);
  }

  public onKick(author: string) {
    const authorName = this.getPlayerById(author).userData.username;
    const gameState = useGameState();
    dispatchEvent(EnumApplicationEvents.ApplicationStartMinigame, {
      sceneKey: "LobbyScene",
      senderSceneKey: gameState.activeMinigame.key,
    });

    Toast.info(`You were kicked out of the room by ${authorName}`);
  }

  public onPlayerPermissionsChanged(
    permissionsChange: IPlayerPermissionsChangeRequest,
  ) {
    if (permissionsChange.playerId === this.localPlayerId) {
      this._playerRole = permissionsChange.role;
    }

    this._roomData.permissions[permissionsChange.playerId] =
      permissionsChange.role;
  }

  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  public onShutdown() {
    this.setEventsEnabled(false);
    this.unloadAssets();

    this._furnitureList.forEach((furnitureObject) => furnitureObject.destroy());
    this._furnitureList = [];

    this._furniturePlacer.destroy();

    super.onShutdown();
  }

  public previewFloorTile(tile: Tile, floorTileValue: number) {
    tile.index =
      this.map.tilesets[this.map.tilesets.length - 1].firstgid + floorTileValue;
  }

  protected async preload() {
    super.preload();

    this._roomAssetList = RoomAssetReader.getMapAssetList(
      this,
      this._tilemapKey,
    );

    this.load.tilemapTiledJSON(
      this._tilemapKey + "_tilemap",
      "/assets/game/mini/room/room_maps/" +
        this._roomData.map +
        "/" +
        this._roomData.map +
        ".tmj",
    );

    this.load.image("floor_tiles", "/assets/game/mini/room/floor_tiles.png");

    this._roomAssetList.image.forEach((imageData) => {
      this.load.image(imageData.key, imageData.url);
    });

    this._roomAssetList.spritesheet.forEach((spritesheetData, index) => {
      this.load.image(
        this._roomData.map +
          "_" +
          spritesheetData.key.split(".")[0] +
          "_tileset_" +
          index,
        spritesheetData.url,
      );
      this.load.spritesheet(
        spritesheetData.key.split(".")[0] + "_spritesheet",
        spritesheetData.url,
        spritesheetData.frameConfig,
      );
    });

    this.load.image("selector", "/assets/game/selector.png");
    this.load.atlas(
      "ui_atlas",
      "/assets/game/mini/room/ui_atlas.png",
      "/assets/game/mini/room/ui_atlas.json",
    );

    this.loadFurniture();
  }

  protected async create() {
    super.create();

    if (TMP_FLOOR_EDITABLE_MAPS.includes(this._roomData.map)) {
      this.addCustomFloorTilesTileset();
    }

    this.loadSavedFurniture(this._roomData.furniture).finally();

    this._publicSpaceInputComponent.addInputListener(
      PublicSpaceInputEvent.TilePressed,
      this.interactWithFurnitureOnTile,
      this,
      1,
    );

    this._furniturePlacer = new FurniturePlacer(this, this._furnitureComponent);

    this._publicSpaceInputComponent.addInputListener(
      PublicSpaceInputEvent.TilePressed,
      this._furniturePlacer.onTilePressed,
      this._furniturePlacer,
      2,
    );

    this._publicSpaceInputComponent.addInputListener(
      PublicSpaceInputEvent.TileHovered,
      this._furniturePlacer.onTileHovered,
      this._furniturePlacer,
      2,
    );

    if (TMP_FLOOR_EDITABLE_MAPS.includes(this._roomData.map)) {
      this._publicSpaceInputComponent.addInputListener(
        this.game.device.os.desktop
          ? PublicSpaceInputEvent.TileRightClicked
          : PublicSpaceInputEvent.TileLongPress,
        this.removeFloorTileAtTilePosition,
        this,
        0,
      );
    }

    this._publicSpaceInputComponent.addInputListener(
      this.game.device.os.desktop
        ? PublicSpaceInputEvent.TileRightClicked
        : PublicSpaceInputEvent.TileLongPress,
      this.editFurnitureOnTilePosition,
      this,
      1,
    );

    this.setEventsEnabled(true);

    dispatchEvent(EnumUiEvents.UiNetworkRequestInventory);
    dispatchEvent(EnumNetworkEvents.NetworkRequestUserActiveNfts);

    this.addUIEventHandlers();
  }

  protected init(data?: { [key: string]: any }) {
    super.init(data);
    this._roomId = data.roomId;
    this._roomData = data.roomData;
    this._tilemapKey = this._roomData.map;
  }

  private addCustomFloorTilesTileset() {
    const previousTileset = this.map.tilesets[this.map.tilesets.length - 1];
    const tileset = new Tileset(
      "floor_tiles_tileset",
      previousTileset.firstgid + previousTileset.total,
      128,
      96,
    );

    this.map.tilesets.push(tileset);
    this.map.addTilesetImage("floor_tiles_tileset", "floor_tiles");

    this.map.layers.forEach((layerData) => {
      const layer = layerData.tilemapLayer;

      for (
        let i = tileset.firstgid;
        i < tileset.firstgid + tileset.total;
        i++
      ) {
        layer.gidMap[i] = tileset;
      }

      if (layerData.name.substring(0, 6) === "floor ") {
        for (let i = 0; i < layerData.data.length; i++) {
          for (let j = 0; j < layerData.data[i].length; j++) {
            const floorIndex = Number(layer.name.substring(6));
            if (!this._defaultFloors[floorIndex]) {
              this._defaultFloors[floorIndex] = new Array(
                layerData.data.length,
              );
            }
            if (!this._defaultFloors[floorIndex][i]) {
              this._defaultFloors[floorIndex][i] = new Array(
                layerData.data[i].length,
              );
            }
            this._defaultFloors[floorIndex][i][j] = layerData.data[i][j].index;
          }
        }
      }
    });
  }

  private addUIEventHandlers() {
    this._uiEventHandlers.push(
      handleEvent(
        EnumUiEvents.UiPlaceFurniture,
        async (itemData: TInventoryTile) => {
          const name = itemData.id;
          const nftData = NFT.fromAddress(itemData.address!);

          if (nftData.texture?.includes("floor_tile_")) {
            if (!TMP_FLOOR_EDITABLE_MAPS.includes(this._roomData.map)) {
              return Toast.error(
                "Floor tiles cannot be used in this type of room yet",
              );
            }
            this._furniturePlacer.startPlacingFloorTile(
              nftData.texture?.substring(11),
              itemData.address + "_" + itemData.tokenId,
            );
          } else {
            await this.loadFurnitureAssets(name);

            this._furniturePlacer.startPlacing(
              name,
              itemData.address + "_" + itemData.tokenId,
            );
          }
        },
      ),
    );

    this._uiEventHandlers.push(
      handleEvent(EnumUiEvents.UiKickPlayer, (playerId: string) => {
        (this._networkedComponent as RoomNetworkedComponent).sendPlayerKick(
          playerId,
        );
      }),
    );

    this._uiEventHandlers.push(
      handleEvent(
        EnumUiEvents.UiChangePlayerPermissions,
        (data: { playerId: string; role: TRoomRole }) => {
          (
            this._networkedComponent as RoomNetworkedComponent
          ).sendRoleChangeRequest(data);
        },
      ),
    );
  }

  private editFurnitureOnTilePosition(tilePosition: TilePosition3D): boolean {
    const furniture =
      this._furnitureComponent.getFurnitureObjectAt(tilePosition);

    if (furniture) {
      this._furniturePlacer.onEditFurniture(furniture);
      return true;
    }

    return false;
  }

  private async loadSavedFurniture(furnitureList: TFurniturePlacement[]) {
    furnitureList.forEach((savedFurnitureData) => {
      if (savedFurnitureData.name === "floor_tile") {
        this.addFloorTileFromBackend(savedFurnitureData);
      } else {
        this.addFurnitureFromBackend(savedFurnitureData);
      }
    });

    this.sortOrderables();
  }

  protected override loadTilesets() {
    this._tilesets = [];
    this._roomAssetList.spritesheet.forEach((spritesheetData, index) => {
      this._tilesets.push(
        this._map.addTilesetImage(
          spritesheetData.key.split(".")[0],
          this._roomData.map +
            "_" +
            spritesheetData.key.split(".")[0] +
            "_tileset_" +
            index,
        ),
      );
    });
  }

  protected override loadFurniture() {
    this._roomData.furniture.forEach((furnitureData) => {
      this.load.atlas(
        furnitureData.name,
        "/assets/game/mini/room/furniture/" +
          furnitureData.name +
          "/" +
          furnitureData.name +
          ".png",
        "/assets/game/mini/room/furniture/" +
          furnitureData.name +
          "/" +
          furnitureData.name +
          ".json",
      );
    });
  }

  private async onFloorTilePlaced(id: string, position: TilePosition3D) {
    const network = this._networkedComponent as RoomNetworkedComponent;

    this._furnitureComponent.addFloorTile({
      direction: 0,
      id: id,
      name: "floor_tile",
      position: position,
    });

    network.sendFurnitureAdded({
      id: id,
      name: "floor_tile",
      position: position,
      direction: 0,
    });
    dispatchEvent(EnumGameEvents.RoomEditorFurniturePlaced);
  }

  private async onFurniturePlaced(furnitureObject: FurnitureObject) {
    const network = this._networkedComponent as RoomNetworkedComponent;

    this._furnitureComponent.addExistingFurniture(furnitureObject);
    this.setFurnitureFloorAsBlocked(furnitureObject, true);

    network.sendFurnitureAdded({
      id: furnitureObject.id,
      name: furnitureObject.name,
      position: furnitureObject.position,
      direction: furnitureObject.direction,
    });
    dispatchEvent(EnumGameEvents.RoomEditorFurniturePlaced);
  }

  private onFurnitureMoved(
    furnitureObject: FurnitureObject,
    movedPreview: FurnitureObject,
  ) {
    const network = this._networkedComponent as RoomNetworkedComponent;

    network.sendFurnitureMoved({
      id: furnitureObject.id,
      name: furnitureObject.name,
      position: movedPreview.position,
      direction: movedPreview.direction,
    });

    this.setFurnitureFloorAsBlocked(furnitureObject, false);
    furnitureObject.setPosition(movedPreview.position);
    this.setFurnitureFloorAsBlocked(furnitureObject, true);

    this.movePlayerIfBlockedByFurniture(furnitureObject);
  }

  private onFurniturePickedUp(furnitureObject: FurnitureObject) {
    const network = this._networkedComponent as RoomNetworkedComponent;

    this._furnitureComponent.removeFurniture(furnitureObject.id);

    network.sendFurnitureRemoved({
      id: furnitureObject.id,
      name: furnitureObject.name,
      position: furnitureObject.position,
      direction: furnitureObject.direction,
    });

    new ItemPickUpAnimation(this, furnitureObject);

    this.setFurnitureFloorAsBlocked(furnitureObject, false);
  }

  private onFurnitureRotated(furnitureObject: FurnitureObject) {
    const network = this._networkedComponent as RoomNetworkedComponent;

    network.sendFurnitureMoved({
      id: furnitureObject.id,
      name: furnitureObject.name,
      position: furnitureObject.position,
      direction: furnitureObject.direction,
    });

    this.movePlayerIfBlockedByFurniture(furnitureObject);
  }

  private interactWithFurnitureOnTile(tilePosition: TilePosition3D): boolean {
    if (this._furniturePlacer.editorUI.inputCaptured) {
      // Stop any other action from being performed with this click
      return true;
    }

    const furniture =
      this._furnitureComponent.getFurnitureObjectAt(tilePosition);

    if (furniture && furniture.interactive && this._playerRole !== "guest") {
      this.movePlayerToInteractWithFurniture(furniture);
      return true;
    }

    return false;
  }

  private removeFloorTile(tile: Tile, defaultTileIndex: number) {
    const position = {
      x: tile.x,
      y: tile.y,
      z: Number(tile.layer.name.split("floor ")[1]),
    };
    const furniture =
      this._furnitureComponent.getFloorTileFurnitureObjectAt(position);
    this._furnitureComponent.removeFloorTile(tile, position, defaultTileIndex);

    const network = this._networkedComponent as RoomNetworkedComponent;
    network.sendFurnitureRemoved({
      id: furniture.id,
      name: furniture.name,
      position: furniture.position,
      direction: furniture.direction,
    });
  }

  private removeFloorTileAtTilePosition(tilePosition: TilePosition3D): boolean {
    if (this._playerRole !== "owner" && this._playerRole !== "vip") {
      return false;
    }

    const navigatableTile = this.getNavigatableTileAt(tilePosition);
    const floorTile =
      this._furniturePlacer.getFloorTileFromNavigatableTile(navigatableTile);
    const defaultTileIndex =
      this._defaultFloors[Number(navigatableTile.layer.name.substring(12))][
        navigatableTile.y
      ][navigatableTile.x]; // It's y,x because that's how Tiled saves the tiles

    if (defaultTileIndex !== floorTile.index) {
      this.removeFloorTile(floorTile, defaultTileIndex);
      return true;
    }

    return false;
  }

  private removeFloorTileFromBackend(tilePosition: TilePosition3D) {
    const navigatableTile = this.getNavigatableTileAt(tilePosition);
    const floorTile =
      this._furniturePlacer.getFloorTileFromNavigatableTile(navigatableTile);
    const defaultTileIndex =
      this._defaultFloors[Number(navigatableTile.layer.name.substring(12))][
        navigatableTile.y
      ][navigatableTile.x]; // It's y,x because that's how Tiled saves the tiles

    if (defaultTileIndex !== floorTile.index) {
      this.removeFloorTile(floorTile, defaultTileIndex);
    }
  }

  private setEventsEnabled(enabled: boolean) {
    const eventHook = enabled ? "on" : "off";

    this._furniturePlacer[eventHook](
      "floor_tile_placed",
      this.onFloorTilePlaced,
      this,
    );
    this._furniturePlacer[eventHook](
      "furniture_placed",
      this.onFurniturePlaced,
      this,
    );
    this._furniturePlacer[eventHook](
      "furniture_moved",
      this.onFurnitureMoved,
      this,
    );
    this._furniturePlacer[eventHook](
      "furniture_rotated",
      this.onFurnitureRotated,
      this,
    );
    this._furniturePlacer[eventHook](
      "furniture_picked_up",
      this.onFurniturePickedUp,
      this,
    );

    for (const eventHandler of this._uiEventHandlers) {
      eventHandler.destroy();
    }
  }

  protected preUpdate(time, deltaTime) {
    super.preUpdate(time, deltaTime);
    this.sortOrderables();
  }

  private unloadAssets() {
    const assetList: AssetList = { image: [], spritesheet: [] };

    this._roomAssetList.image.forEach((imageData) => {
      assetList.image.push({ key: imageData.key, url: imageData.url });
    });

    this._roomAssetList.spritesheet.forEach((spritesheetData, index) => {
      assetList.image.push({
        key:
          this._roomData.map +
          "_" +
          spritesheetData.key.split(".")[0] +
          "_tileset_" +
          index,
        url: spritesheetData.url,
      });

      assetList.spritesheet.push({
        key: spritesheetData.key.split(".")[0] + "_spritesheet",
        url: spritesheetData.url,
      });
    });

    this._dynamicLoadComponent.unloadAssetList(assetList);
  }
}
