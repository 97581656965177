import ArcadeScene from "@game/mini/arcade/ArcadeScene";
import ShopScene from "@game/mini/shop/ShopScene";
import OrderableNonPlayerCharacter from "@game/engine/gameobjects/OrderableNonPlayerCharacter";
import { ISceneSwitchingData } from "@game/engine/interfaces/ISceneSwitchingData";
import OrderableImage from "@game/engine/objects/OrderableImage";
import PublicSpaceGameScene from "@game/engine/scenes/PublicSpaceGameScene";
import { stretch } from "@game/engine/utilities/AnimationHelper";
import { dispatchEvent, EnumGameEvents } from "@shared/Events";
import RemotePlayerCharacter from "./gameobjects/RemotePlayerCharacter";
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import MoonbeamScene from "../moonbeam/MoonbeamScene";
import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import PlinkoScene from "../plinko/PlinkoScene";
import MightyMinesScene from "../mighty-mines/MightyMinesScene";
import Rectangle = Phaser.Geom.Rectangle;

class AudioKeys {
  public static Theme = "lobbytheme";
}

export default class LobbyScene extends PublicSpaceGameScene {
  public static SceneKey: string = "LobbyScene";

  constructor() {
    let {
      VITE_SERVER_GAME_OFFLINE_MODE: offlineMode,
      VITE_SERVER_GAME_DEBUG_MODE: debugMode,
    } = import.meta.env;
    debugMode = debugMode === "true";
    offlineMode = offlineMode === "true";
    super(
      {
        key: LobbyScene.SceneKey,
        debugMode: false,
        offlineMode,
      },
      "/assets/game/mini/lobby/",
      "lobby.tmj",
      [
        "floor-tilesheet.png",
        "rounded_platform.png",
        "Furniture/AquaCarpet/aqua_carpet.png",
        "Furniture/BarDrain/bar_drain.png",
        "Furniture/BarTable/bar_table.png",
        "Furniture/BarSeat/bar_seat.png",
        "Furniture/BarCounter/bar_counter.png",
        "Furniture/BeigeCarpet/beige_carpet.png",
        "Furniture/Stool/stool.png",
        "Furniture/Armchair/armchair.png",
        "Furniture/LargeModernCarpet/large_modern_carpet.png",
        "Furniture/FrontDesk/front_desk.png",
        "Furniture/BarDecorations/bar_decorations.png",
        "Furniture/BarDrain/bar_drain.png",
        "Furniture/BitCoinSofa/bit_coin_sofa.png",
        "Furniture/PalmLeafPlant/palm_leaf_plant.png",
        "Furniture/SideTable/side_table.png",
        "Furniture/BarFridge/bar_fridge.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_1.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_2.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_3.png",
        "tilesheets/Highlight.png",
      ], // Tilesets
      [
        "images/Arcade_Left.png",
        "images/Arcade_Wall_Behind_Left.png",
        "images/Arcade_Wall_Behind_Left_Top.png",
        "images/ArcadeMachine.png",
        "images/ArcadeMachine_Top.png",
        "images/CarpetModernLarge.png",
        "images/CarpetModernSmall.png",
        "images/CarpetUnderSittingCornerLarge.png",
        "images/CarpetUnderSittingCornerSmall.png",
        "images/ElevatorCarpet.png",
        "images/LobbyFountain.png",
        "images/LobbyFountain_Top.png",
        "images/PalmTree.png",
        "images/PalmTree_Top.png",
        "images/ShopDisplayTable_Left.png",
        "images/ShopDisplayTable_Right.png",
        "images/ShopFront_Left.png",
        "images/ShopFront_Right_Top.png",
        "images/ShopFront_Right.png",
        "images/ShopFront_Top.png",
        "images/ShopFrontPillar.png",
        "images/ShopFrontPillar_Repeating.png",
        "images/ShopFrontWindow_Left_Behind.png",
        "images/ShopFrontWindow_Right.png",
        "FullImages/BitWall.png",
        "ElevatorFixed/ElevatorLeft.png",
        "ElevatorFixed/ElevatorRight.png",
        "FullImages/MinigameEntrance.png",
        "FullImages/MinigameInnerWall.png",
        "FullImages/ShopBack.png",
        "FullImages/ShopFront.png",
        "FullImages/tallDivider.png",
        "FullImages/WindowWall.png",
        "FullImages/FurniSaleLeft.png",
        "FullImages/FurniSaleRight_2.png",
        "FullImages/gumball.png",
        "Furniture/PalmTree/main_lobby_palm/main_lobby_palm.png",
        "Furniture/PalmTree/main_lobby_palm/main_lobby_palm_shadow.png",
        "walls/additional_wall.png",
        "minigames_animated_sign/minigames_animated_sign_0_7_1_0/000.png",
        "walls/window_wall_xmas.png",
        "fogWall/fog_wall_tileable_a.png",
        "fogWall/fog_wall_tileable_b.png",
      ], // Image layers
      [
        "Furniture/BarFridge/bar_fridge.png",
        "Furniture/Armchair/armchair.png",
        "Furniture/BarCounter/bar_counter.png",
        "Furniture/BarDecorations/bar_decorations.png",
        "Furniture/BarSeat/bar_seat.png",
        "Furniture/BarTable/bar_table.png",
        "Furniture/BitCoinSofa/bit_coin_sofa.png",
        "Furniture/FrontDesk/front_desk.png",
        "Furniture/PalmLeafPlant/palm_leaf_plant.png",
        "Furniture/SideTable/side_table.png",
        "Furniture/Stool/stool.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_1.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_2.png",
        "Furniture/ThreeSeatSofa/three_seat_sofa_3.png",
      ], // Spritesheets
      [
        "minigamesign/Sign/minigamesign_data.json",
        "minigamesign/Highlight/minigamesign_data.json",
        "shoplights/shoplights.json",
        "christmasTree/christmastree/christmastree.json",
      ], //Animation Atlas
      true,
    );

    // this._audioComponent.addSoundEffects();
  }

  // MARKTWAIN - make this correct
  public override getBounds(): Rectangle {
    return new Rectangle(-10000, -10000, 20000, 20000);
  }

  public override getNetworkMatchIdentifier(): string {
    return "lobby";
  }

  public override onInteractableImageInteract(image: OrderableImage) {
    if ("interact" in image.interaction) {
      stretch(this.tweens, image.image);
    }
  }

  public override onNPCHover(npc: OrderableNonPlayerCharacter) {
    if ("hover" in npc.interaction) {
      npc.lookAt(this._localPlayer.tilePosition);
      npc.say(npc.interaction["hover"]);
    }
  }

  public override onNPCInteract(npc: OrderableNonPlayerCharacter) {
    if ("do" in npc.interaction) {
      npc.twitch();
      dispatchEvent(EnumGameEvents.GameUiNpcInteraction, {
        ...npc.interaction,
        name: npc.characterName,
      });
    }

    if ("interact" in npc.interaction) {
      npc.twitch();
      npc.say(npc.interaction["interact"]);
    }
  }

  public override onRemotePlayerHover(player: RemotePlayerCharacter): void {}

  public override onRemotePlayerInteract(player: RemotePlayerCharacter): void {
    dispatchEvent(EnumGameEvents.GameUiPlayerInteraction, player.userData);
  }

  public override onRemotePlayerOut(player: RemotePlayerCharacter): void {}

  protected override async init(data?: object) {
    await super.init(data);

    // Check the origin and adjust the spawn point accordingly
    if (data && "origin" in data) {
      const { origin } = <ISceneSwitchingData>data;
      const validOrigins = [
        ArcadeScene.SceneKey,
        CryptoBomberScene.SceneKey,
        PlinkoScene.SceneKey,
        MightyMinesScene.SceneKey,
        MemoryGameScene.SceneKey,
        MoonbeamScene.SceneKey,
        ShopScene.SceneKey,
      ];
      if (validOrigins.indexOf(origin) > -1) {
        this.setSpawnLocation(origin);
      }
    }
  }

  protected override onCreated() {
    super.onCreated();

    this._audioComponent.addBackgroundMusic({
      key: AudioKeys.Theme,
      path: "/assets/game/mini/lobby/audio/theme.ogg",
    });
    this._audioComponent.playBackgroundAudio();

    // this._audioComponent.pauseOnBlur = false;
    // this._audioComponent.masterVolume = 0.32;
    // this._audioComponent.soundEffectVolume = 1.0;
  }

  protected override preload() {
    // Adding audio for loading
    // TODO make this work for mobile
    this.load.audio(AudioKeys.Theme, "/assets/game/mini/lobby/audio/theme.ogg");

    super.preload();
  }
}
