import { NetworkedComponent } from "@engine/components/scenecomponents/v2/NetworkedComponent";
import { MatchData } from "@heroiclabs/nakama-js";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import { dispatchEvent } from "@shared/Events";
import { EnumNetworkEvents } from "@overlay/enums/Events";
import StampWarsScene from "@game/mini/stamp_wars/StampWarsScene";
import { StampWarsOpCodes } from "@common/modules/stampwars/enums/StampWarsOpCodes";
import { StampWarsGamePhase } from "@common/modules/stampwars/enums/StampWarsGamePhase";
import { IStampWarsPlayerData } from "@common/modules/stampwars/interfaces/IStampWarsPlayerData";

interface IOnCountdownData {
  secondsLeft: number;
}

export interface IOnGameCompleteData {
  players: IStampWarsPlayerData[];
  tilesPerTeam: number[];
}

interface IOnInitializedWorldData {
  players: IStampWarsPlayerData[];
  userId: string;
  countdown?: number;
}

export interface IOnTickData {
  players: {
    collided: boolean;
    filledAreas: Vector2Like[][];
    id: string;
    position: Vector2Like;
    target: Vector2Like;
  }[];
  timeLeft: number;
}

interface IOnPlayerLeaveData {
  userId: string;
}

export class StampWarsNetworkedComponent extends NetworkedComponent {
  protected declare _scene: StampWarsScene;

  constructor(matchData: { [key: string]: string }) {
    super("stampwars", matchData);
  }

  public override async findAndJoinMatch(): Promise<boolean> {
    return super.findAndJoinMatch(this._module, this._matchData);
  }

  public sendPlayerMove(destination: Vector2Like) {
    this.sendMatchState(StampWarsOpCodes.OnPlayerMove, destination);
  }

  protected override onMatchData(matchData: MatchData) {
    switch (matchData.op_code) {
      case StampWarsOpCodes.OnCountdownUpdated:
        const { secondsLeft } = this.decodeAndParseMatchData<IOnCountdownData>(
          matchData.data,
        );
        this._scene.onServerCountdownUpdate(secondsLeft);
        break;
      case StampWarsOpCodes.OnGameMovedToPhase: {
        const phase = <StampWarsGamePhase>(
          parseInt(this.decodeMatchData(matchData.data))
        );
        switch (phase) {
          case StampWarsGamePhase.WaitingForMaximumNumberOfPlayers:
            this._scene.startWaitingForPlayers();
            break;

          case StampWarsGamePhase.Playing:
            dispatchEvent(EnumNetworkEvents.NetworkRequestUserData);
            this._scene.onGameStarted();
            break;
        }
        break;
      }

      case StampWarsOpCodes.OnInitializeWorld:
        const { players, userId, countdown } =
          this.decodeAndParseMatchData<IOnInitializedWorldData>(matchData.data);

        this._scene.initializeWorld(players, userId, countdown);
        break;
      case StampWarsOpCodes.OnPlayerJoined:
        const playerData = this.decodeAndParseMatchData<IStampWarsPlayerData>(
          matchData.data,
        );
        this._scene.onPlayerJoined(playerData);
        break;
      case StampWarsOpCodes.OnPlayerLeave:
        const onLeaveData = this.decodeAndParseMatchData<IOnPlayerLeaveData>(
          matchData.data,
        );
        this._scene.onPlayerLeft(onLeaveData.userId);
        break;
      case StampWarsOpCodes.OnTick:
        const onTickData = this.decodeAndParseMatchData<IOnTickData>(
          matchData.data,
        );
        this._scene.onServerTick(onTickData);
        break;
      case StampWarsOpCodes.OnGameComplete:
        const completeData = this.decodeAndParseMatchData<IOnGameCompleteData>(
          matchData.data,
        );
        this._scene.onGameComplete(completeData);
        break;
    }
  }
}
