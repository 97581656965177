import { Scene } from "phaser";

export default class MainLabel extends Phaser.GameObjects.Text {
  constructor(scene: Scene) {
    super(scene, 0, 0, "TEST LABEL", {
      fontFamily: "Depixel",
      fontSize: "40px",
      stroke: "#000",
      strokeThickness: 8,
      color: "#ffffff",
    });

    this.setOrigin(0.5);
    this.setScrollFactor(0);
    this.setDepth(Number.MAX_VALUE);

    this.setVisible(false);

    this.onSceneResize();
  }

  public onSceneResize() {
    this.setScale(this.scene.cameras.main.zoom * 0.5);
    this.setPosition(
      this.scene.scale.width / 2,
      -this.scene.cameras.main.scrollY + 100,
    );
  }

  public playScaleDown() {
    this.scene.tweens.killTweensOf(this);
    this.scene.tweens.add({
      targets: this,
      scale: {
        from: 1 * this.scene.cameras.main.zoom,
        to: 0.5 * this.scene.cameras.main.zoom,
      },
      ease: "Back.easeOut",
      duration: 500,
    });
  }

  public playScaleOut() {
    this.scene.tweens.killTweensOf(this);
    this.scene.tweens.add({
      targets: this,
      scale: {
        from: 0.5 * this.scene.cameras.main.zoom,
        to: 1 * this.scene.cameras.main.zoom,
      },
      ease: "Back.easeOut",
      duration: 500,
    });
    this.scene.tweens.add({
      targets: this,
      alpha: 0,
      duration: 350,
      delay: 150,
    });
  }

  public hide() {
    this.visible = false;
  }

  public show() {
    this.visible = true;
  }
}
