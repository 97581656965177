// 3.5 seconds (seems to be the average in the bomberman games)
export const CRYPTO_BOMBER_BOMB_DURATION = 2500;
export const CRYPTO_BOMBER_PLAYER_MOVEMENT_SPEED_IN_PIXELS = 256;
// 5 seconds
export const CRYPTO_BOMBER_READY_DURATION = 5000;
// 3 minutes
export const CRYPTO_BOMBER_ROUND_DURATION = 60000 * 3;
// 30 seconds
export const CRYPTO_BOMBER_WAITING_FOR_MAXIMUM_PLAYERS_DURATION = 15000;

export const CRYPTO_BOMBER_POWERUP_MAXIMUM_LEVEL_SPEED = 10;
