import { TilePosition2D } from "@common/interfaces/TilePosition2D";

export interface TilePosition3D {
  x: number;
  y: number;
  z: number;
}

export const TilePosition3DMinusOne: TilePosition3D = { x: -1, y: -1, z: -1 };
export const TilePosition3DZero: TilePosition3D = { x: 0, y: 0, z: 0 };

export function TilePosition2DToTilePosition3D(
  tilePosition: TilePosition2D,
  z: number = 0
): TilePosition3D {
  return { x: tilePosition.x, y: tilePosition.y, z: z };
}
