import Entity from "@game/engine/Entity";
import { TILE_VALUES } from "../../../common/data/TileValues";
import GameObject = Phaser.GameObjects.GameObject;

enum StatusUIState {
  PreGame,
  WaitingMorePlayers,
  RoundWait,
  RoundIntro,
  RoundPreview,
  RoundChoice,
  Resolve,
}

const BACKGROUND_COLORS = {
  default: 0x9ee7d7,
  banana: 0x667bf3,
  cherry: 0xddd765,
  grapes: 0x57bc70,
  orange: 0xd6d851,
  peach: 0xa469fb,
  watermelon: 0xf7b763,
};

export default class GamesStatusUI extends Entity {
  public texture: Phaser.GameObjects.RenderTexture;

  private _backgroundFruits: Phaser.GameObjects.Image[] = [];
  private _countdownTime: number;
  private _dirty: boolean = true;
  private _label: Phaser.GameObjects.BitmapText;
  private _rectangle: Phaser.GameObjects.Rectangle;
  private _state: StatusUIState = StatusUIState.RoundWait;
  private _targetImage: Phaser.GameObjects.Image;

  constructor(scene) {
    super(scene);

    this.texture = new Phaser.GameObjects.RenderTexture(scene, 0, 0, 300, 150);
    this.texture.saveTexture("memory_game_ui");

    this._label = new Phaser.GameObjects.BitmapText(
      scene,
      this.texture.width / 2,
      this.texture.height / 2,
      "font",
      "WAITING",
      50,
      1,
    ).setOrigin(0.5, 0.5);

    this._targetImage = new Phaser.GameObjects.Image(
      scene,
      this.texture.width / 2,
      this.texture.height / 2,
      "memory_atlas",
      "preview_banana",
    ).setVisible(false);

    this._rectangle = new Phaser.GameObjects.Rectangle(
      scene,
      this.texture.width / 2,
      this.texture.height / 2,
      300,
      150,
      BACKGROUND_COLORS.default,
      1,
    );

    for (let i = 0; i < 4; i++) {
      this._backgroundFruits.push(
        new Phaser.GameObjects.Image(
          scene,
          0,
          -200,
          "memory_atlas",
          "preview_banana",
        )
          .setAlpha(0.8)
          .setScale(0.6),
      );
      this.playBackgroundFruit(i, 1000 * i);
    }

    this.registerForTick();
  }

  public startWaitingPlayers(timeLeft: number) {
    this._state = StatusUIState.WaitingMorePlayers;
    this._countdownTime = timeLeft / 1000; // timeLeft is in ms
    this._label.setText(
      "WAITING \n" + Math.ceil(this._countdownTime).toString(),
    );
  }

  public override tick(deltaSeconds: number, deltaTime: number) {
    super.tick(deltaSeconds, deltaTime);

    // if (this._dirty) {
    this.render();
    // }

    if (
      this._state === StatusUIState.WaitingMorePlayers &&
      this._countdownTime > 0
    ) {
      this._countdownTime -= deltaSeconds;
      this._label.setText(
        "WAITING \n" + Math.ceil(this._countdownTime).toString(),
      );
    }
    if (
      this._state === StatusUIState.RoundPreview ||
      this._state === StatusUIState.RoundChoice
    ) {
      this.updateCountdown(deltaSeconds);
    }
  }

  private playBackgroundFruit(index: number, delay: number = 0) {
    const fruit = this._backgroundFruits[index];

    fruit.x = Phaser.Math.RND.integerInRange(0, this._rectangle.width);
    fruit.y = -fruit.height / 2;
    fruit.setTexture(
      "memory_atlas",
      "preview_" + Phaser.Math.RND.pick(TILE_VALUES).element,
    );

    this.scene.tweens
      .add({
        targets: fruit,
        y: this._rectangle.height + fruit.height / 2,
        angle: Phaser.Math.RND.integerInRange(360, 720),
        duration: 4000,
        ease: "Linear",
        delay: delay,
      })
      .once("complete", () => this.playBackgroundFruit(index, 0));
  }

  private render() {
    const renderedElements: GameObject[] = [this._rectangle];

    this._backgroundFruits.forEach((fruit) => {
      if (fruit.visible) renderedElements.push(fruit);
    });

    if (this._label.visible) renderedElements.push(this._label);
    if (this._targetImage.visible) renderedElements.push(this._targetImage);

    this.texture.clear();
    this.texture.draw(renderedElements);

    this._dirty = false;
  }

  public startPreview(timeToResolve: number) {
    this._state = StatusUIState.RoundPreview;
    this._countdownTime = timeToResolve / 1000;
  }

  public startIntro(roundNumber: number, numPlayers: number) {
    this._state = StatusUIState.RoundIntro;
    this._label.visible = true;
    this._targetImage.visible = false;

    let text = roundNumber === 5 ? "FINAL ROUND" : "ROUND " + (roundNumber + 1);
    if (roundNumber > 0) {
      text += `\n${numPlayers}   PLAYER${numPlayers > 1 ? "S" : ""}  LEFT`;
    }

    this._label.setText(text);
    this.scene.tweens.add({
      targets: this._label,
      scale: { from: 0.2, to: 0.8 },
      ease: "Back.Out",
      duration: 500,
    });
    this._dirty = true;
    this._rectangle.fillColor = BACKGROUND_COLORS.default;
    this._backgroundFruits.forEach((fruit) => (fruit.visible = true));
  }

  public startChoice(tileId: number) {
    this._state = StatusUIState.RoundChoice;
    this._label.visible = false;
    this._targetImage.visible = true;
    this._rectangle.fillColor = BACKGROUND_COLORS[TILE_VALUES[tileId].element];

    this._targetImage
      .setTexture("memory_atlas", "preview_" + TILE_VALUES[tileId].element)
      .setScale(0);
    this.scene.tweens.add({
      targets: this._targetImage,
      scale: 0.8,
      ease: "Back.Out",
      duration: 500,
    });

    this._backgroundFruits.forEach((fruit) => (fruit.visible = false));

    this._dirty = true;
  }

  private updateCountdown(deltaSeconds: number) {
    if (this._countdownTime > 0) {
      const oldText = this._label.text;

      this._countdownTime -= deltaSeconds;
      this._label.scale = 1;
      this._label.setText(Math.ceil(this._countdownTime).toString());

      if (oldText !== this._label.text) {
        this._dirty = true;
      }
    }
  }
}
