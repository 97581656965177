export const MEMORY_BOARD_NUM_COLS = 4; // In game tiles
export const MEMORY_BOARD_NUM_ROWS = 4; // In game tiles
export const MEMORY_BOARD_TILE_RESOLUTION = 4; // How many engine tiles does a memory game tile cover

export const MEMORY_COUNTDOWN_WAITING_PLAYERS_DURATION = 10000;

export const MEMORY_GAMEOVER_NUM_PLAYERS = 0;
export const MEMORY_GAMEOVER_NUM_ROUNDS = 6;

export const MEMORY_PLAYER_SPEED = 250;

export const MEMORY_ROUND_INTRO_TIME = 2000;
export const MEMORY_ROUND_PREVIEW_TIME = 4000;
export const MEMORY_ROUND_PREVIEW_NUM_PREVIEWS = 4;
export const MEMORY_ROUND_PREVIEW_NUM_TILES_PER_PREVIEW = 4;
export const MEMORY_ROUND_CHOICE_TIME = 5000;
export const MEMORY_ROUND_RESOLVE_TIME = 5000;

export const MEMORY_TILE_HALF_WIDTH = 64;
export const MEMORY_TILE_HALF_HEIGHT = 32;
export const MEMORY_TILE_VALUES = [
    { id: 0, element: "banana" },
    { id: 1, element: "cherry" },
    { id: 2, element: "grapes" },
    { id: 3, element: "orange" },
    { id: 4, element: "peach" },
    { id: 5, element: "watermelon" },
];