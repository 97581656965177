import StampWarsScene from "@game/mini/stamp_wars/StampWarsScene";
import Vector2Like = Phaser.Types.Math.Vector2Like;
import Ellipse = Phaser.Geom.Ellipse;
import Image = Phaser.GameObjects.Image;

const NUM_PARTICLES = 8;

export default class TileChangeFX extends Phaser.Events.EventEmitter {
  private _emitArea: Ellipse;
  private _ellipsePool: Image[] = [];
  private _glowPool: Image[] = [];
  private _particlePool: TileChangeParticle[] = [];
  private _scene: StampWarsScene;

  constructor(scene: StampWarsScene) {
    super();

    this._scene = scene;
    this._emitArea = new Ellipse(0, 0, 100, 70);
  }

  public playAt(position: Vector2Like, color: number, playParticles: boolean) {
    this.playEllipse(position, color);

    if (playParticles) {
      this.playParticles(position, color);
    }
  }

  public playEllipse(position: Vector2Like, color: number) {
    const ellipse = this.getEllipse();

    ellipse.setPosition(position.x, position.y);
    ellipse.setTint(color);

    this._scene.tweens.add({
      targets: ellipse,
      scale: { from: 0.5, to: 2 },
      ease: "Sine.easeOut",
      duration: 750,
    });

    this._scene.tweens
      .add({
        targets: ellipse,
        alpha: 0,
        duration: 750,
        delay: 250,
        ease: "Sine.easeOut",
      })
      .once(
        "complete",
        () => {
          this._ellipsePool.push(ellipse);
        },
        this,
      );
  }

  public playTileGlow(position: Vector2Like, color: number) {
    const glow = this.getTileGlow();

    glow.setPosition(position.x, position.y);
    glow.setTint(color);

    this._scene.tweens
      .add({
        targets: glow,
        alpha: { from: 1, to: 0 },
        ease: "Sine.easeOut",
        duration: 500,
      })
      .once(
        "complete",
        () => {
          this._glowPool.push(glow);
        },
        this,
      );
  }

  public playParticles(position: Vector2Like, color: number) {
    this._emitArea.setPosition(position.x, position.y);

    for (let i = 0; i < NUM_PARTICLES; i++) {
      const particle = this.getParticle();
      const particlePos = this._emitArea.getRandomPoint();
      const duration = Phaser.Math.RND.integerInRange(750, 1000);

      particle.setPosition(particlePos.x, particlePos.y);
      particle.tint = color;
      particle.setDepth(1000 + particlePos.y);

      this._scene.tweens
        .add({
          targets: particle,
          y: "-=" + Phaser.Math.RND.integerInRange(50, 125),
          ease: "Sine.easeOut",
          duration: duration,
        })
        .once(
          "complete",
          () => {
            particle.visible = false;
            this._particlePool.push(particle);
          },
          this,
        );

      this._scene.tweens.add({
        targets: particle,
        alpha: 0,
        duration: duration - 300,
        ease: "Sine.easeOut",
        delay: 300,
      });
    }
  }

  private getEllipse(): Image {
    let ellipse = this._ellipsePool.pop();

    if (ellipse === undefined) {
      ellipse = this._scene.add.image(
        0,
        0,
        "stamp_wars_atlas",
        "white_ellipse_thin",
      );
    }

    ellipse.scale = 1;
    ellipse.alpha = 1;
    ellipse.blendMode = Phaser.BlendModes.ADD;
    ellipse.setDepth(999);

    return ellipse;
  }

  private getParticle(): TileChangeParticle {
    const particle = this._particlePool.pop();

    if (particle) {
      particle.visible = true;
      particle.alpha = 1;
      return particle;
    }

    return new TileChangeParticle(this._scene);
  }

  private getTileGlow(): Image {
    let glow = this._glowPool.pop();

    if (glow === undefined) {
      glow = this._scene.add.image(0, 0, "stamp_wars_atlas", "tile_glow");
    }

    glow.scale = 1;
    glow.alpha = 1;
    glow.blendMode = Phaser.BlendModes.ADD;
    glow.setDepth(999);

    return glow;
  }
}

class TileChangeParticle extends Phaser.GameObjects.Image {
  constructor(scene) {
    super(scene, 0, 0, "stamp_wars_atlas", "white_square");

    this.scale = 0.25;
    this.blendMode = Phaser.BlendModes.ADD;

    scene.add.existing(this);
  }
}
