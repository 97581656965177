import {
  TilemapImageLayer,
  TilemapLayer,
  TilemapTileLayer,
} from "@game/engine/objects/TilemapLayer";
import { TilePosition3D } from "../navigation/Pathfinder";
import GameScene from "./GameScene";

export default class TiledGameScene extends GameScene {
  public levelHighestDepth: { [key: number]: number };
  public map: Phaser.Tilemaps.Tilemap;
  public tilesets: Phaser.Tilemaps.Tileset[];
  protected _debugMode: boolean = false;
  protected _layers: TilemapLayer[];

  private readonly _imagePaths: { [key: string]: string };
  private readonly _tilemapPath: string;
  private _tilesetPaths: string[];

  constructor(
    config,
    private _assetPath: string,
    tilemapPath: string,
    tilesetPathOrPaths: string | string[],
    imagePathOrPaths: string | string[] = [],
  ) {
    super(config);

    this._tilemapPath = this._assetPath + tilemapPath;

    //start highest depth
    this.levelHighestDepth = {};
    for (let i: number = 0; i < 10; i++) {
      this.levelHighestDepth[i] = 0;
    }

    // Set the tileset paths
    this._tilesetPaths = [];
    if (typeof tilesetPathOrPaths === "string") {
      tilesetPathOrPaths = [tilesetPathOrPaths];
    }
    this._tilesetPaths = tilesetPathOrPaths.map(
      (tilesetPath) => this._assetPath + tilesetPath,
    );

    // Set the image paths
    if (typeof imagePathOrPaths === "string") {
      imagePathOrPaths = [imagePathOrPaths];
    }
    this._imagePaths = {};
    imagePathOrPaths.forEach((imagePath) => {
      this._imagePaths[imagePath] = this._assetPath + imagePath;
    });

    this._debugMode = config.debugMode || false;
  }

  public getTileAndPositionAt(
    position: Phaser.Math.Vector2,
    layerIndex: number,
  ): {
    tile: Phaser.Tilemaps.Tile;
    tilePosition: TilePosition3D;
  } {
    this.map.currentLayerIndex = layerIndex;
    const tileXY = this.map.worldToTileXY(position.x, position.y);

    // Snap it to the isometric grid
    tileXY.x = Math.round(tileXY.x);
    tileXY.y = Math.round(tileXY.y);

    // Position in "3D"
    const hoveredPosition: TilePosition3D = {
      x: tileXY.x,
      y: tileXY.y,
      z: 0,
    };

    // Retrieve the tile from the floor otherwise
    const tile = this.map.getTileAt(tileXY.x, tileXY.y);
    if (tile && tile.index > -1) {
      return { tile: tile, tilePosition: hoveredPosition };
    }

    return null;
  }

  protected create() {
    super.create();

    // Generate and add a tile maps from the preloaded JSON
    this.map = this.make.tilemap({ key: this.scene.key + "-tilemap" });

    // Retrieve the data needed for organising the map structure
    const layerOrderMap: { [key: string]: TilemapLayer } = {};
    const jsonMap = this.cache.json.get(this.scene.key + "-tilemap_json");

    // Store layers in order
    const numberOfLayers = jsonMap.layers.length;
    for (let index = 0; index < numberOfLayers; ++index) {
      const layer = jsonMap.layers[index];
      layerOrderMap[layer.name] = index;
    }

    // Retrieve the different supported layer types individually
    const { images, layers } = this.map;

    // Place for storing the different layers
    this._layers = new Array(images.length + layers.length);

    // Add and place imagelayers
    images.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapImageLayer(
        original,
      );
      // this._layers[layerOrderMap[original.name]].x = original.x - 1344;
    });

    // Add and place tilelayers
    layers.forEach((original) => {
      this._layers[layerOrderMap[original.name]] = new TilemapTileLayer(
        original,
      );
    });

    // Set highest depth value for each Level on layer proprieties
    this._layers.forEach((original) => {
      const levelProperty = original.getProperty("Level");
      const level = levelProperty ? levelProperty.value : -1;
      if (!levelProperty) {
        console.warn("Layer " + original.name + " has no Level property");
      }
      if (this.levelHighestDepth[level] < layerOrderMap[original.name]) {
        this.levelHighestDepth[level] = layerOrderMap[original.name];
      }
    });

    // Load and store the tilesets
    this.tilesets = [];
    this._tilesetPaths.forEach((tilesetURL, index) => {
      // For now, this is fine.. Right? Surely.
      const pathSplit = tilesetURL.split("/");
      const filename = pathSplit[pathSplit.length - 1].split(".")[0];

      // Retrieve the tileset
      this.tilesets.push(
        this.map.addTilesetImage(
          filename,
          this.scene.key + "-tileset-" + index,
        ),
      );
    });
  }

  protected onShutdown() {
    super.onShutdown();

    // Clear the stored tilesets
    this.tilesets.length = 0;
  }

  protected preload() {
    super.preload();

    // Load all the tilesets (if needed)
    this._tilesetPaths.forEach((tilesetURL, index) => {
      this.load.image(this.scene.key + "-tileset-" + index, tilesetURL);
    });

    // Load the tilemap as a tilemap and as straight up JSON (for parsing)
    this.load.json(this.scene.key + "-tilemap_json", this._tilemapPath);
    this.load.tilemapTiledJSON(this.scene.key + "-tilemap", this._tilemapPath);

    // Load any images in case of image layers
    for (const [key, path] of Object.entries(this._imagePaths)) {
      this.load.image(key, path);
    }
  }
}
