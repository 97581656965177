import { GameObjects } from "phaser";

class XaxisComponent {
  private container: GameObjects.Container;
  private timeSize: number = 5;
  private timeMin: number = 1;
  private cleanup: any[] = [];
  public xPosition: number = 0;
  public yPosition: number = 0;

  constructor(ctn: GameObjects.Container, x: number, y: number) {
    this.container = new GameObjects.Container(ctn.scene, 0, 0);
    this.xPosition = x;
    this.yPosition = y;
    ctn.add(this.container);
    // this.container = ctn;

    const zero = new GameObjects.Text(
      this.container.scene,
      this.xPosition,
      this.yPosition,
      "0s",
      { font: "20px depixel", color: "#FFFFFF" },
    );
    zero.setOrigin(0, 0.5);
    this.container.add(zero);

    // const lineZero = new GameObjects.Line(this.container.scene, -590, 10, -500, 20, 0xFFFFFF);
    // this.container.add(lineZero);

    const lineZero = this.container.scene.add.graphics();
    lineZero.lineStyle(1, 0xffffff);
    lineZero.lineBetween(-580, this.yPosition + 10, -580, this.yPosition + 40);
    this.container.add(lineZero);
  }

  public startRender(time: number) {
    time = time / 1000;
    this.cleanup.forEach((c) => c.destroy());
    if (time < this.timeMin) {
      const zero = new GameObjects.Text(
        this.container.scene,
        580,
        this.yPosition,
        "1s",
        { font: "20px depixel", color: "#FFFFFF" },
      );
      zero.setOrigin(0.5, 0.5);
      this.container.add(zero);
      const lineZero = this.container.scene.add.graphics();
      lineZero.lineStyle(1, 0xffffff);
      lineZero.lineBetween(580, this.yPosition + 10, 580, this.yPosition + 40);
      this.container.add(lineZero);
      this.cleanup.push(zero);
      this.cleanup.push(lineZero);
    } else if (time < this.timeSize) {
      let wholeOnes: number = 0;
      while (wholeOnes < time) {
        wholeOnes++;

        const xPos = -600 + 1200 * (wholeOnes / time);

        if (xPos <= 580) {
          const timetext = new GameObjects.Text(
            this.container.scene,
            xPos,
            this.yPosition,
            wholeOnes.toString() + "s",
            { font: "20px depixel", color: "#FFFFFF" },
          );
          timetext.setOrigin(0.5, 0.5);
          this.container.add(timetext);
          const lineText = this.container.scene.add.graphics();
          lineText.lineStyle(1, 0xffffff);
          lineText.lineBetween(
            timetext.x,
            this.yPosition + 10,
            timetext.x,
            this.yPosition + 40,
          );
          this.container.add(lineText);
          this.cleanup.push(timetext);
          this.cleanup.push(lineText);
        }
      }
    } else {
      let timeInterval = Math.floor(time / this.timeSize);

      let wholeOnes: number = 0;
      while (wholeOnes < time) {
        wholeOnes += timeInterval;

        const xPos = -600 + 1200 * (wholeOnes / time);
        if (xPos <= 580) {
          const timetext = new GameObjects.Text(
            this.container.scene,
            -590 + 1200 * (wholeOnes / time),
            this.yPosition,
            wholeOnes.toString() + "s",
            { font: "20px depixel", color: "#FFFFFF" },
          );
          timetext.setOrigin(0.5, 0.5);
          this.container.add(timetext);
          const lineText = this.container.scene.add.graphics();
          lineText.lineStyle(1, 0xffffff);
          lineText.lineBetween(
            timetext.x,
            this.yPosition + 10,
            timetext.x,
            this.yPosition + 40,
          );
          this.container.add(lineText);
          this.cleanup.push(timetext);
          this.cleanup.push(lineText);
        }
      }
    }
  }
}

export { XaxisComponent };
