import { Direction } from "@game/engine/navigation/Direction";

const ANGLE_INTERVAL = Math.PI / 8;

export function AngleToDirection(angle: number): Direction {
  if (angle > -5 * ANGLE_INTERVAL && angle <= -3 * ANGLE_INTERVAL) {
    return Direction.NorthWest;
  } else if (angle > -ANGLE_INTERVAL && angle <= ANGLE_INTERVAL) {
    return Direction.NorthEast;
  } else if (angle > 3 * ANGLE_INTERVAL && angle <= 5 * ANGLE_INTERVAL) {
    return Direction.SouthEast;
  } else if (angle > -3 * ANGLE_INTERVAL && angle <= -ANGLE_INTERVAL) {
    return Direction.North;
  } else if (angle > ANGLE_INTERVAL && angle <= 3 * ANGLE_INTERVAL) {
    return Direction.East;
  } else if (angle > 5 * ANGLE_INTERVAL && angle <= 7 * ANGLE_INTERVAL) {
    return Direction.South;
  } else if (angle > -7 * ANGLE_INTERVAL && angle <= -5 * ANGLE_INTERVAL) {
    return Direction.West;
  }

  return Direction.SouthWest;
}
