export enum CrashStates {
  PreReady = 0,
  Ready = 1,
  Game = 2,
  PostGame = 3,
}
export enum CrashOpCodes {
  PlayerJoined = 1,
  PlayerLeft = 2,
  GamePreReady = 3,
  GamePrepare = 4,
  GameStarted = 5,
  GameTick = 6,
  GamePlayerJoined = 7,
  GamePlayerLeft = 8,
  GameCrash = 9,
  GameState = 10,
}

export const CrashTickRate = 20;
export const CrashMultiplierDecimals = 2;
export const CrashMultiplierMinimalCashout = 1.1;
