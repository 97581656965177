import Body = Phaser.Physics.Arcade.Body;
import Sprite = Phaser.GameObjects.Sprite;
import ITopDownOrderable from "@game/engine/interfaces/ITopDownOrderable";
import { TilePosition3D } from "@game/engine/navigation/Pathfinder";
import ANIMATION_COMPLETE = Phaser.Animations.Events.ANIMATION_COMPLETE;

export default class CryptoBomberCrate
  extends Sprite
  implements ITopDownOrderable
{
  public static EventTypes = {
    Destroyed: "cratedestroyed",
  };

  public declare body: Body;

  private readonly _animationName: string = "explode";

  constructor(scene, public tilePosition: TilePosition3D) {
    const worldPosition = scene.worldPositionAtTilePosition(tilePosition);
    worldPosition.y -= 15;
    super(
      scene,
      worldPosition.x,
      worldPosition.y,
      "crate_explosion_spritesheet",
      0,
    );

    // Set up physics
    scene.physics.world.enable(this);
    this.body.setSize(64, 64).setOffset(6, 38).setImmovable(true);

    this.anims.create({
      key: this._animationName,
      frameRate: 14,
      frames: this.anims.generateFrameNumbers("crate_explosion_spritesheet", {
        start: 0,
        end: 7,
      }),
      repeat: 0,
    });
  }

  public explode() {
    // Make us ethereal
    this.body.destroy();

    this.anims.play(this._animationName).once(ANIMATION_COMPLETE, () => {
      this.emit(CryptoBomberCrate.EventTypes.Destroyed, this);

      this.destroy(true);
    });
  }
}
