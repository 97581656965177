import Tilemap = Phaser.Tilemaps.Tilemap;
import Tile = Phaser.Tilemaps.Tile;
import {
  STAMP_WARS_TILE_LEVEL_MAX,
  STAMP_WARS_TILE_LEVEL_TOTAL,
} from "@common/modules/stampwars/constants/StampWarsConstants";
import Vector2Like = Phaser.Types.Math.Vector2Like;

export default class StampWarsBoard {
  public get height(): number {
    return this._height;
  }
  public get width(): number {
    return this._width;
  }

  private _grid: Tile[][];
  private _height: number;
  private _map: Tilemap;
  private _width: number;

  constructor(map: Tilemap) {
    this._map = map;

    this.initializeGrid();
  }

  public getNumTilesPerTeam(): number[] {
    const tilesPerTeam = [0, 0, 0, 0];

    for (let x = 0; x < this._width; x++) {
      for (let y = 0; y < this._height; y++) {
        const tile = this.getTileAt(x, y);

        if (tile && tile.properties.value) {
          const team = this.getOwnerTeamFromTileValue(tile.properties.value);

          if (team >= 0) {
            tilesPerTeam[team]++;
          }
        }
      }
    }

    return tilesPerTeam;
  }

  public getTileAt(x: number, y: number) {
    return this._grid[x][y];
  }

  public getTileLevelFromValue(value: number): number {
    if (value === 1) return 0;

    return (
      value -
      1 -
      this.getOwnerTeamFromTileValue(value) * STAMP_WARS_TILE_LEVEL_TOTAL
    );
  }

  public getOwnerTeamFromTileValue(value: number): number {
    return Math.floor((value - 2) / STAMP_WARS_TILE_LEVEL_TOTAL);
  }

  public getTileValue(tilePosition: Vector2Like): number {
    return this.getTileAt(tilePosition.x, tilePosition.y).index;
  }

  public playerStepOnTile(
    playerTeam: number,
    tilePosition: Vector2Like,
  ): number {
    const tile = this.getTileAt(tilePosition.x, tilePosition.y);
    const value = this.getTileValue(tilePosition);
    const tileTeam = this.getOwnerTeamFromTileValue(value);
    const level = this.getTileLevelFromValue(value);

    if (tileTeam !== playerTeam && level < STAMP_WARS_TILE_LEVEL_MAX) {
      tile.index = playerTeam * STAMP_WARS_TILE_LEVEL_TOTAL + 2;
      tile.properties.value = tile.index;
      return 1;
    } else if (tileTeam === playerTeam && level < STAMP_WARS_TILE_LEVEL_MAX) {
      tile.index++;
      tile.properties.value = tile.index;
      return 2;
    }

    return 0;
  }

  public setTileTeamAndLevel(
    tilePosition: Vector2Like,
    team: number,
    level: number,
    hidden: boolean = false,
  ): boolean {
    const tile = this.getTileAt(tilePosition.x, tilePosition.y);
    const oldValue = this.getTileValue(tilePosition);
    const newValue = 1 + team * STAMP_WARS_TILE_LEVEL_TOTAL + level;

    if (!hidden) {
      tile.index = newValue;
    }

    tile.properties.value = newValue;

    return newValue !== oldValue;
  }

  private initializeGrid() {
    const width = this._map.width;
    const height = this._map.height;

    this._width = width;
    this._height = height;

    this._grid = new Array(width);

    for (let x = 0; x < width; x++) {
      this._grid[x] = new Array(height);
      for (let y = 0; y < height; y++) {
        this._grid[x][y] = null;
      }
    }

    this._map.layers.forEach((layerData) => {
      const tiles = this._map.getTilesWithin(
        0,
        0,
        this._map.width,
        this._map.height,
        undefined,
        layerData.name,
      );

      tiles.forEach((tile) => {
        if (tile.index >= 0) {
          this._grid[tile.x][tile.y] = tile;
        }
      });
    });
  }
}
