import "phaser";
import ApplicationHandler from "@game/ApplicationHandler";
import GameScene from "@game/engine/scenes/GameScene";
import {
  dispatchEvent,
  EnumUiEvents,
  handleEvent,
  handleEventOnce,
} from "@shared/Events";
import { TUiAuthData } from "@shared/types";
import { Plugin as NineSlicePlugin } from "phaser3-nineslice";
import ShopScene from "@game/mini/shop/ShopScene";
import CryptoBomberScene from "@game/mini/cryptobomber/CryptoBomberScene";
import CrashScene from "@game/mini/crash/CrashScene";
import Network from "@game/engine/networking/Network";
import ArcadeScene from "@game/mini/arcade/ArcadeScene";
import LobbyScene from "@game/mini/lobby/LobbyScene";
import PlinkoScene from "@game/mini/plinko/PlinkoScene";
import CustomizableRoomScene from "@game/mini/room/CustomizableRoomScene";
import MoonbeamScene from "./mini/moonbeam/MoonbeamScene";
import MightyMinesScene from "@game/mini/mighty-mines/MightyMinesScene";
import MemoryGameScene from "@game/mini/memory/client/MemoryGameScene";
import StampWarsScene from "@game/mini/stamp_wars/StampWarsScene";
import CaspersConferenceScene from "./mini/caspers-conference/CaspersConferenceScene";
import { mobileGetPushToken } from "@/mobile/postMessage";
import isMobile from "@shared/IsMobile";
import TrackingComponent from "@engine/components/entitycomponents/TrackingComponent";
import OverlayScene from "@engine/scenes/OverlayScene";
import OS from "phaser/src/device/OS";
import { savePushToken } from "@/mobile/pushToken";

Network.create();

const config: Phaser.Types.Core.GameConfig = {
  disableContextMenu: true,
  input: {
    gamepad: true,
  },
  type: Phaser.AUTO,
  scale: {
    autoCenter: Phaser.Scale.CENTER_BOTH,
    mode: Phaser.Scale.RESIZE,
    parent: "phaser-game",
    fullscreenTarget: "app",
    zoom: 1,
  },
  audio: {
    noAudio: isMobile(),
  },
  plugins: {
    global: [NineSlicePlugin.DefaultCfg],
  },

  physics: {
    default: "arcade",
    arcade: {
      debug: false,

      gravity: { y: 0 },
    },
    matter: {
      debug: {
        showBody: true,
        showStaticBody: true,
      },
      gravity: {
        y: 0,
      },
    },
  },
  render: {
    pixelArt: OS.desktop,
    antialiasGL: false,
    batchSize: 512,
  },

  // backgroundColor: "#125555",
  backgroundColor: "#000000",
  // The order here determines which scene is first visible
  scene: [
    // Test scene
    // TestScene,
    LobbyScene,
    CrashScene,
    PlinkoScene,
    // Public Spaces
    ShopScene,
    MoonbeamScene,
    ArcadeScene,
    CaspersConferenceScene,

    // Mini Games
    CryptoBomberScene,
    CustomizableRoomScene,
    MightyMinesScene,
    MemoryGameScene,
    StampWarsScene,

    // Overlay
    OverlayScene,
  ],
};

async function authenticate(data: TUiAuthData) {
  const response = await Network.authenticateEmail(data.email, data.password);

  if (response.success) {
    dispatchEvent(EnumUiEvents.UiLoginSuccess);

    window["savePushToken"] = savePushToken;
    mobileGetPushToken();

    await Network.connect();
  } else {
    dispatchEvent(EnumUiEvents.UiLoginFailed, response);
  }
}

if (!Network.isAuthenticated) {
  handleEvent(EnumUiEvents.UiLogin, authenticate);
  // handleEventOnce(EnumUiEvents.UiRegister, authenticate);
}

handleEventOnce(EnumUiEvents.UiMounted, (data) => {
  const game = new Phaser.Game(config);
  game.scene.start("OverlayScene");
  (window as any)._game = game;
  new ApplicationHandler(game, <(typeof GameScene)[]>config.scene);

  // Just type meta in devtools to see this
  // Make sure you were logged in before you typed it
  (window as any).meta = {};
  (window as any).meta.commit = import.meta.env.VITE_COMMIT_ID_SHORT;
  (window as any).meta.commitFull = import.meta.env.VITE_COMMIT_ID;
  (window as any).meta.ref = import.meta.env.VITE_HEAD_REF;
  (window as any).meta.buildDate = import.meta.env.VITE_BUILD_DATE;
  //As it should be tracked on every game opening
  TrackingComponent.storeTrackingInfo();
});
